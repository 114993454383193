import React from 'react'
import { LocalisedPost } from '../../../models/PostDetails'
import PostHorizontalNoImage from '../Shared/PostItemVerticalNoImage'

export default function PostListVerticalNoImage({ posts }: { posts: LocalisedPost[] }) {
    return (
        <div className="section-block-posts">
            {posts.map(post => <PostHorizontalNoImage key={post.id} post={post} />)}
        </div>
    )
}
