import React from 'react'
import { useEffect } from 'react';
import { useCategoriesStore } from '../../state/categoriesStore';
import { useLangStore, useLoading } from '../../state/stateManagement';
import { categoryListPage } from '../../translations/categories/categoryListPage';
import { toLocalisedCategory } from '../../utils/mappers/categoryMappers';
import CenterHeading from '../PageHeading/CenterHeading';
import Heading from '../PageHeading/Heading'
import SEO, { getLocalisation } from '../Shared/SEO';
import CategoryListItem from './CategoriesListPage/CategoryListItem'

export default function CategoriesListPage() {

    const { setLoading } = useLoading();
    const { selectedLanguage } = useLangStore();

    // Categories store
    const { categories, fetchCategories } = useCategoriesStore();

    useEffect(() => {
        setLoading(true);
        fetchCategories().then(() => { setLoading(false); });


    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {<SEO title={categoryListPage.pageTitle.get(selectedLanguage)!} description='All categories, where you can choose topic you want to read about !' localisation={getLocalisation(selectedLanguage)} ogImage='' />}
            <CenterHeading headingContent={
                <Heading heading={categoryListPage.heading.get(selectedLanguage)!} segment={categoryListPage.segment.get(selectedLanguage)!} />
            } />
            <div className="blog-home9">
                <div className="container">
                    <div className="list-articles">
                        {
                            categories && categories.map(category => <CategoryListItem key={category.id} category={toLocalisedCategory(category, selectedLanguage)} />)
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
