import { CreateInquery, InqueryItem } from "../models/Inqueries";
import { deleteRequestWithToken, getWithToken, post, postWithToken } from "./baseHttpClient";

const inqueriesUrl = 'api/inqueries';

export async function createInquery(inquery: CreateInquery): Promise<string> {
    const response = await post(inqueriesUrl, inquery);

    return response;
}

export async function getInqueries(accessToken: string) {
    const response = await getWithToken(accessToken, inqueriesUrl) as InqueryItem[];

    return response;
}

export async function getInqueryById(accessToken: string, id: string) {
    const response = await getWithToken(accessToken, `${inqueriesUrl}/${id}`) as InqueryItem;

    return response;
}

export async function createBecomeAuthorInquery(accessToken: string, inquery: CreateInquery): Promise<string> {
    const response = await postWithToken(accessToken, `${inqueriesUrl}/become-author`, inquery);

    return response;
}

export async function getBecomeAuthorInquery(accessToken: string) {
    const response = await getWithToken(accessToken, `${inqueriesUrl}/become-author`) as InqueryItem;

    return response;
}

export async function deleteInqueryById(accessToken: string, id: string) {
    const response = await deleteRequestWithToken(accessToken, `${inqueriesUrl}/${id}`) as string;

    return response;
}