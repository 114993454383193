import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { LocalisedAuthor } from '../../../models/Author';
import { useCurrentUser } from '../../../state/stateManagement';
import AuthorInfo from '../Shared/AuthorInfo';
import AuthorInfoEdit from '../Shared/AuthorInfoEdit';

function AuthorDetailsSection({ author }: { author: LocalisedAuthor }) {
    const { currentUser } = useCurrentUser();
    // Edit Mode
    const [isEditMode, setIsEditMode] = useState(false);

    return (
        <section className="authors">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 ">
                        {isEditMode
                            ? <AuthorInfoEdit author={author} onClose={() => { setIsEditMode(false); }} onSave={() => { setIsEditMode(false); }} />
                            : <AuthorInfo author={{ id: author.id, name: author.name, imgUrl: author.image?.urlOrBase64, bio: author.bio, isMale: author.isMale, socialMediaAccounts: author.socialMediaAccounts }} />}

                        {(currentUser?.identityId === author.id && !isEditMode)
                            && <Link className='edit-icon' to='edit' onClick={e => { e.preventDefault(); setIsEditMode(!isEditMode); }}>
                                <FontAwesomeIcon icon={faPenToSquare} />
                            </Link>}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AuthorDetailsSection;