import React from 'react'
import ReactModal from 'react-modal'
import { useYesNoDialogStore } from '../../../state/yesNoDialogStore'

export default function YesNoDialog({ title, description, yesBtnText = "OK", noBtnText = "Cancel" }: { title?: string, description?: string, yesBtnText?: string, noBtnText?: string }) {

    const { isOpen, texts, onYes, onNo } = useYesNoDialogStore()

    return (
        <ReactModal
            isOpen={isOpen}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            closeTimeoutMS={500}
            appElement={document.getElementById('root') as HTMLElement}
            style={{
                content: {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -60%)",
                    padding: 20,
                    width: 400,
                    height: 300
                }
            }}
        >
            <div className="dialog-content">
                <div className="dialog-header">
                    <button type="button" className="close" aria-label="Close" onClick={onNo}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="dialog-title">{title ?? texts?.title}</h4>
                </div>
                <div className="dialog-body">
                    {description ?? texts?.description}
                </div>
                <div className="dialog-footer">
                    <button type="button" className="btn-custom" onClick={onYes}>{texts?.yesBtnText ?? yesBtnText }</button>
                    <button type="button" className="btn-custom ml-2" onClick={onNo}>{texts?.noBtnText ?? noBtnText}</button>
                </div>
            </div>
        </ReactModal >
    )
}
