export const timespanTranslations = {
    year: new Map([["en", "{0} year ago"], ["bg", "преди {0} година"]]),
    years: new Map([["en", "{0} years ago"], ["bg", "преди {0} години"]]),
    month: new Map([["en", "{0} month ago"], ["bg", "преди {0} месец"]]),
    months: new Map([["en", "{0} months ago"], ["bg", "преди {0} месеца"]]),
    day: new Map([["en", "{0} day ago"], ["bg", "преди {0} ден"]]),
    days: new Map([["en", "{0} days ago"], ["bg", "преди {0} дни"]]),
    hour: new Map([["en", "{0} hour ago"], ["bg", "преди {0} час"]]),
    hours: new Map([["en", "{0} hours ago"], ["bg", "преди {0} часа"]]),
    minute: new Map([["en", "{0} minute ago"], ["bg", "преди {0} минута"]]),
    minutes: new Map([["en", "{0} minutes ago"], ["bg", "преди {0} минути"]]),
}