import React, { useEffect, useState } from 'react'
import { Dropdown } from '../Shared/Dropdown'
import { useLangStore, useLoading } from '../../state/stateManagement';
import 'react-quill/dist/quill.snow.css';
import MainEditor from '../ContentEditorWrapper/MainEditor';
import SmallEditor from '../ContentEditorWrapper/SmallEditor';
import TagsArea from './PostCreatePage/TagsArea';
import { useCategoriesStore } from '../../state/categoriesStore';
import { useTagsStore } from '../../state/tagsStore';
import { useForm } from 'react-hook-form';
import { useFetchWithTokenWithResult } from '../../utils/useADAuth';
import { scopes } from '../../config/authConfig';
import { getPostsTranslations, patchPost } from '../../services/blogPostService';
import { useNavigate, useParams } from 'react-router-dom';
import { usePostDetailsStore } from '../../state/postDetailsStore';
import { postEditPageTranslations } from '../../translations/posts/postEditPage';
import { EditPostForm, PatchPostRequest, PostImage, PostMultiLanguage } from '../../models/PostDetails';
import { useCurrentUserAzureFileUploader } from '../../services/fileUploaderService';
import { BlogImage } from '../../models/Image';
import ImageEditable from '../Shared/ImageEditable';
import { isNotOnlyWhiteSpaces } from '../../utils/predicates';
import { toLocalisedCategory } from '../../utils/mappers/categoryMappers';

const getDirtyValues = (
    dirtyFields: {
        title?: boolean | undefined;
        categoryId?: boolean | undefined;
        timeToRead?: boolean | undefined;
        previewContent?: boolean | undefined;
        content?: boolean | undefined;
        tags?: boolean[] | undefined;
    },
    allValues: EditPostForm
): PatchPostRequest => {
    return {
        title: dirtyFields.title ? allValues.title : undefined,
        categoryId: dirtyFields.categoryId ? allValues.categoryId : undefined,
        timeToRead: dirtyFields.timeToRead ? allValues.timeToRead : undefined,
        previewContent: dirtyFields.previewContent ? allValues.previewContent : undefined,
        content: dirtyFields.content ? allValues.content : undefined,
        tags: dirtyFields.tags ? allValues.tags.map(t => t.toLowerCase()) : undefined
    }
};

export const mapToMultiLangPost = (post: PostMultiLanguage, editForm: PatchPostRequest, language: string) => {
    return {
        ...post, ...{
            title: editForm.title ? editForm.title : post.title,
            timeToRead: editForm.timeToRead ? editForm.timeToRead : post.readTimeInMinutes,
            previewContent: editForm.previewContent ? editForm.previewContent : post.contentPreview,
            content: editForm.content ? editForm.content : post.content,

            titleTranslations: editForm.title
                ? [...post.titleTranslations.filter(t => t.language !== language), { language: language, word: editForm.title }]
                : post.titleTranslations,
            contentPreviewTranslations: editForm.previewContent
                ? [...post.contentPreviewTranslations.filter(t => t.language !== language), { language: language, word: editForm.previewContent }]
                : post.contentPreviewTranslations,
            contentTranslations: editForm.content
                ? [...post.contentTranslations.filter(t => t.language !== language), { language: language, word: editForm.content }]
                : post.contentTranslations,

            tags: editForm.tags ? editForm.tags : post.tags,
            images: editForm.imageUrl ? [{ url: editForm.imageUrl } as PostImage] : []
        }
    } as PostMultiLanguage;
}

export default function PostEditPage() {

    const { postId } = useParams<string>();

    const { selectPostItem, selectedPost, addPost, removePost, addPostTranslation } = usePostDetailsStore(
        store => ({
            selectPostItem: store.selectPostItem,
            selectedPost: store.postsMap.get(store.selectedPostId!) as PostMultiLanguage,
            addPost: store.addPostItem,
            removePost: store.removePost,
            addPostTranslation: store.addPostTranslation
        })
    );

    //Redirect when post is created successfully
    const navigate = useNavigate();

    // Upload image
    const { uploadPostImage } = useCurrentUserAzureFileUploader();

    //fetch with access token
    const fetchWithToken = useFetchWithTokenWithResult();

    // Language
    const selectedLanguage = useLangStore(store => store.selectedLanguage);

    // Lookups
    const { categories, fetchCategories } = useCategoriesStore(store => ({ categories: store.categories.map(c => toLocalisedCategory(c, selectedLanguage)), fetchCategories: store.fetchCategories }));
    const { predefinedTags, fetchTags } = useTagsStore(store => ({ predefinedTags: store.tags.map(t => ({ id: t.name, text: t.name })), fetchTags: store.fetchTags }));

    // Loader
    const { setLoading, loadingFetch } = useLoading();

    // Form
    const { register, handleSubmit, setValue, setError, getValues, reset, formState: { errors, dirtyFields, isValid } } = useForm<EditPostForm>({ mode: "onChange" });

    // Preview image (after edited in the Image editor)
    const [editedImageData, setEditedImageData] = useState<BlogImage>();

    useEffect(() => {
        loadingFetch([fetchCategories(), fetchTags(), selectPostItem(postId!, selectedLanguage)]);
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        reset({
            title: selectedPost?.titleTranslations.find(t => t.language === selectedLanguage)?.word ?? "",
            timeToRead: selectedPost?.readTimeInMinutes,
            categoryId: selectedPost?.category.id,
            previewContent: selectedPost?.contentPreviewTranslations.find(t => t.language === selectedLanguage)?.word ?? "",
            content: selectedPost?.contentTranslations.find(t => t.language === selectedLanguage)?.word ?? "",
            tags: [...(selectedPost?.tags ?? [])]
        })

        register('categoryId', { required: true });
        register('content');
        selectedPost?.images?.length > 0 && setEditedImageData({
            urlOrBase64: selectedPost?.images[0].url,
            name: selectedPost?.images[0].url.substring(selectedPost?.images[0].url.lastIndexOf("/") + 1),
            heightInPx: selectedPost?.images[0].originalHeightinPx,
            widthInPx: selectedPost?.images[0].originalWidthInPx
        })

    }, [selectedPost, selectedLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedPost && !selectedPost.titleTranslations.find(t => t.language === selectedLanguage)) {
            loadingFetch([getPostsTranslations([selectedPost.id], selectedLanguage).then(t => { addPostTranslation(t[0], selectedLanguage); })])
        }
    }, [selectedLanguage, addPostTranslation]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        selectedPost && <section className='post-create' >
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-9 col-md-12 m-auto'>
                        <div className='post-content'>
                            <h2>{postEditPageTranslations.editPostHeading.get(selectedLanguage)}</h2>

                            <form onSubmit={
                                handleSubmit(async (data) => {
                                    setLoading(true);

                                    const request: PatchPostRequest = getDirtyValues(dirtyFields, data);

                                    // Set the same image as default
                                    const selectedPostImageUrl = selectedPost.images?.find(() => true)?.url;

                                    // Change with new image
                                    if (editedImageData && editedImageData.urlOrBase64 !== selectedPostImageUrl) {
                                        request.imageUrl = await uploadPostImage(editedImageData.urlOrBase64, editedImageData.name)
                                    } else if (editedImageData && editedImageData.urlOrBase64 === selectedPostImageUrl) {
                                        request.imageUrl = selectedPostImageUrl;
                                    }
                                    else if (!editedImageData && selectedPostImageUrl) {
                                        request.imageUrl = null; // Image is removed
                                    }

                                    const postId = await fetchWithToken([scopes.openId, scopes.posts.write],
                                        (accessToken) => patchPost(accessToken, selectedPost.id!, request, selectedLanguage));

                                    removePost(postId);
                                    addPost(postId, mapToMultiLangPost(selectedPost, request, selectedLanguage));

                                    // Redirect to post
                                    navigate(`/posts/${postId}`);
                                })}
                                className='create-post-form widget-form'
                            >
                                <div className='form-group image-container'>
                                    <ImageEditable imageData={selectedPost.images?.map(img => (
                                        {
                                            urlOrBase64: img.url,
                                            name: img.url.substring(img.url.lastIndexOf("/") + 1),
                                            heightInPx: img.originalHeightinPx,
                                            widthInPx: img.originalWidthInPx
                                        } as BlogImage)
                                    )[0]
                                    }
                                        onSave={setEditedImageData} />
                                </div>
                                <div className='form-group'>
                                    <input
                                        {...register('title', { required: true, minLength: 10, validate: isNotOnlyWhiteSpaces })}
                                        className={`form-control ${!dirtyFields.title ? "" : errors.title ? "border-danger" : "border-success"}`}
                                        placeholder={postEditPageTranslations.postTitlePlaceholder.get(selectedLanguage)}
                                    />
                                </div>
                                <div className='form-group row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <Dropdown
                                            value={getValues('categoryId')}
                                            label={"Category"}
                                            className={`form-control ${!dirtyFields.categoryId ? "" : errors.categoryId ? "border-danger" : "border-success"}`}
                                            placeholderElement={postEditPageTranslations.categorySelectDefault.get(selectedLanguage)}
                                            options={categories.map<LookupItem>(c => ({ id: c.id, name: c.name }))}
                                            onChange={(e) => {
                                                setValue("categoryId", e.target.value, { shouldValidate: true, shouldDirty: true })
                                            }}
                                        />
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <input
                                            {...register('timeToRead', { required: true, valueAsNumber: true })}
                                            type="number"
                                            className={`form-control ${!dirtyFields.timeToRead ? "" : errors.timeToRead ? "border-danger" : "border-success"}`}
                                            placeholder={postEditPageTranslations.timeToReadPlaceholder.get(selectedLanguage)}
                                        />
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <SmallEditor
                                        initValue={getValues("previewContent")}
                                        onChange={(content) => { setValue('previewContent', content, { shouldDirty: true }); }}
                                        placeholder={postEditPageTranslations.postPreviewContentPlaceholder.get(selectedLanguage)}
                                        maxContentLenght={200}
                                    />
                                </div>
                                <div className='form-group'>
                                    <MainEditor
                                        initValue={getValues("content")}
                                        onChange={(content) => {
                                            if (content === '<p><br></p>') {
                                                setValue('content', content);
                                                setError('content', { type: 'required' })
                                            }
                                            else {
                                                setValue('content', content, { shouldValidate: true, shouldDirty: true });
                                            }
                                        }}
                                        placeholder={postEditPageTranslations.postContentPlaceholder.get(selectedLanguage)}
                                        className={!dirtyFields.content ? "" : errors.content ? "border border-danger" : "border border-success"}
                                    />
                                </div>
                                <div className='form-group'>
                                    <TagsArea
                                        tags={getValues("tags")}
                                        onTagAdded={alltags => setValue("tags", alltags, { shouldDirty: true, shouldValidate: true })}
                                        language={selectedLanguage}
                                        suggestions={predefinedTags}
                                    />
                                </div>
                                <button type="submit" name="submit" className={`btn-custom ${!isValid ? "disabled" : ""}`} disabled={!isValid}>
                                    {postEditPageTranslations.sendBtnText.get(selectedLanguage)}
                                </button>
                                <button className={`btn-custom ml-1`} onClick={() => { navigate(`/posts/${postId}`); }}>
                                    {postEditPageTranslations.cancelBtnText.get(selectedLanguage)}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
