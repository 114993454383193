import { Category, CategoryCreateRequest, CategoryPatchRequest } from "../models/Category";
import { get, patchWithToken, postWithToken } from "./baseHttpClient";

const categoriesUrl = 'api/categories';

export async function getCategories() {
    const response = await get(categoriesUrl, new Headers({ 'Accept-Language': 'bg' })) as Category[];
    return response;
};

export async function getCategory(id: string) {
    const response = await get(categoriesUrl + `/${id}`) as Category;
    return response;
}

export async function patchCategory(accessToken: string, request: CategoryPatchRequest) {
    const response = await patchWithToken(accessToken, categoriesUrl + `/${request.id}`, request) as Promise<string>;
    return response;
}

export async function createCategory(accessToken: string, request: CategoryCreateRequest) {
    const response = await postWithToken(accessToken, categoriesUrl, request) as Promise<string>;
    return response;
}