import React from 'react'
import { useLangStore } from '../../../state/stateManagement';
import { commentList } from '../../../translations/posts/postSinglePage';

export function ListCommentsTemplate({ comments }: { comments: JSX.Element[] }) {

    const selectedLanguage = useLangStore(store => store.selectedLanguage);

    return (
        <>
            <h4>{comments.length} {comments.length === 1 ? commentList.commentSingular.get(selectedLanguage) : commentList.commentPlural.get(selectedLanguage)}</h4>
            <ul className="comments">{comments}</ul>
        </>
    )
}