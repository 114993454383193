export interface CreateInquery {
    name: string,
    email: string,
    subject: string,
    description: string,
    type?: string
}

export interface InqueryItem {
    id: string,
    personName: string,
    personEmail: string,
    subject: string,
    description: string,
    type: string,
    createdOn: string
}

export const InqueryType = {
    ContactForm: "ContactForm",
    BecomeAuthor: "BecomeAuthor"
}