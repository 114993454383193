import React from 'react'
import { tagsAreaTranslations } from '../../../translations/posts/postCreatePage'
import { WithContext as ReactTags } from 'react-tag-input';

export default function TagsArea({ onTagAdded, suggestions, language, tags = [] }
    : { onTagAdded: (tags: string[]) => void, suggestions?: { id: string, text: string }[], language: string, tags?: string[] }) {

    function handleDelete(i: number) { onTagAdded(tags.filter((tag, index) => index !== i)); }
    function handleAddition(tag: { id: string, text: string }) { onTagAdded([...tags, tag.text]); }
    function handleDrag(tag: { id: string, text: string }, currPos: number, newPos: number) {
        const tagsToOperateWith = [...tags];

        // mutate array
        tagsToOperateWith.splice(currPos, 1);
        tagsToOperateWith.splice(newPos, 0, tag.text);

        // re-render
        onTagAdded(tags);
    }

    return (
        <ReactTags
            placeholder={tagsAreaTranslations.addTagPlaceholder.get(language)}
            tags={tags.map(t => ({ id: t, text: t }))}
            suggestions={suggestions}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
        />
    )
}
