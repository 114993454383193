import { LocalisedProduct, Product } from "../../models/Shop/Product";
import { getTranslatedWord } from "../translations";

export function toLocalisedProduct(product: Product, language: string): LocalisedProduct {
    return {
        id: product.id,
        name: getTranslatedWord(product.name, language) ?? "",
        shortDescription: getTranslatedWord(product.shortDescription, language) ?? "",
        description: getTranslatedWord(product.description, language) ?? "",
        price: product.price,
        imageUrls: product.imageUrls,
        reviewsSummary: product.reviewsSummary,
        specifications: product.specifications
    }
}