import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { scopes } from '../../config/authConfig'
import { CategoryPatchForm, CategoryPatchRequest } from '../../models/Category'
import { BlogImage } from '../../models/Image'
import { patchCategory } from '../../services/categoryService'
import { useCategoryImagesUploader } from '../../services/fileUploaderService'
import { useCategoriesStore } from '../../state/categoriesStore'
import { useLangStore, useLoading } from '../../state/stateManagement'
import { categoryEditTranslations } from '../../translations/categories/categoryEdit'
import { genericTranslations } from '../../translations/generic/inputTooltip'
import { toCategoryPatchForm } from '../../utils/mappers/categoryMappers'
import { isNotOnlyWhiteSpaces } from '../../utils/predicates'
import { useFetchWithTokenWithResult } from '../../utils/useADAuth'
import ImageEditable from '../Shared/ImageEditable'

const getDirtyValues = (
    dirtyFields: {
        nameBg?: boolean | undefined;
        descriptionBg?: boolean | undefined;
        nameEn?: boolean | undefined;
        descriptionEn?: boolean | undefined;
        imgUrl?: boolean | undefined;
        imgWideUrl?: boolean | undefined;
    },
    allValues: CategoryPatchForm
): CategoryPatchForm => {
    return {
        nameBg: dirtyFields.nameBg ? allValues.nameBg : undefined,
        descriptionBg: dirtyFields.descriptionBg ? allValues.descriptionBg : undefined,
        nameEn: dirtyFields.nameEn ? allValues.nameEn : undefined,
        descriptionEn: dirtyFields.descriptionEn ? allValues.descriptionEn : undefined,
        imgUrl: dirtyFields.imgUrl ? allValues.imgUrl : undefined,
        imgWideUrl: dirtyFields.imgWideUrl ? allValues.imgWideUrl : undefined,
    }
}

export default function CategoryEditPage() {

    const navigate = useNavigate();
    const { categoryId } = useParams<string>();
    const setLoading = useLoading(s => s.setLoading);
    const { selectCategory, selectedCategory } = useCategoriesStore();
    const { uploadCategoryImage } = useCategoryImagesUploader();

    const fetchWithToken = useFetchWithTokenWithResult();

    const selectedLanguage = useLangStore(store => store.selectedLanguage);
    const { register, setValue, getValues, handleSubmit, reset, formState: { errors, dirtyFields, isValid, isDirty } } = useForm<CategoryPatchForm>(
        {
            mode: "onChange",
            defaultValues: selectedCategory && toCategoryPatchForm(selectedCategory)
        })

    useEffect(() => {
        setLoading(true);

        selectCategory(categoryId!)
            .then(() => {
                setLoading(false);
                reset(selectedCategory && toCategoryPatchForm(selectedCategory));
            });

    }, [selectedCategory]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="container-fluid category-edit">
            <div className="category-wide-image row">
                <ImageEditable
                    imageData={getValues('imgWideUrl') ? { urlOrBase64: getValues('imgWideUrl'), name: `${selectedCategory!.name}-wide.webp` } as BlogImage : undefined}
                    onSave={(img) => { setValue("imgWideUrl", img?.urlOrBase64, { shouldDirty: true }) }}
                />
            </div>

            <div className='row'>
                <div className="category-list-image">
                    <ImageEditable
                        imageData={getValues('imgUrl') ? { urlOrBase64: getValues('imgUrl'), name: `${selectedCategory!.name}-list.webp` } as BlogImage : undefined}
                        onSave={(img) => { setValue("imgUrl", img?.urlOrBase64, { shouldDirty: true }) }}
                    />
                </div>

                <form
                    onSubmit={handleSubmit(async (data) => {
                        setLoading(true);

                        const dirtyFormFields: CategoryPatchForm = getDirtyValues(dirtyFields, data);

                        let request: CategoryPatchRequest = {
                            id: selectedCategory!.id,
                            nameBg: dirtyFormFields.nameBg,
                            descriptionBg: dirtyFormFields.descriptionBg,
                            nameEn: dirtyFormFields.nameEn,
                            descriptionEn: dirtyFormFields.descriptionEn
                        }

                        // Change with new image
                        if (dirtyFormFields.imgUrl) {
                            request.imgUrl = await uploadCategoryImage(dirtyFormFields.imgUrl, selectedCategory!.name.find(n => n.language === 'bg')?.word!, `${dirtyFormFields.imgUrl.substring(0, 10)}-list.webp`);
                        }

                        if (dirtyFormFields.imgWideUrl) {
                            request.imgWideUrl = await uploadCategoryImage(dirtyFormFields.imgWideUrl, selectedCategory!.name.find(n => n.language === 'bg')?.word!, `${dirtyFormFields.imgWideUrl.substring(0, 10)}-wide.webp`);
                        }

                        const category = await fetchWithToken([scopes.openId, scopes.posts.write],
                            (accessToken) => patchCategory(accessToken, request));

                        navigate(`/categories/${category.id}`)
                        setLoading(false);
                    })}
                    className="form category-edit-form"
                >
                    <div className='row'>
                        <div className='col-md-12 col-lg-6'>
                            <div className="form-group">
                                <input
                                    title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                    {...register('nameBg', { required: true, maxLength: 60, minLength: 4, validate: isNotOnlyWhiteSpaces })}
                                    type="text"
                                    className={`form-control ${!dirtyFields.nameBg ? "" : errors.nameBg ? "border-danger" : "border-success"}`}
                                    placeholder={categoryEditTranslations.name.get(selectedLanguage)}
                                />
                            </div>
                            <div className="form-group">
                                <textarea
                                    title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                    {...register('descriptionBg', { required: true, minLength: 4, validate: isNotOnlyWhiteSpaces })}
                                    className={`form-control ${!dirtyFields.descriptionBg ? "" : errors.descriptionBg ? "border-danger" : "border-success"}`}
                                    placeholder={categoryEditTranslations.description.get(selectedLanguage)}
                                />
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-6'>
                            <div className="form-group">
                                <input
                                    title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                    {...register('nameEn', { required: true, maxLength: 60, minLength: 4, validate: isNotOnlyWhiteSpaces })}
                                    type="text"
                                    className={`form-control ${!dirtyFields.nameEn ? "" : errors.nameEn ? "border-danger" : "border-success"}`}
                                    placeholder={categoryEditTranslations.name.get("en")}
                                />
                            </div>
                            <div className="form-group">
                                <textarea
                                    title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                    {...register('descriptionEn', { required: true, minLength: 4, validate: isNotOnlyWhiteSpaces })}
                                    className={`form-control ${!dirtyFields.descriptionEn ? "" : errors.descriptionEn ? "border-danger" : "border-success"}`}
                                    placeholder={categoryEditTranslations.description.get("en")}
                                />
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <button type="submit" name="submit" className={`btn-custom mr-2 ${!isDirty || !isValid ? "disabled" : ""}`} disabled={!isDirty || !isValid}>
                            {categoryEditTranslations.sendBtnText.get(selectedLanguage)}
                        </button>

                        <button type="submit" name="submit" className={`btn-custom`} onClick={e => { e.preventDefault(); navigate(-1); }}>
                            {categoryEditTranslations.cancelBtnText.get(selectedLanguage)}
                        </button>
                    </div>
                </form>
            </div>
        </div>

    )
}
