import React from 'react'
import { Link } from 'react-router-dom'
import { LocalisedPost } from '../../../models/PostDetails';
import { useLangStore } from '../../../state/stateManagement';
import { popularPostListSection } from '../../../translations/home/homePageTranslations';
import { formatToBlogDate } from '../../../utils/dateFormatter';

export default function PopularPostListSection({ posts }: { posts: LocalisedPost[] }) {

    const { selectedLanguage } = useLangStore();

    return (
        <section className="section-feature-2 ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 ">
                        <div className="section-title">
                            <h3>{popularPostListSection.heading.get(selectedLanguage)}</h3>
                            <p>{popularPostListSection.subheading.get(selectedLanguage)}</p>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-lg-6">
                        {
                            posts.slice(1,4).map(post =>
                                <div key={post.id} className="post-list post-list-style1 ">
                                    <div className="post-list-image">
                                        <Link to={`/posts/${post.id}`}>
                                            <img src={post.images?.length ? post.images[0].url : post.category.imgUrl} alt="" />
                                        </Link>
                                    </div>
                                    <div className="post-list-title">
                                        <div className="entry-title">
                                            <h5>
                                                <Link to={`/posts/${post.id}`}>{post.title}</Link>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="post-list-category">
                                        <div className="entry-cat">
                                            <Link to={`/categories/${post.category.id}`} className="category-style-1">{post.category.name}</Link>
                                        </div>
                                    </div>
                                </div>)
                        }
                    </div>

                    {/* Big Post */}
                    <div className="col-lg-6">
                        <div className="post-overly post-overly-2">
                            <div className="post-ovetly-image">
                                {<img src={posts[0]?.images?.find(() => true)?.url ?? posts[0]?.category.imgUrl} alt="" />}
                            </div>
                            <div className="post-overly-content">
                                <div className="entry-cat">
                                    <Link to={`/categories/${posts[0]?.category.id}`} className="category-style-2">{posts[0]?.category.name}</Link>
                                </div>
                                <h3 className="entry-title">
                                    <Link to={`/posts/${posts[0]?.id}`}>{posts[0]?.title} </Link>
                                </h3>
                                <ul className="entry-meta">
                                    <li className="post-author"> <Link to={`/authors/${posts[0]?.author.id}`}>{posts[0]?.author.name}</Link></li>
                                    <li className="post-date"> <span className="line"></span> {formatToBlogDate(posts[0]?.createdOnUtc, selectedLanguage)}</li>
                                    <li className="post-timeread"> <span className="line"></span> {posts[0]?.readTimeInMinutes} {popularPostListSection.minsRead.get(selectedLanguage)}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
