import React, { useEffect } from 'react'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'
import { useLangStore, useLoading } from '../../state/stateManagement'
import { inqueriesTranslations } from '../../translations/inqueries/inqueries'
import CenterHeading from '../PageHeading/CenterHeading'
import Heading from '../PageHeading/Heading'
import { useFetchWithTokenWithResult } from '../../utils/useADAuth'
import { useInqueriesStore } from '../../state/inqueries'
import { scopes } from '../../config/authConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { formatToBlogDate } from '../../utils/dateFormatter'

export default function InqueriesPage() {
    const selectedLanguage = useLangStore(store => store.selectedLanguage);
    const { setLoading } = useLoading();
    const fetchWithToken = useFetchWithTokenWithResult();
    const { inqueries, fetchInqueries, deleteInquery } = useInqueriesStore();

    useEffect(() => {
        setLoading(true);

        fetchWithToken([scopes.user_impersonation], fetchInqueries).then(() => setLoading(false))
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='container'>
            <CenterHeading headingContent={
                <Heading heading={inqueriesTranslations.heading.get(selectedLanguage)!} segment={inqueriesTranslations.segment.get(selectedLanguage)!} />
            } />

            <div className='inqueries'>
                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} >
                    {
                        inqueries.map(inquery => (
                            <AccordionItem key={inquery.id} className="inquery-item">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <span className='inquery-title'>
                                            <strong>{inquery.subject}</strong>
                                        </span>
                                        <span className='inquery-right'>
                                            <span className='person-name'>{inquery.personName}</span>
                                            <span className='person-email'>({inquery.personEmail})</span>
                                            <span className='time-since'>
                                                <strong>{inqueriesTranslations.from.get(selectedLanguage)} {formatToBlogDate(new Date(inquery.createdOn), selectedLanguage)}</strong>
                                            </span>
                                        </span>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className='inquery-body'>
                                        <p className='inquery-content'>{inquery.description}</p>

                                        <span className='inquery-actions'>
                                            <span className='action-btn' onClick={() => {
                                                fetchWithToken([scopes.user_impersonation], (token) => deleteInquery(token, inquery.id))
                                            }}>
                                                <FontAwesomeIcon className='inquery-delete' icon={faTrashCan} />
                                            </span>
                                        </span>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))
                    }
                </Accordion>
            </div>
        </div>
    )
}
