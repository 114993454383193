import { appDomain, appName } from '../../config/config'

export const aboutPageTranslations = {
    pageTitle: new Map([["en", "About Us"], ["bg", "За нас"]]),
    heading: new Map([["en", "About Us"], ["bg", "За нас"]]),
    segment: new Map([["en", "About Us"], ["bg", "За нас"]]),

    pageHeading: new Map([["en", "Thank you for checking out our blog website."], ["bg", "Благодарим ви, че разглеждате нашата страница."]]),
    pageDetails: new Map([
        ["en", `<p style='font-size: 18px;'>Quality information can help a lot of people. "<a href='${appDomain}'>${appName}</a>" is a place where you can share your ideas or knowledge and spread it all over the world. We are an open platform where many people read and post insightful things.</p> <p style='font-size: 18px;'>Anyone can write on "<a href='${appDomain}'>${appName}</a>". It’s an environment that’s open to everyone but promotes quality content and authenticity. Discussions between readers can be started which can lead to discovery and growth.</p>`],
        ["bg", `<p style='font-size: 18px;'>Качествената информация може да помогне на много хора. "<a href='${appDomain}'>${appName}</a>" е място, където можете да споделяте идеите или знанията си и да ги разпространявате навсякъде. Ние сме отворена платформа, където много хора могат да четат и публикуват задълбочени публикации.</p> <p style='font-size: 18px;'>Всеки може да пише в "<a href='${appDomain}'>${appName}</a>". Това е среда, която е отворена за всеки и която се осланя на качество и автентичност. Дискусии между читателите могат да бъдат започнати, които да доведат до открития и израстване.</p>`]]),

    contactRedirectBtnText: new Map([["en", "Contact Us"], ["bg", "Контакти"]]),
}