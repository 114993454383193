import React from 'react'

export default function LeftAlignHeading({ headingContent }: { headingContent: JSX.Element }) {
    return (
        <div className="section-heading" style={{ backgroundImage: "url('../../assets/img/other/bg-cat-1.jpg')" }}>
            <div className="container">
                <div className="section-heading-5"  >
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading-5-title">
                                {headingContent}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
