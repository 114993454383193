import { ToastPromiseParams } from "react-toastify"

export const authorListPage = {
    pageTitle: new Map([["en", "All Authors"], ["bg", "Всички Автори"]]),
    metaDescription: new Map([["en", "All authors present in the system. You can browse everyones articles, gain knowledge and learn from them."], ["bg", "Всички автори в нашата система. Можеш да разгледаш техните статии, да се учиш и обогатяваш."]]),
    heading: new Map([["en", "All Authors"], ["bg", "Всички Автори"]]),
    segment: new Map([["en", "Authors"], ["bg", "Автори"]]),
    articles: new Map([["en", "Articles"], ["bg", "Статии"]]),
    articlesSingular: new Map([["en", "Article"], ["bg", "Статия"]])
}

export const authorPromoteTrustedPage = {
    heading: new Map([["en", "All Authors"], ["bg", "Всички Автори"]]),
    segment: new Map([["en", "Authors"], ["bg", "Автори"]]),
    apply: new Map([["en", "Trusted"], ["bg", "Доверен"]]),
    yesNoApproveDialog: {
        title: new Map([["en", "You want to make '{0}' 'Trusted' ?"], ["bg", "Искате да направете '{0}' 'Доверен' ?"]]),
        body: new Map([["en", "If you want to make '{0}' 'Trusted' click Approve button !"], ["bg", "Ако искаш да направиш '{0}' 'Доверен' натисни бутона Потвърди"]]),
        yesBtnText: new Map([["en", "Approve"], ["bg", "Потвърди"]]),
        noBtnText: new Map([["en", "Cancel"], ["bg", "Откажи"]])
    }
}

export const promoteTrustedAuthorPageTranslations = {
    toastMessages: new Map<string, ToastPromiseParams<void>>([
        ["en", { pending: "Promoting to trusted...", success: "Author is promoted to Trusted Author!", error: "Author not promoted to Trusted!" }],
        ["bg", { pending: "Повишаване в 'Доверен' автор...", success: "Повишаване в 'Доверен' автор Успешна!", error: "Повишаване в 'Доверен' автор Неуспешна!" }]
    ]),
}