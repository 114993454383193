import { CreatePostRequest, PatchPostRequest, Post, PostTranslation } from "../models/PostDetails";
import { formatForBackend } from "../utils/dateFormatter";
import { getDate } from "../utils/dateUtils";
import { get, getWithToken, patchWithToken, postWithToken } from "./baseHttpClient";

const postsUrl = 'api/posts';

export enum PostOrdering {
    CreatedOnAsc = 0,
    CreatedOnDesc = 1,
    CommentCountAsc = 2,
    CommentCountDesc = 3,
    VisitCountAsc = 4,
    VisitCountDesc = 5
}

export interface PostSearchParams {
    authorId?: string,
    categoryId?: string,
    tag?: string,
    keyword?: string,
    orderBy?: PostOrdering //0: CreatedOnAsc, 1: CreatedOnDesc, 2: CommentCountAsc, 3: CommentCountDesc, 4: VisitCountAsc, 5: VisitCountDesc,
    after?: Date,
    page?: number,
    pageSize?: number
}

export function toUrlSearchParams(params: PostSearchParams) {
    const urlParams = new URLSearchParams();
    params.authorId && urlParams.append("authorId", params.authorId);
    params.categoryId && urlParams.append("categoryId", params.categoryId);
    params.tag && urlParams.append("tag", params.tag);
    params.keyword && urlParams.append("keyword", params.keyword);
    params.after && urlParams.append("after", formatForBackend(params.after));
    urlParams.append("orderBy", params.orderBy?.toString() ?? `${PostOrdering.CreatedOnDesc}`);
    urlParams.append("page", params.page?.toString() ?? "1");
    urlParams.append("pageSize", params.pageSize?.toString() ?? "8");

    return urlParams;
}

export interface PostBaseSearchParams {
    authorId?: string,
    categoryId?: string,
    tag?: string,
    keyword?: string,
    after?: Date,
}

export function getPost(id: string, language: string = 'bg') {
    const response = get(postsUrl + `/${id}`, { 'Language': language }) as Promise<Post>;
    return response;
}

export const getPostsCount = (searchParams: PostBaseSearchParams) => {
    const params = new URLSearchParams();
    searchParams.authorId && params.append("authorId", searchParams.authorId);
    searchParams.categoryId && params.append("categoryId", searchParams.categoryId);
    searchParams.tag && params.append("tag", searchParams.tag);
    searchParams.keyword && params.append("keyword", searchParams.keyword);
    searchParams.after && params.append("after", formatForBackend(searchParams.after));

    const response = get(postsUrl + `/count?${params}`) as Promise<number>;
    return response;
}

export const getPosts = (page = 1, pageSize = 20, language: string = 'bg') => getPostsBy({ page: page, pageSize: pageSize })
// Get N most popular posts for M days back. Posts which are most viewed in the last M days
export const getMostPopular = (count: number, days: number, language: string = 'bg') => getPostsBy({ after: getDate(days), orderBy: PostOrdering.VisitCountDesc, pageSize: count })
export const getAuthorPosts = (authorId: string, page = 1, pageSize = 20, language: string = 'bg') => getPostsBy({ authorId: authorId, page: page, pageSize: pageSize })
export const getCategoryPosts = (categoryId: string, page = 1, pageSize = 20, language: string = 'bg') => getPostsBy({ categoryId: categoryId, page: page, pageSize: pageSize })
export function getPostsBy(searchParams: PostSearchParams, language: string = 'bg') {
    const params = toUrlSearchParams(searchParams)

    const response = get(postsUrl + `?${params}`, { 'Language': language }) as Promise<Post[]>;
    return response;
}

// Translations
export const getPostsTranslations = (ids: string[], language: string) => {
    const response = get(postsUrl + '/translations?postIds=' + ids.join(','), { 'Language': language }) as Promise<PostTranslation[]>;
    return response;
}

export function createPost(accessToken: string, request: CreatePostRequest, language: string = "bg") {
    const response = postWithToken(accessToken, postsUrl, request, { "Language": language }) as Promise<string>;
    return response;
}

export function patchPost(accessToken: string, postId: string, request: PatchPostRequest, language: string = "bg") {
    const response = patchWithToken(accessToken, postsUrl + `/${postId}`, request, { "Language": language }) as Promise<string>;
    return response;
}

// Admin actions
export const getUnapprovedPosts = (accessToken: string) => getWithToken(accessToken, `${postsUrl}/pending`);
export const approvePost = (accessToken: string, postId: string): Promise<string> => postWithToken(accessToken, `${postsUrl}/${postId}/approve`, { id: postId })