import { create } from 'zustand'
import { getSocialMediaFollowers } from "../services/socialMediaService";

interface SocialMediaFollowersStore {
    socialMediaFollowers: SocialMediaFollows[]
    fetchSocialMediaFollowers: () => Promise<void>
}

export const useSocialMediaFollowersStore = create<SocialMediaFollowersStore>((set, get) => ({
    socialMediaFollowers: [],
    fetchSocialMediaFollowers: async () => {
        if (get().socialMediaFollowers.length) return;

        const socialMediaFollowers = await getSocialMediaFollowers();
        useSocialMediaFollowersStore.setState(() => ({ socialMediaFollowers: socialMediaFollowers }));
    }
}))