import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { LocalisedCategory } from '../../../models/Category'
import { useCurrentUser } from '../../../state/stateManagement'
import CategoryInfo from './CategoryInfo'

export default function CategoryHeader({ category }: { category: LocalisedCategory }) {

    const { currentUser } = useCurrentUser()

    return (
        <div className="section-heading" style={{
            backgroundImage: `url('${category.imgWideUrl}')`,
            backgroundSize: "cover",
            backgroundPosition: "center"
        }}>
            <div className='section-heading-overlay'>
                {currentUser && currentUser.role === "Admin" &&
                    <Link className='edit-icon' to='edit'>
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </Link>}
                <div className="container">
                    <div className="section-heading-5"  >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-heading-5-title">
                                    <CategoryInfo category={category} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
