import React from 'react'

function CenterHeading({ headingContent }: { headingContent: JSX.Element }) {
    return (
        <div className="section-heading" >
            <div className="container">
                <div className="section-heading-2">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading-5-title">
                                {headingContent}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CenterHeading;