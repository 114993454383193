import { create } from "zustand";

interface YesNoDialogTexts {
    title: string,
    description?: string,
    yesBtnText?: string,
    noBtnText?: string
}

interface YesNoDialogStore {
    isOpen: boolean
    texts?: YesNoDialogTexts,
    onYes: () => Promise<void>,
    onNo: () => void,

    openDialog: (texts: YesNoDialogTexts, onYes?: () => Promise<void>, onNo?: () => void) => void,
    setIsOpen: (value: boolean) => void
}

export const useYesNoDialogStore = create<YesNoDialogStore>((set, get) => ({
    isOpen: false,
    setIsOpen: (value) => { set({ isOpen: value }) },

    onYes: async () => { set({ isOpen: false }) },
    onNo: () => { set({ isOpen: false }) },
    

    openDialog: (texts: YesNoDialogTexts, onYes, onNo) => {
        const closeDialog = () => { set({ isOpen: false }) };

        set({
            isOpen: true,
            texts: texts,
            onYes: async () => {
                closeDialog()
                onYes && await onYes();
            },
            onNo: () => {
                closeDialog();
                onNo && onNo();
            }
        })
    }
}))