import React from 'react'
import { Link } from 'react-router-dom';
import { useLangStore } from '../../state/stateManagement';
import { headingTranslation } from '../../translations/shared/pageHeading';

function Heading({ heading, segment, description }: { heading?: string, segment?: string, description?: string }): any {

    const { selectedLanguage } = useLangStore();

    return (
        <>
            {heading && <h1>{heading}</h1>}
            {
                segment && <p className="links">
                    <Link to="/">{headingTranslation.home.get(selectedLanguage)} <i className="las la-angle-right"></i>
                    </Link> {segment}
                </p>
            }
            {description && <p className='desc'>{description}</p>}
        </>
    )
}

export default Heading;