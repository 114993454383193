import { CreateCommentRegisteredUserRequest, CreateCommentUnregisteredUserRequest, DeleteComment, PostComment } from "../models/Comment";
import { get, post, deleteRequestWithToken, postWithToken } from "./baseHttpClient";

const postsUrl = 'api/posts/';
const commentsUrl = 'api/comments/';

export async function commentsByPostId(postId: string): Promise<PostComment[]> {
    const response = await get(postsUrl + `${postId}/comments`) as PostComment[];
    return response;
}

export async function addComment(accessToken: string, postId: string, commentToAdd: CreateCommentRegisteredUserRequest): Promise<PostComment> {
    const response = await postWithToken(accessToken, postsUrl + `${postId}/comments`, commentToAdd) as PostComment;
    return response;
}

export async function addCommentAnonymous(postId: string, commentToAdd: CreateCommentUnregisteredUserRequest): Promise<PostComment> {
    const response = await post(postsUrl + `${postId}/comments/anonymous`, commentToAdd) as PostComment;
    return response;
}

export async function deleteComment(accessToken: string, commentToDelete: DeleteComment) {
    const response = await deleteRequestWithToken(accessToken, commentsUrl + `${commentToDelete.id}`, commentToDelete) as void;
    return response;
}