export const postEditPageTranslations = {
    editPostHeading: new Map([["en", "Edit Post"], ["bg", "Промени статия"]]),
    clickToUploadImage: new Map([["en", "Click to upload image"], ["bg", "Кликни за да качиш снимка"]]),
    postTitlePlaceholder: new Map([["en", "Title*"], ["bg", "Заглавие*"]]),
    categorySelectDefault: new Map([["en", "Select Category*"], ["bg", "Избери категория*"]]),
    timeToReadPlaceholder: new Map([["en", "Time to read*"], ["bg", "Време за четене*"]]),
    postPreviewContentPlaceholder: new Map([["en", "Write post preview here, which will show when post is listed"], ["bg", "Напишете кратко описание, което ще се показва при показване в списък"]]),
    postContentPlaceholder: new Map([["en", "Write your amazing post*"], ["bg", "Напишете страхотният си пост (задължително поле)"]]),    
    sendBtnText: new Map([["en", "Edit Post"], ["bg", "Промени Пост"]]),
    cancelBtnText: new Map([["en", "Cancel"], ["bg", "Откажи"]])
}