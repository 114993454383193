import { faRemove } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { useCurrentUser, useLangStore } from '../../../state/stateManagement'
import { commentSingle } from '../../../translations/posts/postSinglePage'
import { getTimeSinceNow } from '../../../utils/dateFormatter'
import AuthorImage from '../../Author/Shared/AuthorImage'
import { PostComment } from '../../../models/Comment'

export default function SingleComment({ comment, onDeleteClick, onAnswerClick }: {
    comment: PostComment, onDeleteClick?: (comment: PostComment) => Promise<void>, onAnswerClick?: () => void
}) {

    const { selectedLanguage } = useLangStore();
    const { currentUser } = useCurrentUser();

    return (
        <li className="comment-item">
            <AuthorImage imageUrl={comment.commentator?.imgUrl} isMale={comment.commentator?.isMale} />
            <div className="content">
                <div className="meta">
                    <ul className="list-inline">
                        <li>
                            {
                                comment.commentator?.id
                                    ? <Link to={`/authors/${comment.commentator?.id}`}>{comment.commentator?.name}</Link>
                                    : <span>{comment.commentator?.name}</span>
                            }
                        </li>
                        <li className="slash"></li>
                        <li>{getTimeSinceNow(comment.createdOnUtc, selectedLanguage)}</li>
                        {
                            currentUser?.id === comment.commentator?.id && <li className='right' onClick={() => onDeleteClick && onDeleteClick(comment)}> <FontAwesomeIcon icon={faRemove} /> </li>
                        }
                    </ul>
                </div>
                <p> {comment.text}</p>
                <span
                    onClick={() => { onAnswerClick && onAnswerClick() }}
                    className="btn-reply">
                    <i className="las la-reply"></i> {commentSingle.reply.get(selectedLanguage)}
                </span>
            </div>
        </li>
    )
}
