import { ToastPromiseParams } from "react-toastify"

export const userTranslations = {
    heading: new Map([["en", "All Users"], ["bg", "Всички потребители"]]),
    segment: new Map([["en", "Users"], ["bg", "Потребители"]]),
    apply: new Map([["en", "Apply"], ["bg", "Приложи"]])
}

export const changeRoleTranslations = {
    toastMessages: new Map<string, ToastPromiseParams<void>>([
        ["en", { pending: "Changing role...", success: "Role changed successfully!", error: "Role change failed !" }],
        ["bg", { pending: "Смяна на роля...", success: "Смяната на роля беше завършена успешно!", error: "Смяната на роля не беше завършена успешно!" }]
    ]),
}