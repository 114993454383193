import React from 'react'
import InstagramPanel from '../../Instagram/InstagramPanel'
import Heading from '../../PageHeading/Heading'
import ContactImage from '../../../assets/img/other/contact.webp'
import { useForm } from "react-hook-form";
import { useLangStore } from '../../../state/stateManagement';
import { contactTranslations, createInqueryTranslations } from '../../../translations/contact/contactform';
import CenterHeading from '../../PageHeading/CenterHeading';
import { createInquery } from '../../../services/contactService';
import { toast } from 'react-toastify';
import { genericTranslations } from '../../../translations/generic/inputTooltip';
import { isValidEmail } from '../../../utils/field-validations';
import { isNotOnlyWhiteSpaces } from '../../../utils/predicates';
import { CreateInquery } from '../../../models/Inqueries';
import SEO, { getLocalisation } from '../../Shared/SEO';

export default function ContactPage() {
    const { register, handleSubmit, setValue, reset, formState: { errors, dirtyFields, isValid } } = useForm<CreateInquery>({ mode: 'onChange' });
    const { selectedLanguage } = useLangStore();

    return (
        <>
            {<SEO title={contactTranslations.pageTitle.get(selectedLanguage)!} description='Contact us with any questions or feedback, we would love to hear from you !' localisation={getLocalisation(selectedLanguage)} ogImage={ContactImage} />}
            <CenterHeading headingContent={
                <Heading heading={contactTranslations.heading.get(selectedLanguage)!} segment={contactTranslations.segment.get(selectedLanguage)!} />
            } />
            <section className="contact">
                <div className="container">
                    <div className="contact-area">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="contact-image">
                                    <img src={ContactImage} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contact-info">
                                    <h3>{contactTranslations.formHeading.get(selectedLanguage)}</h3>
                                    <p>{contactTranslations.formDetails.get(selectedLanguage)}</p>
                                </div>

                                <form
                                    onSubmit={
                                        handleSubmit(data => {
                                            if (errors.description || errors.email || errors.name || errors.subject)
                                                return;

                                            toast.promise(
                                                createInquery(data).then(() => { reset(); }),
                                                createInqueryTranslations.toastMessages.get(selectedLanguage)!
                                            );
                                        })
                                    }
                                    className="form contact_form"
                                    id="main_contact_form"
                                >
                                    <div className="alert alert-success contact_msg" style={{ display: 'none' }} role="alert">
                                        {contactTranslations.formSubmitMessage.get(selectedLanguage)}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                            {...register('name', { required: true, maxLength: 60, minLength: 4, validate: isNotOnlyWhiteSpaces })}
                                            type="text"
                                            className={`form-control ${!dirtyFields.name ? "" : errors.name ? "border-danger" : "border-success"}`}
                                            placeholder={contactTranslations.formNameInputPlaceholder.get(selectedLanguage)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <input
                                            title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                            {...register('email', { required: true, validate: isValidEmail })}
                                            type="email"
                                            className={`form-control ${!dirtyFields.email ? "" : errors.email ? "border-danger" : "border-success"}`}
                                            placeholder={contactTranslations.formEmailInputPlaceholder.get(selectedLanguage)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <input
                                            title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                            {...register('subject', { required: true, validate: isNotOnlyWhiteSpaces })}
                                            type="text"
                                            className={`form-control ${!dirtyFields.subject ? "" : errors.subject ? "border-danger" : "border-success"}`}
                                            placeholder={contactTranslations.formSubjectInputPlaceholder.get(selectedLanguage)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <textarea
                                            title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                            {...register('description', { required: true, validate: isNotOnlyWhiteSpaces })}
                                            onChange={e => { setValue('description', e.target.value, { shouldValidate: true, shouldDirty: true }) }}
                                            cols={30} rows={5}
                                            className={`form-control ${!dirtyFields.description ? "" : errors.description ? "border-danger" : "border-success"}`}
                                            placeholder={contactTranslations.formMessageInputPlaceholder.get(selectedLanguage)}>
                                        </textarea>
                                    </div>

                                    <button
                                        type="submit"
                                        name="submit"
                                        disabled={!isValid}
                                        className={`btn-custom ${isValid ? "" : "disabled"}`}>
                                        {contactTranslations.formSubmitBtnText.get(selectedLanguage)}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <InstagramPanel />
        </>
    )
}
