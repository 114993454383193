export const notificationTranslations = {
    title: new Map([["en", "Notifications"], ["bg", "Известия"]]),
    seeAll: new Map([["en", "See all"], ["bg", "Виж всички"]]),
    markAsRead: new Map([["en", "Mark read"], ["bg", "Маркирай като прочетени"]]),
    noNotifications: new Map([["en", "No notifications"], ["bg", "Нямате известия"]]),

    postCreatedMessage: new Map([["en", "<strong>{0}</strong> wrote post about <strong>{1}</strong>"], ["bg", "<strong>{0}</strong> написа статия за <strong>{1}</strong>"]]),
    emailSubscriptionCreatedMessage: new Map([["en", "<strong>{0}</strong> subscribed to the system"], ["bg", "<strong>{0}</strong> се абонира в системата"]]),
    commentCreatedMessage: new Map([["en", "<strong>{0}</strong> wrote comment on your post <strong>{1}</strong>"], ["bg", "<strong>{0}</strong> написа коментар на вашата статия <strong>{1}</strong>"]]),
    inqueryCreatedMessage: new Map([["en", "<strong>{0}</strong> ({1}) created inquery <strong>{2}</strong>"], ["bg", "<strong>{0}</strong> ({1}) въведе контактна форма <strong>{2}</strong>"]]),
    authorCreatedMessage: new Map([["en", "<strong>{0}</strong> ({1}) signed up in the system"], ["bg", "<strong>{0}</strong> ({1}) се регистрира в системата"]]),
}