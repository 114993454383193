import React from 'react'
import { Link } from 'react-router-dom';
import { LocalisedPost } from '../../../models/PostDetails';
import { useLangStore } from '../../../state/stateManagement';
import { postSinglePage } from '../../../translations/posts/postSinglePage';

function SmallPost({ post, isNext }: { post: LocalisedPost, isNext: boolean }) {

    const { selectedLanguage } = useLangStore();

    return (
        <div className="small-post">
            {!!post.images?.length && <div className="small-post-image">
                <a href={`/posts/${post.id}`}>
                    <img src={post.images[0].url} alt="..." />
                </a>
            </div>}

            <div className="small-post-content">
                <p>
                    <Link to={`/posts/${post.id}`}>{post.title}</Link>
                </p>
                <small>
                    <Link to={`/posts/${post.id}`}>
                        {isNext
                            ? <>{postSinglePage.nextPost.get(selectedLanguage)}<i className="las la-arrow-right"></i></>
                            : <><i className="las la-arrow-left"></i>{postSinglePage.previousPost.get(selectedLanguage)}</>
                        }
                    </Link>
                </small>
            </div>
        </div>
    )
}

export default SmallPost;