import React, { useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import HeaderImg from '../../../assets/img/congrats/header-img.webp'
import LogoCirle from '../../../assets/img/congrats/logo-circle.webp'
import SmallImgOne from '../../../assets/img/congrats/small-img-1.webp'
import SmallImgTwo from '../../../assets/img/congrats/small-img-2.webp'
import SmallImgThree from '../../../assets/img/congrats/small-img-3.webp'
import parse from 'html-react-parser'
import { contactTranslations } from '../../../translations/congrats/congrats'
import { useLangStore } from '../../../state/stateManagement'

const getTranslation = (type?: string) => {
    switch (type) {
        case 'subscription-confirmed':
            return contactTranslations.subscription;
    
        default:
            return contactTranslations.subscription;
    }
}

export default function CongratsPage() {

    const [params] = useSearchParams();
    const type = params.get('type') ?? undefined;

    const lang = params.get('lang') ?? undefined;
    const { selectedLanguage, setLanguage } = useLangStore();

    useEffect(() => {
        setLanguage(lang ?? 'bg');
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    const message = getTranslation(type).get(selectedLanguage) as string;

    return (
        <div className='container congrats-page'>
            <div className='row image-top'>
                <div className='col-lg-12 header-img-wrapper'>
                    <img className='header-img' src={HeaderImg} alt='computer' />
                </div>
                <div className='brand-container'>
                    <div className='circle-wrapper'>
                        <img src={LogoCirle} alt="cirle" />

                    </div>
                    <div className="brand-name-wrapper">
                        <span className='brand-name'>Start your post</span>
                    </div>
                </div>
            </div>
            <div className='row content-section' >
                <div className='col-lg-12'>
                    <p>
                        {parse(message)}
                        {/* Вашият абонамент е потвърден успешно ! <br /> Благодарим ви, че се абонирахте в страницата ни. */}
                    </p>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12 redirect-section'>
                    <Link to="/">Начало</Link>
                </div>
            </div>
            <div className='row'>
                <div className='img-list col-lg-12'>
                    <div className='img-circle'>
                        <img src={SmallImgOne} alt="pie" />
                    </div>
                    <div className='img-circle'>
                        <img src={SmallImgTwo} alt="baloons" />
                    </div>
                    <div className='img-circle' >
                        <img src={SmallImgThree} alt="writer" />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12 congrats-bottom'>
                    <p> <Link to="/">www.startyourpost.com</Link> </p>
                </div>
            </div>
        </div >
    )
}
