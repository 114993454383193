export const languages = new Map<string, string>([
    ["bg", "Bulgarian"],
    ["en", "English"]
])

export const supportedLanguages: Language[] = [{ shortName: 'bg', fullName: 'Bulgarian' }, { shortName: 'en', fullName: 'English' }]

export interface Language {
    shortName: string,
    fullName: string
}