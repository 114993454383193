import { LocalisedPost, LocalisedPostAuthor, LocalisedPostCategory, Post, PostAuthor, PostCategory, PostMultiLanguage } from "../../models/PostDetails";
import { getTranslatedWord } from "../translations";

export function toMultiLangPost(post: Post, language: string): PostMultiLanguage {
    return {
        id: post.id,
        title: post.title,
        content: post.content,
        contentPreview: post.contentPreview,

        titleTranslations: [{ language: language, word: post.title }],
        contentPreviewTranslations: [{ language: language, word: post.contentPreview }],
        contentTranslations: [{ language: language, word: post.content }],

        readTimeInMinutes: post.readTimeInMinutes,
        visitCount: post.visitCount,
        createdOnUtc: post.createdOnUtc,
        author: post.author,
        category: post.category,
        isApproved: post.isApproved,
        images: post.images,
        commentCount: post.commentCount,
        comments: post.comments,
        tags: post.tags,
    }
}

export function toLocalisedPost(post: PostMultiLanguage, language: string): LocalisedPost {

    const titleTranslationDefault = new Map<string, string>([["en", "No English Translation"], ["bg", "Няма Превод на български"]]);
    const contentTranslationDefault = new Map<string, string>([["en", "No English Translation"], ["bg", "Няма Превод на български"]]);
    const contentPreviewTranslationDefault = new Map<string, string>([["en", "No English Translation"], ["bg", "Няма Превод на български"]]);

    return {
        id: post.id,
        title: getTranslatedWord(post.titleTranslations, language) ?? titleTranslationDefault.get(language)!,
        content: getTranslatedWord(post.contentTranslations, language) ?? contentTranslationDefault.get(language)!,
        contentPreview: getTranslatedWord(post.contentPreviewTranslations, language) ?? contentPreviewTranslationDefault.get(language)!,
        readTimeInMinutes: post.readTimeInMinutes,
        visitCount: post.visitCount,
        createdOnUtc: post.createdOnUtc,
        author: toLocalisedPostAuthor(post.author, language),
        category: toLocalisedPostCategory(post.category, language),
        isApproved: post.isApproved,
        images: post.images,
        commentCount: post.commentCount,
        comments: post.comments,
        tags: post.tags,
        hasTranslation: getTranslatedWord(post.titleTranslations, language) !== undefined
            || getTranslatedWord(post.contentTranslations, language) !== undefined
            || getTranslatedWord(post.contentPreviewTranslations, language) !== undefined
    }
}

function toLocalisedPostAuthor(author: PostAuthor, language: string): LocalisedPostAuthor {
    return {
        id: author.id,
        name: getTranslatedWord(author.name, language) ?? author.name[0].word,
        imgUrl: author.imgUrl,
        bio: author.bio && getTranslatedWord(author.bio, language),
        isMale: author.isMale,
        socialMediaAccounts: author.socialMediaAccounts,
    }
}

function toLocalisedPostCategory(category: PostCategory, language: string): LocalisedPostCategory {
    return {
        id: category.id,
        imgUrl: category.imgUrl,
        imgWideUrl: category.imgWideUrl,
        name: getTranslatedWord(category.name, language)!
    }
}