import React from 'react'
import InstagramPanel from '../../Instagram/InstagramPanel'
import NotAuthorizedImage from '../../../assets/img/other/notauthorized.webp'
import { Link } from 'react-router-dom'
import { useLangStore } from '../../../state/stateManagement'
import { notAuthorizedPage } from '../../../translations/notauthorized/notauthorized'

export default function NotAuthorizedPage() {

    const { selectedLanguage } = useLangStore();

    return (
        <>
            <div className="page404">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 m-auto">
                            <div className="page404-content">
                                <img src={NotAuthorizedImage} alt="" />
                                <h3>{notAuthorizedPage.title.get(selectedLanguage)}</h3>
                                <p>{notAuthorizedPage.subTitle.get(selectedLanguage)}
                                </p>
                                <Link to="/" className="btn-custom">{notAuthorizedPage.backBtnText.get(selectedLanguage)}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <InstagramPanel />
        </>
    )
}
