import React from 'react'
import { Link } from 'react-router-dom'
import { LocalisedProduct } from '../../../../models/Shop/Product'

export default function ProductCard({ product }: { product: LocalisedProduct }) {
    return (
        <div className="shop-item">
            <div className="image">
                {product.imageUrls?.length > 0 && <img src={product.imageUrls[0]} alt="" />}
                <div className="add-to-cart">
                    <Link to={`/products/${product.id}`} className="btn-action">Add to cart</Link>
                </div>
            </div>
            <h5><Link to={`/products/${product.id}`}> {product.name} </Link></h5>
            <div className="star">
                <i className="las la-star"></i>
                <i className="las la-star"></i>
                <i className="las la-star"></i>
                <i className="las la-star"></i>
                <i className="las la-star"></i>
            </div>
            <p>{product.price.amount} {product.price.currency}</p>
        </div>
    )
}
