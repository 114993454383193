import React from 'react'
import { Link } from 'react-router-dom'
import { LocalisedCategory } from '../../../models/Category';
import { useLangStore } from '../../../state/stateManagement'
import { categoryListPage } from '../../../translations/categories/categoryListPage';

export default function CategoryListItem({ category }: { category: LocalisedCategory }) {

    const { selectedLanguage } = useLangStore();

    return (
        <div className="post-list post-list-style8">
            <div className="post-list-image">
                <Link to={`/categories/${category.id}`}>
                    <img src={category.imgUrl} alt=""/>
                </Link>
            </div>
            <div className="post-list-content">
                <h4 className="entry-title">
                    <Link to={`/categories/${category.id}`}>{category.name}</Link>
                </h4>
                <div className="post-exerpt">
                    <p>{category.description}</p>
                </div>
                <div className="post-btn">
                    <Link to={`/categories/${category.id}`} className="btn-read-more">
                        {categoryListPage.continueReading.get(selectedLanguage)} <i className="las la-long-arrow-alt-right"></i>
                    </Link>
                </div>
            </div>
        </div>
    )
}
