import { blogApiConfig } from "../config/config";
import { Author, CreateAuthor, PatchAuthorRequest } from "../models/Author";
import { get, patchWithToken, postWithToken } from "./baseHttpClient";

const authorsUrl = 'api/authors';

export async function getAuthor(id: string): Promise<Author> {
    const response = await get(authorsUrl + `/${id}`);
    return response as Author;
};

export async function getAuthorByEmail(email: string): Promise<Author> {
    const response = await get(authorsUrl + `/by-email/${email}`);
    return response as Author;
};

export async function getAuthorByIdentityId(identityId: string): Promise<Author | null> {
    const response = await fetch(blogApiConfig.baseUrl + authorsUrl + `/by-identity/${identityId}`);

    if (response.status === 404) {
        return null;
    }

    if (!response.ok) {
        var responseJson = await response.json();
        throw new Error(responseJson.error);
    }

    const data: Author = await response.json();
    return data;
};

export async function getAuthors(): Promise<Author[]> {
    const response = await get(authorsUrl);
    return response as Author[];
};

export async function createAuthor(accessToken: string, author: CreateAuthor): Promise<Author> {
    const response = await postWithToken(accessToken, authorsUrl, author);
    return response;
}

export async function patchAuthor(accessToken: string, author: PatchAuthorRequest, language: string = 'bg'): Promise<Author> {
    const response = await patchWithToken(accessToken, authorsUrl, author, { "Language": language });
    return response;
}

export async function promoteToTrusted(accessToken: string, id: string): Promise<string> {
    const response = await postWithToken(accessToken, `${authorsUrl}/${id}/promote-trusted`, { id: id });
    return response as string;
}