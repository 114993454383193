import { create } from "zustand";
import { InqueryItem } from "../models/Inqueries";
import { deleteInqueryById, getBecomeAuthorInquery, getInqueries, getInqueryById } from "../services/contactService";

interface InqueriesStore {
    inqueries: InqueryItem[],
    isInit: boolean,
    selectedInqueryId?: string,

    fetchInqueries: (accessToken: string) => Promise<void>,
    fetchSingleInquery: (accessToken: string, id: string) => Promise<void>,
    deleteInquery: (accessToken: string, id: string) => Promise<void>


    becomeAuthorInquery?: InqueryItem,
    fetchBecomeAuthorInquery: (accessToken: string) => Promise<void>
}

export const useInqueriesStore = create<InqueriesStore>((set, get) => ({
    inqueries: [],
    isInit: false,

    async fetchInqueries(accessToken) {
        if (!get().isInit) {
            const fetchedInqueries = await getInqueries(accessToken);
            set(({ inqueries: fetchedInqueries, isInit: true }));
        }
    },
    async fetchSingleInquery(accessToken, id) {
        if (get().inqueries.some(i => i.id === id)) {
            set({ selectedInqueryId: id });
            return;
        }

        const inquery = await getInqueryById(accessToken, id);
        set({ inqueries: [...get().inqueries, inquery], selectedInqueryId: inquery.id })
    },
    async deleteInquery(accessToken, id) {
        const deletedInqueryId = await deleteInqueryById(accessToken, id);
        set(store => ({ inqueries: store.inqueries.filter(i => i.id !== deletedInqueryId) }));
    },

    async fetchBecomeAuthorInquery(accessToken) {
        if (get().becomeAuthorInquery === undefined) {
            const becomeAuthorInquery = await getBecomeAuthorInquery(accessToken);
            set(({ becomeAuthorInquery: becomeAuthorInquery }));
        }
    }
}))