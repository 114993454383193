import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faSquareFacebook, faSquareInstagram, faSquarePinterest, faSquareTwitter, faSquareYoutube } from '@fortawesome/free-brands-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SocialMediaLink } from '../../../models/Author';

function SocialMediaList({ socialMediaAccounts = [] }: { socialMediaAccounts: SocialMediaLink[] }) {

    const socialMediaMap : Map<string, IconProp> = new Map([
        ["facebook", faSquareFacebook],
        ["instagram", faSquareInstagram],
        ["twitter", faSquareTwitter],
        ["youtube", faSquareYoutube],
        ["pinterest", faSquarePinterest]
    ]);

    return (
        <div className="social-media">
            <ul className="list-inline">
                {
                    socialMediaAccounts.filter(sa => sa.profileUrl).map((sa) =>
                    (<li key={sa.mediaName}>
                        <a className={`fa-${sa.mediaName.toLowerCase()}`} href={sa.profileUrl} rel="noopener noreferrer" target="_blank" >
                            <FontAwesomeIcon icon={socialMediaMap.get(sa.mediaName.toLowerCase())!} />
                        </a>
                    </li>))
                }
            </ul>
        </div>
    )
}

export default SocialMediaList;