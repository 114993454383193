import { CreateProductRequest, EditProductRequest, Product } from "../../models/Shop/Product";
import { deleteRequestWithToken, get, patchWithToken, postWithToken } from "../baseHttpClient";

const productsUrl = 'api/products';

export interface ProductSearchParams {
    ownerId?: string,
    page?: number,
    pageSize?: number
}

export function toUrlSearchParams(params: ProductSearchParams) {
    const urlParams = new URLSearchParams();
    params.ownerId && urlParams.append("authorId", params.ownerId);
    urlParams.append("page", params.page?.toString() ?? "1");
    urlParams.append("pageSize", params.pageSize?.toString() ?? "8");

    return urlParams;
}

export async function getProducts(searchParams: ProductSearchParams) {
    const params = toUrlSearchParams(searchParams)

    const response = await get(productsUrl + `?${params}`);
    return response as Product[];
}

export const getProductsCount = (searchParams: ProductSearchParams) => {
    const params = toUrlSearchParams(searchParams)

    const response = get(productsUrl + `/count?${params}`) as Promise<number>;
    return response;
}

export async function getProduct(id: string) {
    const response = await get(`${productsUrl}/${id}`);
    return response as Product;
}

export async function createProduct(accessToken: string, request: CreateProductRequest) {
    const response = await postWithToken(accessToken, productsUrl, request);
    return response;
}

export async function patchProduct(accessToken: string, request: EditProductRequest) {
    const response = await patchWithToken(accessToken, `${productsUrl}/${request.id}`, request);
    return response;
}

export async function deleteProduct(accessToken: string, id: string) {
    const response = await deleteRequestWithToken(accessToken, `${productsUrl}/${id}`);
    return response;
}