import React from 'react'
import { Link } from 'react-router-dom'
import { LocalisedPost } from '../../../models/PostDetails';
import { useLangStore } from '../../../state/stateManagement';
import { sidebarPopularPostsSection } from '../../../translations/shared/sidebar';
import { formatToBlogDate } from '../../../utils/dateFormatter';

export default function PopularPostsSection({ posts }: { posts: LocalisedPost[] }) {

    const { selectedLanguage } = useLangStore();

    return (
        <div className="widget">
            <div className="widget-title">
                <h5>{sidebarPopularPostsSection.popularPostsHeading.get(selectedLanguage)}</h5>
            </div>

            <ul className="widget-popular-posts">
                {
                    posts.slice(0, 5).map((post, i) =>
                        <li key={post.id} className="small-post">
                            <div className="small-post-image">
                                <Link to={`/posts/${post.id}`}>
                                    <img src={post.images?.length ? post.images[0].url : post.category.imgUrl} alt="" />
                                    <small className="nb">{i + 1}</small>
                                </Link>
                            </div>
                            <div className="small-post-content">
                                <p>
                                    <Link to={`/posts/${post.id}`}>{post.title}</Link>
                                </p>
                                <small> <span className="slash"></span>{formatToBlogDate(post.createdOnUtc, selectedLanguage)}</small>
                            </div>
                        </li>)
                }
            </ul>
        </div>
    )
}
