export const postCreatePageTranslations = {
    createPostHeading: new Map([["en", "Create Post"], ["bg", "Създай статия"]]),
    clickToUploadImage: new Map([["en", "Click to upload image"], ["bg", "Кликни за да качиш снимка"]]),
    postTitlePlaceholder: new Map([["en", "Title*"], ["bg", "Заглавие*"]]),
    categorySelectDefault: new Map([["en", "Select Category*"], ["bg", "Избери категория*"]]),
    timeToReadPlaceholder: new Map([["en", "Time to read*"], ["bg", "Време за четене*"]]),
    postPreviewContentPlaceholder: new Map([["en", "Write post preview here, which will show when post is listed"], ["bg", "Напишете кратко описание, което ще се показва при показване в списък"]]),
    postContentPlaceholder: new Map([["en", "Write your amazing post*"], ["bg", "Напишете страхотният си пост (задължително поле)"]]),    
    sendBtnText: new Map([["en", "Create Post"], ["bg", "Създай Пост"]]),
    cancelBtnText: new Map([["en", "Cancel"], ["bg", "Откажи"]])
}

export const imageEditorTranslations = {
    imageEditorSaveBtnText: new Map([["en", "Save"], ["bg", "Запази"]]),
    cropPostSize: new Map([["en", "Post"], ["bg", "Статия"]]),
}

export const tagsAreaTranslations = {
    addTagPlaceholder: new Map([["en", "Add Tag"], ["bg", "Добави Таг"]])
}