import React from 'react'
import { Link } from 'react-router-dom';
import { LocalisedCategory } from '../../../models/Category';
import { useLangStore } from '../../../state/stateManagement'
import { headingTranslation } from '../../../translations/shared/pageHeading'

export default function CategoryInfo({ category }: { category: LocalisedCategory }) {
    const selectedLanguage = useLangStore(store => store.selectedLanguage);

    return (
        <>
            <h1>{category.name}</h1>
            <p className="links">
                <Link to="/">{headingTranslation.home.get(selectedLanguage)}
                    <i className="las la-angle-right"></i>
                </Link> {category.name}
            </p>
            <p>{category.description}</p>
        </>
    )
}
