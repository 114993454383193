import { ChangeRoleRequest, User } from "../models/Author";
import { getWithToken, patchWithToken } from "./baseHttpClient";

const authorsUrl = 'api/users';

export async function getUsers(accessToken: string): Promise<User[]> {
    const response = await getWithToken(accessToken, authorsUrl);
    return response as User[];
};

export async function changeUserRole(accessToken: string, data: ChangeRoleRequest) {
    const response = await patchWithToken(accessToken, `${authorsUrl}/${data.userId}/change-role`, data);
    return response;
}