import React from 'react'
import InstagramPanel from '../../Instagram/InstagramPanel'
import AboutUsImage from '../../../assets/img/other/about-1.webp'
import { Link } from 'react-router-dom'
import Heading from '../../PageHeading/Heading'
import { useLangStore } from '../../../state/stateManagement'
import { aboutPageTranslations } from '../../../translations/about/aboutPage'
import CenterHeading from '../../PageHeading/CenterHeading'
import parse from 'html-react-parser'
import SEO, { getLocalisation } from '../../Shared/SEO'

export default function About() {

    const { selectedLanguage } = useLangStore();

    return (
        <>
            {<SEO title={aboutPageTranslations.pageTitle.get(selectedLanguage)!} description='We want to provide access to knowledge, information and know-how to all people. We strive to create community which is ambitious to help each other !' localisation={getLocalisation(selectedLanguage)} ogImage={AboutUsImage} />}
            <CenterHeading headingContent={
                <Heading heading={aboutPageTranslations.heading.get(selectedLanguage)!} segment={aboutPageTranslations.segment.get(selectedLanguage)!} />
            } />
            {/* <!--about-us--> */}
            <section className="about-us">
                <div className="container">
                    <div className="about-us-area">
                        <div className="row ">
                            <div className="col-lg-12 ">
                                <div className="image">
                                    <img src={AboutUsImage} alt="" />
                                </div>

                                <div className="description">
                                    <h3>{aboutPageTranslations.pageHeading.get(selectedLanguage)!}</h3>
                                    {parse(aboutPageTranslations.pageDetails.get(selectedLanguage)!)}

                                    <Link to="/contact" className="btn-custom">{aboutPageTranslations.contactRedirectBtnText.get(selectedLanguage)!}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <InstagramPanel />
        </>
    )
}
