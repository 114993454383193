import { Category, CategoryPatchForm, LocalisedCategory } from "../../models/Category";
import { getTranslatedWord } from "../translations";

export function toLocalisedCategory(category: Category, language: string): LocalisedCategory {
    return {
        id: category.id,
        imgUrl: category.imgUrl,
        imgWideUrl: category.imgWideUrl,
        postsCount: category.postsCount,
        name: getTranslatedWord(category.name, language)!,
        description: getTranslatedWord(category.description, language)!
    }
}

export function toCategoryPatchForm(category: Category): CategoryPatchForm {
    return {
        imgUrl: category.imgUrl,
        imgWideUrl: category.imgWideUrl,
        nameBg: category.name.find(t => t.language === 'bg')!.word,
        nameEn: category.name.find(t => t.language === 'en')!.word,
        descriptionBg: category.description.find(t => t.language === 'bg')!.word,
        descriptionEn: category.description.find(t => t.language === 'en')!.word,
    }
}