import React from 'react'
import { Link } from 'react-router-dom'
import { LocalisedProduct } from '../../../../models/Shop/Product'

export default function ProductMainImage({ product }: { product: LocalisedProduct }) {
    return (
        <div className="shop-single-image">
            <Link to="#">
                {product.imageUrls.length > 0 && <img src={product.imageUrls[0]} alt="product illustration" />}
            </Link>
        </div>
    )
}
