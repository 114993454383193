export const productCreateHeadingTranslations = {
    createProduct: new Map([["en", "Create Product"], ["bg", "Създай продукт"]]),

    productNamePlaceholder: new Map([["en", "Enter Product name"], ["bg", "Въведи име на продукт"]]),
    productPriceAmountPlaceholder: new Map([["en", "Enter Product price"], ["bg", "Въведи цена на продукт"]]),
    productPriceCurrencyPlaceholder: new Map([["en", "Choose currency"], ["bg", "Изберете валута"]]),
    productPreviewContentPlaceholder: new Map([["en", "Write overview of the Product description"], ["bg", "Напишете кратко обощение за продуктът"]]),
    productContentPlaceholder: new Map([["en", "Write the product description. What is it, How it can be used, etc."], ["bg", "Напишете описание на продукта. Какво представлява, как се използва, тнт."]]),


    sendBtnText: new Map([["en", "Create Product"], ["bg", "Създай Продукт"]]),
    cancelBtnText: new Map([["en", "Cancel"], ["bg", "Откажи"]])
}