import { Author, LocalisedAuthor } from "../../models/Author";
import { getTranslatedWord } from "../translations";

export function toLocalisedAuthor(author: Author, language: string): LocalisedAuthor {
    return {
        id: author.id,
        name: getTranslatedWord(author.name, language) ?? author.name[0]?.word ?? "",
        role: author.role,
        email: author.email,
        image: author.image,
        bio: author.bio && getTranslatedWord(author.bio, language),
        isMale: author.isMale,
        isTrusted: author.isTrusted,
        articlesCount: author.articlesCount,
        socialMediaAccounts: author.socialMediaAccounts
    }
}