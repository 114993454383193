import React from 'react'
import { Link } from 'react-router-dom'
import { useCurrentUser, useLangStore } from '../../../state/stateManagement'
import { headerTranslations } from '../../../translations/shared/header'
import { AuthenticationResult, BrowserAuthError, IPublicClientApplication, PopupRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { createAuthor, getAuthorByIdentityId } from '../../../services/authorService';
import { FacebookProfilePicture } from '../../../models/FacebookProfilePicture';
import { parseJwt } from '../../../utils/parseJWT';
import { Author, CreateAuthor } from '../../../models/Author';
import { authenticationTranslations } from '../../../translations/auth/authentication';
import { toast } from 'react-toastify';
import { useFetchWithTokenWithResult } from '../../../utils/useADAuth';
import { scopes } from '../../../config/authConfig';
import { toLocalisedAuthor } from '../../../utils/mappers/authorMappers';

async function handleLogin(instance: IPublicClientApplication, language?: string): Promise<AuthenticationResult> {

    let loginRequest: PopupRequest = {
        scopes: [scopes.openId, scopes.user_impersonation],
        extraQueryParameters: { ui_locales: language ?? 'bg' }
    };

    var authResult = await instance.loginPopup(loginRequest);

    return authResult;
}

function mapToCreateAuthorRequest(idToken: string) {
    const jwtData = parseJwt(idToken);
    var picture = jwtData.picture
        ? JSON.parse(jwtData.picture).data as FacebookProfilePicture
        : undefined;

    return {
        identityId: jwtData.sub,
        name: jwtData.name,
        email: jwtData.email,
        profileImageUrl: picture?.url
    } as CreateAuthor;
}

export default function SignInBtn() {

    const { instance } = useMsal();
    const { selectedLanguage } = useLangStore();
    const fetchWithToken = useFetchWithTokenWithResult();
    const { setCurrentUser } = useCurrentUser();

    return (
        <Link onClick={
            async () => {
                //TODO: Handle case where user is in IDP, but no AuthorProfile has been created !
                await handleLogin(instance, selectedLanguage)
                    .then(async (data: any) => {
                        instance.setActiveAccount(data.account);

                        let author = undefined;

                        const authorRequest = mapToCreateAuthorRequest(data.idToken);

                        //When the user is new - create Author profile
                        if (data.idTokenClaims.newUser) {
                            author = await fetchWithToken(
                                [scopes.user_impersonation],
                                async (accessToken) => { return await createAuthor(accessToken, authorRequest) }
                            ) as Author;
                        } else {
                            author = await getAuthorByIdentityId(data.account.localAccountId)

                            if (!author) {
                                author = await fetchWithToken(
                                    [scopes.user_impersonation],
                                    async (accessToken) => { return await createAuthor(accessToken, authorRequest) }
                                ) as Author;
                            }
                        }

                        const localisedAuthor = author && toLocalisedAuthor(author, selectedLanguage);

                        localisedAuthor && setCurrentUser({
                            id: localisedAuthor.id,
                            name: localisedAuthor.name,
                            identityId: localisedAuthor.id,
                            email: localisedAuthor.email,
                            imgUrl: localisedAuthor.image,
                            role: localisedAuthor.role,
                            isMale: localisedAuthor.isMale
                        })

                        toast.success(authenticationTranslations.successfullAuth.get(selectedLanguage));
                    })
                    .catch((error) => {
                        if (error instanceof BrowserAuthError &&
                            (error.errorCode === 'interaction_in_progress' || error.errorCode === 'user_cancelled'))
                            return;
                            
                        toast.error(authenticationTranslations.failedAuth.get(selectedLanguage));
                    });
            }
        } to="#" className="btn-login" >
            {headerTranslations.signInBtnText.get(selectedLanguage)}
        </Link >
    )
}
