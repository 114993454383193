import React, { useEffect } from 'react'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { useForm } from 'react-hook-form';
import { useAuthorsStore } from '../../state/authorsStore';
import { useLangStore, useLoading } from '../../state/stateManagement';
import CenterHeading from '../PageHeading/CenterHeading';
import Heading from '../PageHeading/Heading';
import AuthorImage from './Shared/AuthorImage';
import parse from 'html-react-parser'
import { toLocalisedAuthor } from '../../utils/mappers/authorMappers';
import { toast } from 'react-toastify';
import { useFetchWithTokenWithResult } from '../../utils/useADAuth';
import { authorPromoteTrustedPage, promoteTrustedAuthorPageTranslations } from '../../translations/author/authorListPage';
import { promoteToTrusted } from '../../services/authorService';
import { scopes } from '../../config/authConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useYesNoDialogStore } from '../../state/yesNoDialogStore';
import YesNoDialog from '../Modals/Dialogs/YesNoDialog';
import { FormatString } from '../../utils/stringFormatter';

export default function AuthorPromoteTrustedPage() {
    const selectedLanguage = useLangStore(store => store.selectedLanguage);
    const { setLoading } = useLoading();
    const fetchWithToken = useFetchWithTokenWithResult();
    const { openDialog } = useYesNoDialogStore();
    const { localisedAuthors, patchAuthor, fetchAuthors } = useAuthorsStore(store => ({
        localisedAuthors: store.authors.map(a => toLocalisedAuthor(a, selectedLanguage)),
        patchAuthor: store.patchAuthor,
        fetchAuthors: store.fetchAuthors
    }));

    const { handleSubmit, } = useForm();

    useEffect(() => {
        setLoading(true);
        fetchAuthors().then(() => setLoading(false))
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='container'>
            <CenterHeading headingContent={
                <Heading heading={authorPromoteTrustedPage.heading.get(selectedLanguage)!} segment={authorPromoteTrustedPage.segment.get(selectedLanguage)!} />
            } />

            <div className='users-list'>
                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} >
                    {
                        localisedAuthors.map(author => (
                            <AccordionItem key={author.id} >
                                <AccordionItemHeading>
                                    <AccordionItemButton >
                                        <span className='user-name'>{author.name}</span>
                                        {author.isTrusted && <FontAwesomeIcon icon={faCheckCircle} className='text-success' style={{ fontSize: 24, paddingLeft: 6, marginBottom: -4 }} />}
                                        <span className='user-role float-right'>{author.role}</span>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className='author-preview row'>
                                        <div className='col-lg-2'>
                                            <AuthorImage imageUrl={author.image?.urlOrBase64} isMale={false} />
                                        </div>
                                        <div className='col-lg-8'>
                                            <h4>{author.name}</h4>
                                            <span className='mb-3 d-inline-block'>{author.email}</span>
                                            <div className='user-bio'> {parse(author.bio ?? "")} </div>
                                        </div>
                                        <div className='col-lg-2'>
                                            {author.isTrusted &&
                                                <span>
                                                    <button type='submit' name="submit" className={`btn-custom form-control disabled text-success`}>
                                                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 24, paddingRight: 4 }} /> {authorPromoteTrustedPage.apply.get(selectedLanguage)}
                                                    </button>
                                                </span>
                                            }
                                            {!author.isTrusted && <form
                                                onSubmit={handleSubmit(_ => {
                                                    openDialog({
                                                        title: FormatString(authorPromoteTrustedPage.yesNoApproveDialog.title.get(selectedLanguage)!, author.name ?? ""),
                                                        description: FormatString(authorPromoteTrustedPage.yesNoApproveDialog.body.get(selectedLanguage)!, author.name ?? ""),
                                                        yesBtnText: authorPromoteTrustedPage.yesNoApproveDialog.yesBtnText.get(selectedLanguage)!,
                                                        noBtnText: authorPromoteTrustedPage.yesNoApproveDialog.noBtnText.get(selectedLanguage)!
                                                    },
                                                        () => toast.promise(
                                                            fetchWithToken([scopes.user_impersonation],
                                                                async (accessToken) => {
                                                                    const promotedAuthorId = await promoteToTrusted(accessToken, author.id)
                                                                    promotedAuthorId && patchAuthor(promotedAuthorId, { isTrusted: true })
                                                                }
                                                            ),
                                                            promoteTrustedAuthorPageTranslations.toastMessages.get(selectedLanguage)!
                                                        )
                                                    );
                                                })}
                                            >
                                                <button type='submit' name="submit" className={`btn-custom form-control d-block`}>
                                                    {authorPromoteTrustedPage.apply.get(selectedLanguage)}
                                                </button>
                                            </form>}
                                        </div>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))
                    }
                </Accordion>
            </div>

            <YesNoDialog />
        </div >
    )
}
