import React, { useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import EditorToolbarLg, { formatsLg, useModulesLg } from "./MainToolbar";
import "react-quill/dist/quill.snow.css";
import { postImagesFolder } from "../../config/config";
import { DeltaStatic } from "quill";
import { useCurrentUserAzureFileUploader } from "../../services/fileUploaderService";

export const Editor = ({ initValue = "", onChange, placeholder, className }: { initValue?: string, onChange: (val: string) => void, placeholder?: string, className?: string }) => {
    const toolbarEditorLink = Math.random().toString(36).replace(/[^a-z]+/g, '').slice(-5);

    const modules = useModulesLg(postImagesFolder, toolbarEditorLink);
    const { deleteImages } = useCurrentUserAzureFileUploader();
    let lastContents: DeltaStatic;
    const getImgUrls = (delta?: DeltaStatic): string[] | undefined => delta?.ops?.filter(i => i.insert && i.insert.image).map(i => i.insert.image);

    // Placeholder change hack
    var editorRef = useRef<ReactQuill>(null);
    useEffect(() => {
        editorRef.current && (editorRef.current.getEditor().root.dataset.placeholder = placeholder || "");
    }, [editorRef, placeholder]);


    return (
        <div className={`text-editor ${className}`}>
            <EditorToolbarLg id={toolbarEditorLink} />
            <ReactQuill
                className="editor-lg"
                theme="snow"
                ref={editorRef}
                value={initValue}
                onChange={(currentValue, delta, source, editor) => {
                    if (source !== 'user') return;
                    if (!lastContents) lastContents = editor.getContents(); // When the editor has initial content

                    if (delta?.ops?.filter(i => i.delete).length) {
                        const deletedBlobUrls = getImgUrls(editor.getContents().diff(lastContents));
                        if (deletedBlobUrls?.length) { deleteImages(deletedBlobUrls); }
                    }

                    lastContents = editor.getContents();
                    onChange(currentValue);

                }}
                placeholder={placeholder}
                modules={modules}
                formats={formatsLg}
            />
        </div>
    );
};

export default Editor;
