import { ToastPromiseParams } from "react-toastify"

export const contactTranslations = {
    pageTitle: new Map([["en", "Contact"], ["bg", "Контакти"]]),
    heading: new Map([["en", "Contact Us"], ["bg", "Контакти"]]),
    segment: new Map([["en", "Contact Us"], ["bg", "Контакти"]]),

    formHeading: new Map([["en", "How can we help you ?"], ["bg", "Как можем да ви помогнем ?"]]),
    formDetails: new Map([
        ["en", `If you have any questions or recommendations for improvements of our website, please fill the contact form below and we will reach out to you in 3 working days.`],
        ["bg", `Ако имате някакви въпроси или препоръки за подобрение на уебсайта, моля попълнете контактната форма долу и ние ще се свържем с вас до 3 работни дни.`]]),
    formNameInputPlaceholder: new Map([["en", "Name*"], ["bg", "Име*"]]),
    formEmailInputPlaceholder: new Map([["en", "Email*"], ["bg", "Е-мейл*"]]),
    formSubjectInputPlaceholder: new Map([["en", "Subject*"], ["bg", "Относно*"]]),
    formMessageInputPlaceholder: new Map([["en", "Message*"], ["bg", "Съобщение*"]]),
    formSubmitBtnText: new Map([["en", "Send Message"], ["bg", "Изпрати"]]),

    formSubmitMessage: new Map([["en", "Your message was sent successfully."], ["bg", "Вашето съобщение беше изпрате успешно."]]),
}

export const createInqueryTranslations = {
    toastMessages: new Map<string, ToastPromiseParams<void>>([
        ["en", { pending: "Creating inquery...", success: "Inquery created successfully!", error: "Inquery creation failed !" }],
        ["bg", { pending: "Създаване на запитване...", success: "Запитването беше създадено успешно!", error: "Запитването не беше създадено успешно!" }]
    ]),
    alreadySubmited: new Map([["en", "Your've already submitted form to 'Become Author'"], ["bg", "Вече предоставихте формуляр за 'Стани Автор'"]]),
}

export const becomeAuthorTranslations = {
    heading: new Map([["en", "Become Author"], ["bg", "Стани Автор"]]),
    segment: new Map([["en", "Become Author"], ["bg", "Стани Автор"]]),

    formHeading: new Map([["en", "You want to become an author ?"], ["bg", "Искате да станете автор ?"]]),
    formDetails: new Map([
        ["en", `To become an author in our system, you have to fill the form bellow. We will need couple of days to review your form, that's because we want to restrict approving authors who will add spam and bad content. Becoming an author you will be granted access to create articles.`],
        ["bg", `За да станете автор в нашата система, трябва да попълните формуляра долу. Ще са ни необходими няколко дни, за да проверим вашият формуляр, това е така защото искаме да проверим всеки автор с цел предпазване на общността ни. Като станете автор ще имате достъп до фунционалности за създаване на статия.`]]),
    formMessageInputPlaceholder: new Map([["en", "I want to become an author because ...?"], ["bg", "Искам да стана автор, защото ...?"]]),
    formSubmitBtnText: new Map([["en", "Submint"], ["bg", "Изпрати"]]),

    inquerySubject: new Map([["en", "I want to become Author"], ["bg", "Искам да стана Автор"]]),

    inqueryInProgress: new Map([["en", "Your request is Pending"], ["bg", "Вашата Заявка Се Обработва"]]),
}