import React from 'react'
import defaultFemaleAvatar from '../../../assets/img/author/female-avatar.png'
import defaultMaleAvatar from '../../../assets/img/author/male-avatar.png'

export default function AuthorImage({ imageUrl, isMale = true }: { imageUrl?: string, isMale?: boolean }) {

    const authorProfileImg = imageUrl 
        ? imageUrl 
        : isMale 
            ? defaultMaleAvatar
            : defaultFemaleAvatar;

    return (
        <img src={authorProfileImg} alt="Profile" className='author-image' />
    )
}
