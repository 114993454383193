import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { BlogImage } from '../../models/Image';
import ImageEditable from './ImageEditable'

export default function ImageGallery({ onChange }: { onChange?: (images: BlogImage[]) => void }) {

    // Preview image (after edited in the Image editor)
    const [editedImageData, setEditedImageData] = useState<BlogImage | undefined>();

    const [galleryImages, setGalleryImages] = useState<BlogImage[]>([]);

    return (
        <section className='image-gallery row'>
            <div className='main-image col-xl-4'>
                <ImageEditable
                    imageData={editedImageData}
                    onSave={(image) => {
                        if (image) {
                            const newImages: BlogImage[] = galleryImages.some(gImg => gImg.name === image?.name)
                                ? [...galleryImages.filter(gImg => gImg.name !== image.name), image]
                                : [...galleryImages, image!];

                            setGalleryImages(newImages);
                            setEditedImageData(undefined);

                            onChange && onChange(newImages);
                        }
                    }}
                    onImageLoad={(image) => { setEditedImageData(image); }}
                    onEditorClose={() => { setEditedImageData(undefined); }}
                />
            </div>
            <div className='col-xl-8'>
                <div className='images-list row'>
                    {
                        galleryImages.map(img =>
                            <div className='col-xl-4 gallery-image mb-2' key={img.name}>
                                <div className='gallery-image-wrapper'>
                                    <FontAwesomeIcon
                                        className='right'
                                        icon={faClose}
                                        onClick={() => {
                                            setGalleryImages(galleryImages.filter(gImg => gImg.name !== img.name))
                                            if (editedImageData?.name === img.name) {
                                                console.log(editedImageData?.name, img.name)
                                                setEditedImageData(undefined);
                                            }
                                        }}
                                    />
                                    <img alt='product' src={img.urlOrBase64} onClick={() => { setEditedImageData(img) }} />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </section>
    )
}
