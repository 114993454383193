import React from 'react'
import { Link } from 'react-router-dom';
import defaultFemaleAvatar from '../../../assets/img/author/female-avatar.png'
import defaultMaleAvatar from '../../../assets/img/author/male-avatar.png'
import { useCurrentUser } from '../../../state/stateManagement';

export default function ProfileImage() {

    const { currentUser } = useCurrentUser();

    const authorProfileImg = currentUser?.imgUrl
        ? currentUser?.imgUrl.urlOrBase64
        : currentUser?.isMale
            ? defaultMaleAvatar
            : defaultFemaleAvatar;

    return (
        <Link to={`/authors/${currentUser?.id}`}>
            <img src={authorProfileImg} alt=""/>
        </Link>
    )
}
