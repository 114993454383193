import React from 'react'
import { Link } from 'react-router-dom'
import { formatToBlogDate } from '../../../utils/dateFormatter'
import { useLangStore } from '../../../state/stateManagement'
import { postItemAuthorSinglePage } from '../../../translations/author/authorSinglePage'
import { LocalisedPost } from '../../../models/PostDetails'

function AuthorPostListItem({ postItem: { id, images, category, createdOnUtc, title } }: { postItem: LocalisedPost }) {

    const { selectedLanguage } = useLangStore();

    return (
        <div className="post-list post-list-style4">
            <div className="post-list-image">
                <Link to={`/posts/${id}`}>
                    <img src={images?.length ? images[0].url : category.imgUrl} alt="" />
                </Link>
            </div>
            <div className="post-list-content">
                <ul className="entry-meta">
                    <li className="entry-cat">
                        <Link to={`/categories/${category.id}`} className="category-style-1">{category.name}</Link>
                    </li>
                    <li className="post-date"> <span className="line"></span> {formatToBlogDate(createdOnUtc, selectedLanguage)}</li>
                </ul>
                <h5 className="entry-title">
                    <Link to={`/posts/${id}`}>{title}</Link>
                </h5>
                <div className="post-btn">
                    <Link to={`/posts/${id}`} className="btn-read-more">
                        {postItemAuthorSinglePage.continueReading.get(selectedLanguage)}
                        <i className="las la-long-arrow-alt-right"></i>
                    </Link>
                </div>
            </div>
        </div >
    )
}

export default AuthorPostListItem