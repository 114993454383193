import React from 'react'
import { Link } from 'react-router-dom'

export default function InstagramPanel(): any {
    return (
        <div className="instagram">
            <div className="container">
                <div className="instagram-area">
                    <div className="instagram-list">
                        <div className="instagram-item">
                            <Link to="#">
                                <img src={require('../../assets/img/instagram/1.jpg')} alt="" />
                                <div className="icon">
                                    <i className="lab la-instagram"></i>
                                </div>
                            </Link>
                        </div>

                        <div className="instagram-item">
                            <Link to="#">
                                <img src={require('../../assets/img/instagram/2.jpg')} alt="" />
                                <div className="icon">
                                    <i className="lab la-instagram"></i>
                                </div>
                            </Link>
                        </div>
                        <div className="instagram-item">
                            <Link to="#">
                                <img src={require("../../assets/img/instagram/3.jpg")} alt="" />
                                <div className="icon">
                                    <i className="lab la-instagram"></i>
                                </div>
                            </Link>
                        </div>
                        <div className="instagram-item">
                            <Link to="#">
                                <img src={require("../../assets/img/instagram/4.jpg")} alt="" />
                                <div className="icon">
                                    <i className="lab la-instagram"></i>
                                </div>
                            </Link>
                        </div>
                        <div className="instagram-item">
                            <Link to="#">
                                <img src={require("../../assets/img/instagram/5.jpg")} alt="" />
                                <div className="icon">
                                    <i className="lab la-instagram"></i>
                                </div>
                            </Link>
                        </div>
                        <div className="instagram-item">
                            <Link to="#">
                                <img src={require("../../assets/img/instagram/6.jpg")} alt="" />
                                <div className="icon">
                                    <i className="lab la-instagram"></i>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
