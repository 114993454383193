import { NotificationResponseItem } from "../models/Notifications";
import { getWithToken, postWithToken } from "./baseHttpClient";

const notificationsUrl = 'api/notifications';

export async function getSpecificUserNotifications(accessToken: string, userId: string): Promise<NotificationResponseItem[]> {
    const response = await getWithToken(accessToken, `${notificationsUrl}/${userId}`) as NotificationResponseItem[];
    return response;
}

export async function getCurrentUserNotifications(accessToken: string): Promise<NotificationResponseItem[]> {
    const response = await getWithToken(accessToken, `${notificationsUrl}`) as NotificationResponseItem[];
    return response;
}

export async function markCurrentUserNotificationsAsViewed(accessToken: string) {
    const response = await postWithToken(accessToken, `${notificationsUrl}/mark-viewed`, {}) as NotificationResponseItem[];
    return response;
}

export async function markAllCurrentUserNotificationAsRead(accessToken: string) {
    const response = await postWithToken(accessToken, `${notificationsUrl}/mark-read`, {}) as NotificationResponseItem[];
    return response;
}

export async function markCurrentUserNotificationAsRead(accessToken: string, notificationId: string) {
    const response = await postWithToken(accessToken, `${notificationsUrl}/${notificationId}/mark-read`, {}) as NotificationResponseItem[];
    return response;
}