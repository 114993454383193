import React, { useEffect } from 'react'
import { useLangStore, useLoading } from '../../state/stateManagement'
import AdHorizontal from '../Generic/Ads/AdHorizontal'
import InstagramPanel from '../Instagram/InstagramPanel'
import Sidebar from '../Sidebar/Sidebar'
import CategoryListSection from './Home/CategoryListSection'
import MostCommentedPostListSection from './Home/MostCommentedPostListSection'
import MainPost from './Home/MainPost'
import PopularPostListSection from './Home/PopularPostListSection'
import RecentPostListSection from './Home/RecentPostListSection'
import { homePageSelector, usePostDetailsStore } from '../../state/postDetailsStore'
import { useCategoriesStore } from '../../state/categoriesStore'
import { toLocalisedCategory } from '../../utils/mappers/categoryMappers'
import { toLocalisedPost } from '../../utils/mappers/postMappers'
import SEO, { getLocalisation } from '../Shared/SEO'
import Logo from '../../assets/img/logo/SYP_white_theme.webp'
import { homePageTranslations } from '../../translations/home/homePageTranslations'
import { getPostsTranslations } from '../../services/blogPostService'
import { hasTranslationIn } from '../../utils/translations'

export default function HomePage() {

    const { loadingFetch } = useLoading();
    const selectedLanguage = useLangStore(store => store.selectedLanguage);

    // Categories store
    const { categories, fetchCategories } = useCategoriesStore();

    // Posts store
    const { mainPost, mostCommentedPosts, mostPopularPosts, mostRecentPosts, fetchHomePagePosts, addPostsTranslations } = usePostDetailsStore(homePageSelector);

    // Initialization of the Page
    useEffect(() => {
        loadingFetch([fetchCategories(), ...fetchHomePagePosts.map(request => request(selectedLanguage))]);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // On language change load translations
    useEffect(() => {
        let postIdsWithoutTranslationsLoaded: string[] = [
            ...(mostCommentedPosts?.filter(p => !hasTranslationIn(selectedLanguage, p)).map(p => p.id) ?? []),
            ...(mostPopularPosts?.filter(p => !hasTranslationIn(selectedLanguage, p)).map(p => p.id) ?? []),
            ...(mostRecentPosts?.filter(p => !hasTranslationIn(selectedLanguage, p)).map(p => p.id) ?? [])
        ];

        let distinctIds = [...new Set(postIdsWithoutTranslationsLoaded)];

        if (distinctIds.length > 0)
            loadingFetch([
                getPostsTranslations(distinctIds, selectedLanguage).then(translations => { addPostsTranslations(translations, selectedLanguage); })
            ])
    }, [selectedLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {<SEO title={homePageTranslations.pageTitle.get(selectedLanguage)!} description={homePageTranslations.pageDescription.get(selectedLanguage)!} localisation={getLocalisation(selectedLanguage)} ogImage={Logo} />}
            {mainPost && <MainPost post={toLocalisedPost(mainPost, selectedLanguage)} />}
            {categories && <CategoryListSection categories={categories.map(c => toLocalisedCategory(c, selectedLanguage))} />}
            {mostPopularPosts && <PopularPostListSection posts={mostPopularPosts.map(p => toLocalisedPost(p, selectedLanguage))} />}
            <AdHorizontal />
            {mostCommentedPosts && <MostCommentedPostListSection posts={mostCommentedPosts.map(p => toLocalisedPost(p, selectedLanguage))} sidebar={<Sidebar />} />}
            {mostRecentPosts && <RecentPostListSection posts={mostRecentPosts.map(p => toLocalisedPost(p, selectedLanguage))} />}
            <InstagramPanel />
        </>
    )
}
