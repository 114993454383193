import React from 'react'
import { LocalisedPost } from '../../../models/PostDetails'
import SmallPost from './SmallPost'

export default function PreviousNextPostSection({ previousPost, nextPost }: { previousPost?: LocalisedPost, nextPost?: LocalisedPost }) {
    return (
        <div className="post-single-next-previous">
            <div className="row ">

                <div className="col-md-6">
                    {previousPost && <SmallPost post={previousPost} isNext={false} />}
                </div>

                <div className="col-md-6">
                    {nextPost && <SmallPost post={nextPost} isNext={true} />}
                </div>

            </div>
        </div>
    )
}
