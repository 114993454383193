import React from 'react'
import { Link } from 'react-router-dom'
import { useLangStore } from '../../../state/stateManagement';
import { sidebarTagsSection } from '../../../translations/shared/sidebar';

export default function TagsSection({ tags }: { tags: Tag[] }) {

    const { selectedLanguage } = useLangStore();

    return (
        <div className="widget">
            <div className="widget-title">
                <h5>{sidebarTagsSection.tagsHeading.get(selectedLanguage)}</h5>
            </div>
            <div className="tags">
                <ul className="list-inline">
                    {
                        tags.sort((a, b) => { return a.searchedForCount - b.searchedForCount }).slice(0, 20).map(t =>
                            <li key={t.name}>
                                <Link to={`/posts/search?tag=${t.name.toLowerCase()}`}>{t.name}</Link>
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}
