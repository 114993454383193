import { create } from "zustand";
import { User } from "../models/Author";
import { getUsers } from "../services/userService";

interface UsersStore {
    users: User[],
    isInit: boolean,

    fetchUsers: (accessToken: string) => Promise<void>
}

export const useUsersStore = create<UsersStore>((set, get) => ({
    users: [],
    isInit: false,

    async fetchUsers(accessToken) {
        if (!get().isInit) {
            const fetchedUsers = await getUsers(accessToken);
            set(({ users: fetchedUsers, isInit: true }));
        }
    },
}))