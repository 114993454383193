import React from 'react'
import { useLangStore } from '../../../state/stateManagement'
import PrivacyPolicyBg from './PrivacyPolicy/PrivacyPolicyBg'
import PrivacyPolicyEn from './PrivacyPolicy/PrivacyPolicyEn'

export default function PrivacyPolicy() {

    const lang = useLangStore(store => store.selectedLanguage)

    return (
        <div className='container mt-100 mb-40 pt-40'>
            {lang.toLowerCase() === "bg"
                ? <PrivacyPolicyBg />
                : <PrivacyPolicyEn />}
        </div>
    )
}
