import { ToastPromiseParams } from "react-toastify"

export const sidebarSearchSection = {
    searchHeading: new Map([["en", "Search"], ["bg", "Търсене"]]),
    searchInputPlaceholder: new Map([["en", "Search ..."], ["bg", "Какво търсиш ?"]]),
}

export const sidebarCategoriesSection = {
    categoriesHeading: new Map([["en", "Categories"], ["bg", "Категории"]]),
}

export const sidebarSubscribeSection = {
    headingText: new Map([["en", "Subscribe To Our Newsletter"], ["bg", "Абонирай се за известия"]]),
    subHeadingText: new Map([["en", "No spam, notifications only about new products, updates."], ["bg", "Без спам, известия само за нови статии и промени."]]),
    emailInputPlaceholder: new Map([["en", "Your Email Address"], ["bg", "Твоят е-мейл адрес"]]),
    subscribeBtnText: new Map([["en", "Subscribe Now"], ["bg", "Абонирай се сега"]]),
}

export const sidebarSocialMediaSection = {
    stayConnectedHeading: new Map([["en", "Stay Connected"], ["bg", "Социални мрежи"]]),
}

export const sidebarTagsSection = {
    tagsHeading: new Map([["en", "Popular Tags"], ["bg", "Популярни Тагове"]]),
}

export const sidebarPopularPostsSection = {
    popularPostsHeading: new Map([["en", "Popular Posts"], ["bg", "Полулярни статии"]]),
}

export const sidebarSubscribeFormTranslations = {
    toastMessages: new Map<string, ToastPromiseParams<void>>([
        ["en", { pending: "Creating subscription...", success: "Subscription created ! Confirm it on your email.", error: "Subcription creation failed !" }],
        ["bg", { pending: "Създаване на абонамент...", success: "Създаването беше успешно ! Проверете Е-мейлът си за да потвърдите.", error: "Създаването беше неуспешно !" }]
    ]),

    invalidEmail: new Map([["en", "Please enter valid email !"], ["bg", "Въведете валиден е-мейл"]]),
}