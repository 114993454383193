import React, { forwardRef, useEffect, useState } from 'react'
import { getImageDimensions } from '../../../utils/fileUtils';

const HiddenImageInput = forwardRef<HTMLInputElement, { onChange?: (file: File) => void, onImageLoaded: (imageUrl: string, imgName: string, heightInPx: number, widthInPx: number) => void }>(({ onChange, onImageLoaded }, ref) => {

    const [file, setFile] = useState<File | null>(null);

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const imageMimeType = /image\/(png|jpg|jpeg)/i;
        if (e && e.target && e.target.files) {
            const file = e.target.files[0];

            if (!file.type.match(imageMimeType)) {
                alert("Image mime type is not valid");
                return;
            }

            setFile(file);

            if (onChange)
                onChange(file);
        }
    }

    useEffect(() => {
        let fileReader: FileReader, isCancel = false;
        if (file) {
            fileReader = new FileReader();
            fileReader.onload = async (e: ProgressEvent<FileReader>) => {

                if (e && e.target) {
                    const { result } = e.target;
                    if (result && !isCancel) {
                        const dimentions = await getImageDimensions(result as string)
                        onImageLoaded(result as string, file.name, dimentions.h, dimentions.w);
                    }
                }
            }
            fileReader.readAsDataURL(file);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }

    }, [file]);  // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <input
            ref={ref}
            aria-label='browse image'
            className='d-none'
            type="file"
            accept='.png, .jpg, .jpeg'
            onChange={changeHandler}
            onClick={e => ((e.target as any).value = null)} // to trigger on change even if the same file is selected
        />
    )
})

export default HiddenImageInput;