import React, { useMemo } from "react";
import { Quill } from "react-quill";

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
    "roboto",
    "raleway",
    "monserat",
    "lato",
    "rubik",
    "poor-story",
    "griffy"
];
Quill.register(Font, true);

export const useModulesSm = ( toolbarContainerId = 'toolbar-lg') => {
    // Using memo because modules have to be const
    // And there is problem with imageUploader
    // That's kind of fix in order to show the editor pane
    const modulesSm = useMemo(() => {
        return {
            toolbar: {
                container: `#${toolbarContainerId}`
            },
            history: {
                delay: 500,
                maxStack: 100,
                userOnly: true
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return modulesSm;
}

// Formats objects for setting up the Quill editor
export const formatsSm = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "link",
    "code-block",
    "color"
];

// Quill Toolbar component
export const QuillToolbarSm = ({ id = 'toolbar-sm' }) => (
    <div id={id}>
        <span className="ql-formats">
            <select className="ql-font" defaultValue={'roboto'}>
                <option value="roboto">Roboto</option>
                <option value="poor-story">Poor Story</option>
                <option value="griffy">Griffy</option>
                <option value="raleway">Arial</option>
                <option value="montserrat">Arial</option>
                <option value="lato">Helvetica</option>
                <option value="rubik">Lucida</option>
            </select>
        </span>
        <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-strike" />
        </span>
        <span className="ql-formats">
            <button className="ql-script" value="super" />
            <button className="ql-script" value="sub" />
            <button className="ql-blockquote" />
        </span>
        <span className="ql-formats">
            <select className="ql-align" />
            <select className="ql-color" />
            <select className="ql-background" />
        </span>
        <span className="ql-formats">
            <button className="ql-link" />
        </span>
    </div>
);

export default QuillToolbarSm;
