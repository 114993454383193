import React from 'react'
import { Link } from 'react-router-dom';
import StickyBox from "react-sticky-box";
import { formatToBlogDate } from '../../../utils/dateFormatter';
import { useLangStore } from '../../../state/stateManagement';
import AuthorImage from '../../Author/Shared/AuthorImage';
import parse from 'html-react-parser'
import { LocalisedPost } from '../../../models/PostDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

export default function PostMultiple({ posts, sidebar, pagingComponent }: { posts: LocalisedPost[], sidebar: JSX.Element, pagingComponent: JSX.Element }) {

    const { selectedLanguage } = useLangStore();

    return (
        <section className="blog-layout-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <StickyBox offsetTop={100}>
                            {
                                posts.map((p, i) =>
                                    <div key={p.id} className={`post-list post-list-style5 ${i === 0 ? 'pt-0' : ''}`}>
                                        <div className="post-list-content">
                                            <h4 className="entry-title">
                                                <Link to={`/posts/${p.id}`}>{p.title}</Link>
                                            </h4>
                                            <ul className="entry-meta">
                                                <li className="post-author-img"><AuthorImage imageUrl={p.author.imgUrl} isMale={p.author.isMale} /></li>
                                                <li className="post-author"> <Link to={`/authors/${p.author.id}`}>{p.author.name}</Link></li>
                                                <li className="entry-cat">
                                                    <Link to={`/categories/${p.category.id}`} className="category-style-1">
                                                        <span className="line"></span>{p.category.name}
                                                    </Link>
                                                </li>
                                                <li className="post-date"> <span className="line"></span>{formatToBlogDate(p.createdOnUtc, selectedLanguage)}</li>
                                                <li className="post-visits"> <span className="line"></span><FontAwesomeIcon icon={faEye} /> {p.visitCount}</li>
                                            </ul>
                                            <div className="post-exerpt">
                                                {parse(p.contentPreview)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            {pagingComponent}
                        </StickyBox>
                    </div>

                    <div className="col-lg-4">
                        {sidebar}
                    </div>
                </div>
            </div>
        </section>
    )
}
