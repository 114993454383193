import React from 'react'
import { Link } from 'react-router-dom'
import { appDomain, companyName, contactEmail } from '../../../../config/config'

export default function PrivacyPolicyBg() {
    return (
        <>
            <h1>Политика на поверителност</h1>
            <p>В действие от: Ноември 20, 2022</p>
            <p>{companyName} ("ние", "наша", или "нашата") оперира с {appDomain} уеб сайтът (наричан "Услугата").</p>
            <p>Тази страница има за цел да ви информира за нашите политики по отношение на събирането, използването и заличаването на лични данни когато използвате нашата Услуга и правата, които имате асоциирани с тези данни.</p>
            <p>
                Ние използваме вашите данни за да предоставим и подобрим Услугата.
                С използването на Услугата, вие се съгласявате със събирането и използването на информация
                в рамките описани в този документ.</p>

            <h2>Събиране и използване на информация</h2>
            <p>
                Събираме няколко различни типа данни за различни цели, които ни дават възможност да предоставим и подобрим услугата си за вас
            </p>

            <h3>Видове данни, които съхраняваме.</h3>

            <h4>Лични данни</h4>
            <p>
                Докато използване нашата Услуга, може да поискаме да предоставите със специфична лична информация
                която да бъде използвана за свързване или идентификация ("Лични данни").
                Личните данни за индентификация може да включват, но не се ограничават до:
            </p>
            <ul>
                <li>Е-мейл адрес</li>
                <li>Малко име и Фамилно име</li>
                <li>Телефонен номер</li>
                <li>Адрес, Община, Област, Пощенски код, Град</li>
                <li>"Бисквитки" и "Данни за потребление"</li>
            </ul>

            <h4>Данни за потребление</h4>
            <p>
                Ние може също да събираме информация относно как Услугата е достъпена и използвана (Данни за потребление)
                Тези данни може да съдържат информация, като IP адресът на вашият компютър, информация за браузърът ви,
                версия на браузърът ви, страниците от Услугата ни, които сте посетили, времето което сте прекарали и датата на която сте я посетили,
                уникален номер на устройството и друга информация за диагностика.
            </p>

            <h4>Проследяващи данни и "Бисквитки"</h4>
            <p>
                Ние използваме "Бисквитки" или подобни следящи технологии да проследяват активността в нашата Услуга или да съхраняват определена информация
            </p>
            <p>
                "Бисквитките" са файлове с малко количество данни, които може да съдържат анонимен уникален идентификатор.
                "Бисквитките" се изпращат към браузърът ви от уеб страница и съхранявани на вашето устройство.
                Технологии за проследяване, които също се използват са тагове, скриптове за събиране и проследяване на информация за подобряване и анализ на нашата Услугата.
            </p>
            <p>
                Можете да настройте вашият браузър да отказва всички "Бисквитки" или да индикира когато "Бисквитка" е изпратена.
                Ако обаче решите да откажете "Бисквитка" е възможно част от нашата Услуга да не работи правилно.
            </p>
            <p>Примери за "Бисквитки", които използваме:</p>
            <ul>
                <li><strong>"Бисквитки" за сесия.</strong> Използваме ги за да оперира нашата услуга.</li>
                <li><strong>"Бисквитки" с предпочитания.</strong> Използваме за съхраняване на вашите предпочитания за бъдещи посещения.</li>
                <li><strong>"Бисквитки" за сигурност.</strong> използваме с цел сигурност.</li>
            </ul>

            <h2>Използване на данни</h2>
            <p>{companyName} използва събраните данни за следните цели:</p>
            <ul>
                <li>Да предостави и поддържа Услугата</li>
                <li>Да извести при промени в нашата Услуга</li>
                <li>
                    Да позволи участието в интерактивни функционалности част от нашата Услуга, когато изберете да направите това
                </li>
                <li>Да предостави грижа към клиента и поддръжка</li>
                <li>Да предостави анализ да важна информация за да се подобри Услугата</li>
                <li>Да следи ползването на Услугата</li>
                <li>Да засече, предотврати и адресира технически проблеми</li>
            </ul>

            <h2>Пренос на данни</h2>
            <p>
                Вашата информация, включително Лични данни, може да бъдат прехвърлени към, и поддържани на компютри локализирани извън държавата и/или под чужда политическа юрисдикция, където защитата на данните се различават от тези, които са на местно ниво.
            </p>
            <p>
                Ако сте локирани извън България и изберете да предоставите ваша информация, моля имайте предвид, че пренасяме данните, включително Личните данни, до България и обработваме там.
            </p>
            <p>
                Вашето съгласие към тази Политика за поверителност последвано от изпращане на такава информация представлява потвърждаване към това предаване на информация.
            </p>
            <p>
                {companyName} ще предприеме всички разумни стъпки необходими да подсигури за това, че данните ви ще бъдат обработвани сигурно и според правилата на Политиката за поверителност и никакви прехвърляния на Лични данни, ще биват предприема към организации или държави освен, ако адекватен контрол не е наличен, включително сигурността на вашите данни и друга Лична информация.
            </p>

            <h2>Разкриване на данни</h2>

            <h3>Законни задължения</h3>
            <p>{companyName} може да предостави ваши Лични данни с вярата, че това действие е необходимо за:</p>
            <ul>
                <li>Да отговори на задължения породени от закон.</li>
                <li>Да защити права или собственост на {companyName}</li>
                <li>Да предотврати или изследва възможна нередност във връзка с Услугата</li>
                <li>Да защити личната безопасност на потребителите на Услугата или публичността</li>
                <li>Да се защити от юридическа отговорност</li>
            </ul>

            <h2>Сигурност на данните</h2>
            <p>
                Сигурността на данните ви е важна за нас, но помнете, че няма 100% сигурен метод за пренасяне на данни посредством интернет или за съхраняване на данни!
                Въпреки, че се стремим да използваме всичко възможно да защитим Личните ви данни, не можем да гарантираме абсолютна сигурност на същите!
            </p>

            <h2>Доставчици на услуги</h2>
            <p>
                Може да използваме външни доставици или личности да предоставят нашата услуга ("Доставчици на услуга"),
                да предоставят Услугата от наше име, да предоставят услуги свързани с нашата Услуга или да ни асистират с анализирането на това как се ползва услугата ни.
            </p>
            <p>
                Тези Доставчици имат достъп до вашата Лична информация само за да извършат тези задачи от наше име
                и са задължени да не изобличават и използват данните за други услуги.
            </p>

            <h3>Анализиране</h3>
            <p>Може да използваме Услуги от трети лица за наблюдение и анализ на ползването на нашата Услуга</p>
            <ul>
                <li>
                    <p><strong>Google Analytics</strong></p>
                    <p>Google Analytics е интернет услуга за анализ предлагана от "Google", която проследява и докладва трафикът в уеб страницата. Google използва данните събрани за да проследи и наблюдава използването на нашата Услуга. Данните са споделени с други Google услуги. Google може да използва събраната информация за създаване на контекст и персонализиране на реклами от стоята рекламна мрежа.</p>
                    <p>Вие може да спрете събирането на данни от Google като инсталирате "Google Analytics opt-out" добавката за браузъри. Добавката предотвратява "Google Analytics" кодът (ga.js, analytics.js, and dc.js) да споделя информация с "Google Analytics" относно посещенията ви.</p>
                    <p>За повече информация отностно правилата за поверителност на Google, моля посетете: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
                </li>
            </ul>

            <h2>Линкове към други страници</h2>
            <p>
                Нашата Услуга може да съдържа хипервръзки към други уеб страници, които не се оперират от нас.
                Ако натиснете върху хипервръзка към трета страна, вие ще бъдете пренасочени към уеб страницата на тази трета страна.
                Ние силно препоръчваме да прегледате Политиката за поверителност на всеки сайт, който посещавате.
            </p>
            <p>
                Ние нямаме контрол върху и не отговаряме за съдържанието, политиката за поверителност или практики от които и да е уеб страници или услуги на трети страни.
            </p>

            <h2>Поверителност при децата</h2>
            <p>Нашата услуга не адресира никой на възраст до 18 години ("Малолетен/Непълнолетен").</p>
            <p>
                Ние не събираме умишлено лична информация за личности под 18 годишна възраст.
                Ако сте родител или настойник и сте наясно, че вашето дете е предоставило личната си информация, моля свържете се с нас. Ако ни стане известно, че сме събрали Лични данни от дете без родителско съгласие, ние предприемаме стъпки за премахването на тази информация от нашите сървъри.
            </p>

            <h2>Заявки за Изтриване на Данни</h2>
            <p>Имате право да поискате изтриване на Вашите лични данни, съхранявани от {companyName}. За да инициирате заявка за изтриване на данни, следвайте тези стъпки:</p>

            <h2>Подаване на Заявка за Изтриване на Данни</h2>
            <p>
                За да поискате изтриване на данни, можете да се свържете с нас по един от следните начини:
                - Имейл: Изпратете имейл на {contactEmail}, ясно указвайки Вашата заявка за изтриване на данни. Моля, използвайте предмета на съобщението "Заявка за Изтриване на Данни", за да ни помогнете да идентифицираме и обработим Вашата заявка бързо.
                - Формуляр за контакт: Посетете нашия уебсайт {appDomain} и попълнете формуляра за контакт, който е достъпен на страницата "Свържете се с нас". В полето за съобщение уточнете Вашата заявка за изтриване на данни.
            </p>

            <h2>Проверка на Идентичността</h2>
            <p>Поради съображения за сигурност може да се наложи да потвърдим Вашата идентичност, за да удостоверим, че заявката за изтриване на данни е легитимна. При подаване на заявка, моля, бъдете готови да предоставите достатъчна информация, която да ни помогне да потвърдим Вашата идентичност. Това може да включва предоставянето на Вашето потребителско име, имейл адрес или други съответни идентификационни данни.</p>

            <h2>Обработка на Заявката за Изтриване на Данни</h2>
            <p>След получаване и потвърждение на Вашата заявка за изтриване на данни, ние ще предприемем необходимите стъпки за изтриване на Вашите лични данни от нашите системи, в съответствие с приложимите закони за защита на данните. Моля, обърнете внимание, че някои данни може да бъдат задържани съгласно закона за правни, счетоводни или легитимни бизнес цели, дори след изтриването, както позволява законът.</p>

            <h2>Потвърждение на Изтриването</h2>
            <p>След успешното изтриване на Вашите данни, ние ще Ви предоставим потвърждение, че Вашата заявка е била изпълнена. Това потвърждение ще бъде изпратено на имейл адреса или контактната информация, която предоставихте по време на процеса на заявка.</p>

            <h2>Срок за Изтриване на Данни</h2>
            <p>Ние ще полагаме всички усилия, за да обработим заявките за изтриване на данни в срок, съобразно приложимите закони за защита на данните. Въпреки това точният срок за изтриване на данни може да варира в зависимост от сложността на заявката и обема на данните. Ще Ви уведомим за очаквания срок, когато потвърдим Вашата заявка.</p>

            <h2>Упражняване на Други Права</h2>
            <p>Освен правото за изтриване на данни, Вие разполагате и с други права, свързани с Вашите лични данни, включително правото на достъп, коригиране или ограничаване на обработката на Вашите данни. За повече информация относно тези права или за тяхното упражняване, моля, обърнете се към нашата Политика за Поверителност или се свържете с нашия служител по защита на данните.</p>

            <h2>Промени по този документ - Политика за поверителност</h2>
            <p>
                Ние може да обновяваме Политиката за поверителност от време на време.
                Ние ще ви известяваме за всички промени като споделяме новата Политика за поверителност на тази страница.
            </p>
            <p>
                Ще ви съобщим посредством е-мейл и/или посредством видима бележка в Услугата, преди промяната да влезе в действие и ще обновим датата за влизане в сила намираще се най-отгоре в текущият документ.
            </p>
            <p>
                Препоръчваме ви да преглеждате тази Политика за поверителност периодично за промени.
                Промените в Политиката за поверителност влизат в действие от момента в който бъдат публикувани на тази страница.
            </p>

            <h2>Контакти</h2>
            <p>Ако имате въпроси относно Политиката за Поверителност, моля свържете се на:</p>
            <ul>
                <li>Чрез е-мейл: {contactEmail}</li>
                <li>Като посетите тази страница от уеб сайтът ни: <Link to={appDomain + 'contact'}>{appDomain + 'contact'}</Link></li>
            </ul>
        </>
    )
}
