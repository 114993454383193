import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faFacebookSquare, faLinkedinIn, faSquarePinterest, faSquareTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

export default function BottomSection({ tags }: { tags: string[]}) {
    return (
        <div className="post-single-bottom">
            <div className="tags">
                <p>Tags:</p>
                <ul className="list-inline">
                    {
                        tags.map(tag =>
                            <li key={tag}>
                                <Link to={`/posts/search?tag=${tag.toLowerCase()}`}>{tag}</Link>
                            </li>
                        )
                    }
                </ul>
            </div>
            <div className="social-media">
                <p>Share on :</p>
                <ul className="list-inline">
                    <li>
                        <a className="fa-facebook" href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} rel="noopener noreferrer" target="_blank">
                            <FontAwesomeIcon icon={faFacebookSquare as IconProp} />
                        </a>
                    </li>
                    {/* <li>
                        <a className="fab fa-instagram" href={`http://twitter.com/intent/tweet/?url=${window.location.href}`} rel="noopener noreferrer" target="_blank">
                            <FontAwesomeIcon icon={faSquareInstagram as IconProp} />
                        </a>
                    </li> */}
                    <li>
                        <a className="fab fa-twitter" href={`http://twitter.com/intent/tweet/?url=${window.location.href}`} rel="noopener noreferrer" target="_blank">
                            <FontAwesomeIcon icon={faSquareTwitter as IconProp} />
                        </a>
                    </li>
                    {/* <li>
                        <a className="fab fa-youtube" href={`http://twitter.com/intent/tweet/?url=${window.location.href}`} rel="noopener noreferrer" target="_blank">
                            <FontAwesomeIcon icon={faSquareYoutube as IconProp} />
                        </a>
                    </li> */}
                    <li>
                        <a className="fab fa-pinterest" href={`http://pinterest.com/pin/create/button/?url=${window.location.href}`} rel="noopener noreferrer" target="_blank">
                            <FontAwesomeIcon icon={faSquarePinterest as IconProp} />
                        </a>
                    </li>
                    <li>
                        <a className="fab fa-linkedin" href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`} rel="noopener noreferrer" target="_blank">
                            <FontAwesomeIcon icon={faLinkedinIn as IconProp} />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}
