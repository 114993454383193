import React, { useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import AdHorizontal from '../Generic/Ads/AdHorizontal';
import AuthorInfo from '../Author/Shared/AuthorInfo';
import InstagramPanel from '../Instagram/InstagramPanel'
import Sidebar from '../Sidebar/Sidebar'
import BottomSection from './PostSinglePage/BottomSection';
import CommentFormUnregistered from './PostSinglePage/CommentFormUnregistered';
import { ListCommentsTemplate } from './PostSinglePage/ListComments';
import PreviousNextPostSection from './PostSinglePage/PreviousNextPostSection';
import StickyBox from "react-sticky-box";
import { useEffect } from 'react';
import { useCurrentUser, useLangStore, useLoading } from '../../state/stateManagement';
import parse from 'html-react-parser'
import { formatToBlogDate } from '../../utils/dateFormatter';
import AuthorImage from '../Author/Shared/AuthorImage';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import CommentFormRegistered from './PostSinglePage/CommentFormRegistered';
import { singlePostPageSelector, usePostDetailsStore } from '../../state/postDetailsStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import SingleComment from './PostSinglePage/SingleComment';
import { PostComment } from '../../models/Comment';
import { toast } from 'react-toastify';
import { useFetchWithToken } from '../../utils/useADAuth';
import { scopes } from '../../config/authConfig';
import { deleteComment } from '../../services/postCommentService';
import { commentForm, deleteCommentDialog, deleteCommentToastsTranslations, postSinglePage } from '../../translations/posts/postSinglePage';
import YesNoDialog from '../Modals/Dialogs/YesNoDialog';
import { useYesNoDialogStore } from '../../state/yesNoDialogStore';
import { toLocalisedPost } from '../../utils/mappers/postMappers';
import SEO, { getLocalisation } from '../Shared/SEO';
import { trimHtmlTags } from '../../utils/stringFormatter';
import { getPostsTranslations } from '../../services/blogPostService';

export default function PostSinglePage() {

    const { openDialog } = useYesNoDialogStore();
    const { postId } = useParams<string>();
    const { loadingFetch } = useLoading();
    const { selectedLanguage } = useLangStore();
    const { currentUser } = useCurrentUser();
    const { selectPostItem, localisedSelectedPost, removeCommentFromPost, nextAndPrevPost, fetchNextAndPrevPosts, addPostTranslation } = usePostDetailsStore(store => {
        const homePageItems = singlePostPageSelector(store);
        return {
            ...homePageItems,
            localisedSelectedPost: homePageItems.selectedPost && toLocalisedPost(homePageItems.selectedPost, selectedLanguage),
        };
    });

    const fetchWithToken = useFetchWithToken();
    const showCommentDeleteConfirmationModal = async (comment: PostComment) => {
        if (!comment.commentator) return;
        openDialog(
            {
                title: deleteCommentDialog.title.get(selectedLanguage)!,
                description: deleteCommentDialog.body.get(selectedLanguage)!,
                yesBtnText: deleteCommentDialog.yesBtnText.get(selectedLanguage)!,
                noBtnText: deleteCommentDialog.noBtnText.get(selectedLanguage)!
            },
            () => handleCommentDeleteConfirm(comment)
        )
    }

    const handleCommentDeleteConfirm = async (comment: PostComment) => {
        await toast.promise(async () => {
            await fetchWithToken([scopes.openId, scopes.comments.write],
                async (accessToken) => {
                    await deleteComment(accessToken, { id: comment.id, postId: postId!, commentOwnerId: comment.commentator!.id });
                    removeCommentFromPost(postId!, comment.id);
                }
            )
        }
            , deleteCommentToastsTranslations.toastMessages.get(selectedLanguage)!
        );
    }

    const commentSectionRef = useRef<HTMLDivElement>(null);
    const commentFormRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        postId && loadingFetch([selectPostItem(postId, selectedLanguage)]);
    }, [postId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchNextAndPrevPosts()
    }, [localisedSelectedPost, fetchNextAndPrevPosts])

    useEffect(() => {
        if (localisedSelectedPost && !localisedSelectedPost.hasTranslation) {
            loadingFetch([getPostsTranslations([localisedSelectedPost.id], selectedLanguage).then(t => { addPostTranslation(t[0], selectedLanguage); })])
        }
    }, [selectedLanguage, addPostTranslation, loadingFetch, getPostsTranslations]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {localisedSelectedPost && <SEO title={localisedSelectedPost.title} description={trimHtmlTags(localisedSelectedPost.contentPreview)} localisation={getLocalisation(selectedLanguage)} ogImage={localisedSelectedPost.images.find(() => true)?.url} />}
            <section className="post-single post-single-layout-2">
                <div className="category-wide-image">
                    <img src={localisedSelectedPost && localisedSelectedPost.category.imgWideUrl} alt="" />
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <StickyBox offsetTop={100}>
                                {localisedSelectedPost &&
                                    <>
                                        {currentUser?.identityId === localisedSelectedPost.author.id
                                            && <Link className='edit-icon' to='edit'>
                                                <FontAwesomeIcon icon={faPenToSquare} />
                                            </Link>}
                                        <div className={`post-single-title ${currentUser?.identityId === localisedSelectedPost.author.id ? "mr-5" : ""}`}>
                                            <h3>{localisedSelectedPost.title} {!localisedSelectedPost.isApproved && <FontAwesomeIcon icon={faArrowsRotate} title={postSinglePage.pendingPost.get(selectedLanguage)} className={'pl-2 text-secondary'} style={{ fontSize: 24, cursor: 'help' }} />}</h3>
                                            <ul className="entry-meta">
                                                <li className="post-author-img"><AuthorImage imageUrl={localisedSelectedPost.author.imgUrl} isMale={localisedSelectedPost.author.isMale} /></li>
                                                <li className="post-author"> <Link to={`/authors/${localisedSelectedPost.author.id}`}>{localisedSelectedPost.author.name}</Link></li>
                                                <li className="entry-cat"> <Link to={`/categories/${localisedSelectedPost.category.id}`} className="category-style-1"> <span className="line"></span> {localisedSelectedPost.category.name}</Link></li>
                                                <li className="post-date"> <span className="line"></span> {formatToBlogDate(localisedSelectedPost.createdOnUtc, selectedLanguage)}</li>
                                            </ul>
                                        </div>

                                        {
                                            !!localisedSelectedPost.images?.length &&
                                            <div className='post-images'>
                                                <img src={localisedSelectedPost.images[0].url} alt='' />
                                            </div>
                                        }

                                        <div className="post-single-content">{parse(localisedSelectedPost.content!)}</div>


                                        <BottomSection tags={localisedSelectedPost.tags!} />

                                        <div className="post-single-author ">
                                            <AuthorInfo author={localisedSelectedPost.author} />
                                        </div>


                                        {
                                            nextAndPrevPost && <PreviousNextPostSection
                                                previousPost={nextAndPrevPost.prevPost && toLocalisedPost(nextAndPrevPost.prevPost, selectedLanguage)}
                                                nextPost={nextAndPrevPost.nextPost && toLocalisedPost(nextAndPrevPost.nextPost, selectedLanguage)} />
                                        }

                                        <AdHorizontal />


                                        <div ref={commentSectionRef} className="post-single-comments">

                                            {localisedSelectedPost.comments
                                                && <ListCommentsTemplate comments={
                                                    [...localisedSelectedPost.comments]
                                                        .sort((objA, objB) => new Date(objB.createdOnUtc).getTime() - new Date(objA.createdOnUtc).getTime())
                                                        .map(
                                                            comment => <SingleComment
                                                                key={comment.id}
                                                                comment={comment}
                                                                onDeleteClick={showCommentDeleteConfirmationModal}
                                                                onAnswerClick={() => { commentFormRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' }) }}
                                                            />
                                                        )
                                                } />
                                            }

                                            <div ref={commentFormRef} id="comment-form" className="comments-form">
                                                <h4 >{commentForm.heading.get(selectedLanguage)}</h4>

                                                <UnauthenticatedTemplate>
                                                    <CommentFormUnregistered onSubmit={() => {
                                                        commentSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
                                                    }} />
                                                </UnauthenticatedTemplate>
                                                <AuthenticatedTemplate>
                                                    <CommentFormRegistered onSubmit={() => {
                                                        commentSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
                                                    }} />
                                                </AuthenticatedTemplate>
                                            </div>
                                        </div>
                                    </>
                                }
                            </StickyBox>
                        </div>
                        <div className="col-lg-4">
                            <StickyBox offsetTop={100}>
                                <Sidebar />
                            </StickyBox>
                        </div>
                    </div>
                </div>
            </section>

            <InstagramPanel />

            <YesNoDialog />
        </>
    )
}
