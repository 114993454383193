import { appName } from "../../config/config";

export const footerTranslations = {
    menu: new Map([["en", "Menu"], ["bg", "Меню"]]),
    home: new Map([["en", "Homepage"], ["bg", "Начало"]]),
    aboutUs: new Map([["en", "About Us"], ["bg", "За нас"]]),
    contactUs: new Map([["en", "Contact us"], ["bg", "Контакт"]]),
    privacy: new Map([["en", "Privacy"], ["bg", "Поверителност"]]),

    subToOurNewsletterTitle: new Map([["en", "Subscribe To Our Newsletter"], ["bg", "Абонирайте се за известия"]]),
    subToOurNewsletterSubTitle: new Map([["en", "Sign up for free and be the first to get notified about new posts."], ["bg", "Абонирайте се безплатно и бъдете първия прочел най-новата статия"]]),
    emailSubPlaceholder: new Map([["en", "Your Email Address"], ["bg", "Твоят Е-мейл Адрес"]]),

    followUs: new Map([["en", "Follow us"], ["bg", "Последвайте ни"]]),

    tradeMark: new Map([["en", `© 2022, ${appName}, All Rights Reserved.`], ["bg", `© 2022, ${appName}, Всички права са запазени.`]]),
}