import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { scopes } from '../../config/authConfig';
import { CategoryCreateForm, CategoryCreateRequest } from '../../models/Category';
import { BlogImage } from '../../models/Image';
import { createCategory } from '../../services/categoryService';
import { useCategoryImagesUploader } from '../../services/fileUploaderService';
import { useLangStore, useLoading } from '../../state/stateManagement';
import { categoryEditTranslations } from '../../translations/categories/categoryEdit';
import { genericTranslations } from '../../translations/generic/inputTooltip';
import { isNotOnlyWhiteSpaces } from '../../utils/predicates';
import { useFetchWithTokenWithResult } from '../../utils/useADAuth';
import ImageEditable from '../Shared/ImageEditable';

export default function CategoryCreatePage() {

    const setLoading = useLoading(store => store.setLoading);
    const selectedLanguage = useLangStore(store => store.selectedLanguage);
    const navigate = useNavigate();
    const { uploadCategoryImage } = useCategoryImagesUploader()
    const fetchWithToken = useFetchWithTokenWithResult();

    const { register, getValues, setValue, handleSubmit, formState: { errors, isValid, dirtyFields } } = useForm<CategoryCreateForm>({ mode: "onChange" });

    useEffect(() => {
        register('imgData', { required: true })
        register('imgWideData', { required: true })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="container-fluid category-edit">
            <div className="category-wide-image row">
                <ImageEditable
                    imageData={getValues('imgWideData') ? getValues('imgWideData') as BlogImage : undefined}
                    onSave={(img) => { setValue("imgWideData", img, { shouldDirty: true, shouldValidate: true }) }}
                />
            </div>

            <div className='row'>
                <div className="category-list-image">
                    <ImageEditable
                        imageData={getValues('imgData') ? getValues('imgData') : undefined}
                        onSave={(img) => { setValue("imgData", img, { shouldDirty: true, shouldValidate: true }) }}
                    />
                </div>

                <form
                    onSubmit={handleSubmit(async (data) => {
                        setLoading(true);

                        let request: CategoryCreateRequest = {
                            nameEn: data.nameEn!,
                            nameBg: data.nameBg!,
                            descriptionEn: data.descriptionEn!,
                            descriptionBg: data.descriptionBg!,
                            imgUrl: await uploadCategoryImage(data.imgData!.urlOrBase64, data.nameBg!, `${data.imgData!.urlOrBase64.substring(0, 10)}-list.webp`),
                            imgWideUrl: await uploadCategoryImage(data.imgWideData!.urlOrBase64, data.nameBg!, `${data.imgWideData!.urlOrBase64.substring(0, 10)}-wide.webp`)
                        }

                        const categoryId = await fetchWithToken([scopes.openId, scopes.posts.write],
                            (accessToken) => createCategory(accessToken, request));

                        navigate(`/categories/${categoryId}`)
                    })}
                    className="form category-edit-form"
                >
                    <div className='row'>
                        <div className='col-md-12 col-lg-6'>
                            <div className="form-group">
                                <input
                                    title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                    {...register('nameEn', { required: true, maxLength: 60, minLength: 4, validate: isNotOnlyWhiteSpaces })}
                                    type="text"
                                    className={`form-control ${!dirtyFields.nameEn ? "" : errors.nameEn ? "border-danger" : "border-success"}`}
                                    placeholder={categoryEditTranslations.name.get("en")}
                                />
                            </div>
                            <div className="form-group">
                                <textarea
                                    title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                    {...register('descriptionEn', { required: true, minLength: 4, validate: isNotOnlyWhiteSpaces })}
                                    className={`form-control ${!dirtyFields.descriptionEn ? "" : errors.descriptionEn ? "border-danger" : "border-success"}`}
                                    placeholder={categoryEditTranslations.description.get("en")}
                                />
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-6'>
                            <div className="form-group">
                                <input
                                    title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                    {...register('nameBg', { required: true, maxLength: 60, minLength: 4, validate: isNotOnlyWhiteSpaces })}
                                    type="text"
                                    className={`form-control ${!dirtyFields.nameBg ? "" : errors.nameBg ? "border-danger" : "border-success"}`}
                                    placeholder={categoryEditTranslations.name.get("bg")}
                                />
                            </div>
                            <div className="form-group">
                                <textarea
                                    title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                    {...register('descriptionBg', { required: true, minLength: 4, validate: isNotOnlyWhiteSpaces })}
                                    className={`form-control ${!dirtyFields.descriptionBg ? "" : errors.descriptionBg ? "border-danger" : "border-success"}`}
                                    placeholder={categoryEditTranslations.description.get("bg")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <button type="submit" name="submit" className={`btn-custom mr-2 ${!isValid ? "disabled" : ""}`} disabled={!isValid}>
                            {categoryEditTranslations.sendBtnText.get(selectedLanguage)}
                        </button>

                        <button type="submit" name="submit" className={`btn-custom`} onClick={e => { e.preventDefault(); navigate(-1); }}>
                            {categoryEditTranslations.cancelBtnText.get(selectedLanguage)}
                        </button>
                    </div>
                </form>
            </div>
        </div>

    )
}
