import React from 'react'
import Footer from './Footer/Footer'
import Header from './Header/Header'
import Loader from '../Shared/Loader'
import SearchModal from '../Modals/SearchModal/SearchModal';
import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigateSearch } from '../../utils/useNavigateSearch'
import { useDarkModeStore, useLoading } from '../../state/stateManagement';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Link } from 'react-router-dom';

const Layout = ({ children }: any) => {

    const { isDarkModeOn, setDarkMode } = useDarkModeStore();
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const { loading } = useLoading();
    const searchNavigate = useNavigateSearch();


    if (isDarkModeOn)
        document.body.classList.add('dark');
    else
        document.body.classList.remove('dark')


    return (
        <>
            <Header
                isDarkThemeOn={isDarkModeOn}
                handleThemeChange={setDarkMode}
                openModalClicked={() => setIsSearchModalOpen(true)}
            />

            <main>{children}</main>

            <div className="back">
                <Link to='#' className="back-top">
                    <i className="las la-long-arrow-alt-up"></i>
                </Link>
            </div>

            <div className={`search ${isSearchModalOpen ? 'search-open' : ''}`}>
                <SearchModal
                    searchClicked={(data: FieldValues) => {
                        setIsSearchModalOpen(false);
                        searchNavigate('/posts/search', { keyword: data.search });
                    }}
                    closeClicked={() => setIsSearchModalOpen(false)}
                />
            </div>

            {loading && <Loader />}

            <Footer />
            <ToastContainer
                position='bottom-right'
                rtl={false}
                hideProgressBar={true}
                closeOnClick
                pauseOnHover
                draggable
                transition={Zoom}
                autoClose={5000}
            />
        </>
    )
}

export default Layout;