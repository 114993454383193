import React from 'react'
import InstagramPanel from '../Instagram/InstagramPanel'
import Pagination from '../Pagination/Pagination';
import Sidebar from '../Sidebar/Sidebar';
import AuthorPostListItem from './AuthorSinglePage/AuthorPostListItem'
import AuthorDetailsSection from './AuthorSinglePage/AuthorDetailsSection';
import StickyBox from 'react-sticky-box';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useLangStore, useLoading } from '../../state/stateManagement';
import { postsCountSearchParamSelector, postsSearchParamSelector, usePostDetailsStore } from '../../state/postDetailsStore';
import { useAuthorsStore } from '../../state/authorsStore';
import { LocalisedPost } from '../../models/PostDetails';
import { PostOrdering, PostSearchParams, toUrlSearchParams } from '../../services/blogPostService';
import { toLocalisedAuthor } from '../../utils/mappers/authorMappers';
import { toLocalisedPost } from '../../utils/mappers/postMappers';
import SEO, { getLocalisation } from '../Shared/SEO';
import { trimHtmlTags } from '../../utils/stringFormatter';

function AuthorSinglePage() {
    const { setLoading } = useLoading();
    const selectedLanguage = useLangStore(store => store.selectedLanguage);

    const { selectAuthor, localisedSelectedAuthor } = useAuthorsStore(store => ({
        selectAuthor: store.selectAuthor,
        localisedSelectedAuthor: store.authors.find(a => a.id === store.selectedAuthorId) && toLocalisedAuthor(store.authors.find(a => a.id === store.selectedAuthorId)!, selectedLanguage)
    }));

    // Get query string
    const [params] = useSearchParams();

    // Local State
    const { authorId } = useParams<string>();
    const currentPage = Number(params.get('page') ?? '1');
    const pageSize = 8;
    const searchParams: PostSearchParams = { page: currentPage, pageSize: pageSize, orderBy: PostOrdering.CreatedOnDesc, authorId: authorId };

    const { fetchFilteredPosts, fetchPostsCount, authorPosts, authorPostsCount } = usePostDetailsStore(store => ({
        fetchFilteredPosts: store.fetchFilteredPosts,
        fetchPostsCount: store.fetchPostsCount,
        authorPosts: postsSearchParamSelector(store, searchParams)?.map(p => toLocalisedPost(p, selectedLanguage)),
        authorPostsCount: postsCountSearchParamSelector(store, searchParams)
    }));

    useEffect(() => {
        setLoading(true);
        Promise.all([
            selectAuthor(authorId!),
            fetchFilteredPosts(searchParams, selectedLanguage),
            fetchPostsCount(searchParams)
        ])
            .then(() => setLoading(false));
    }, [authorId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {localisedSelectedAuthor && <SEO title={localisedSelectedAuthor.name} description={trimHtmlTags(localisedSelectedAuthor.bio ?? "")} localisation={getLocalisation(selectedLanguage)} ogImage={localisedSelectedAuthor.image?.urlOrBase64} />}
            {localisedSelectedAuthor && <AuthorDetailsSection author={localisedSelectedAuthor} />}

            <section id='blog-author' className="blog-author">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            {authorPosts &&
                                <StickyBox offsetTop={110}>
                                    {
                                        authorPosts.map((postItem: LocalisedPost) => <AuthorPostListItem key={postItem.id} postItem={postItem} />)
                                    }
                                    <Pagination
                                        currentPage={currentPage}
                                        totalCount={authorPostsCount ?? authorPosts.length}
                                        pageSize={pageSize}
                                        onPageChange={async (page: number) => {
                                            if (page * pageSize < (authorPostsCount ?? authorPosts.length) + pageSize) {
                                                setLoading(true);
                                                await fetchFilteredPosts({ ...searchParams, ...{ page: page } }, selectedLanguage);
                                                setLoading(false);
                                            }
                                        }}
                                        queryParams={toUrlSearchParams(searchParams)}
                                        className="list-inline"
                                    />
                                </StickyBox>}
                        </div>
                        <div className="col-lg-4">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>

            <InstagramPanel />
        </>
    )
}

export default AuthorSinglePage;
