import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { subscribeWithEmail } from '../../../services/emailSubscriptionService';
import { useLangStore } from '../../../state/stateManagement'
import { genericTranslations } from '../../../translations/generic/inputTooltip';
import { footerTranslations } from '../../../translations/shared/footer'
import { sidebarSubscribeFormTranslations } from '../../../translations/shared/sidebar';
import { isValidEmail } from '../../../utils/field-validations';

export default function Newsletter() {

  const selectedLanguage = useLangStore(store => store.selectedLanguage);
  const { register, handleSubmit, formState: { errors, dirtyFields, isValid, isDirty }, reset } = useForm<SubscribeModel>({ mode: 'onChange' });


  return (
    <div className="newsletter">
      <div className="newsletter-info">
        <h3>{footerTranslations.subToOurNewsletterTitle.get(selectedLanguage)}</h3>
        <p>{footerTranslations.subToOurNewsletterSubTitle.get(selectedLanguage)}</p>
      </div>
      <form
        className="newsletter-form"
        onSubmit={
          handleSubmit(async data => {
            if (isValid) {
              await toast.promise(
                subscribeWithEmail(data.email).then(() => { reset() }),
                sidebarSubscribeFormTranslations.toastMessages.get(selectedLanguage)!
              )
            }
          })
        }
      >
        <div className="form-flex">
          <div className="form-group">
            <input
              type="email"
              className={`form-control ${!dirtyFields.email ? "" : errors.email ? "border-danger" : "border-success"}`}
              placeholder={footerTranslations.emailSubPlaceholder.get(selectedLanguage)}
              {...register('email', { required: true, validate: isValidEmail })}
              title={genericTranslations.emailRequiredTooltip.get(selectedLanguage)}
              required={true}
            />
          </div>
          <button
            className={`submit-btn ${!isDirty || !isValid ? "disabled" : ""}`}
            disabled={!isDirty || !isValid}
            type="submit"
          >
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>
      </form>
    </div>
  )
}
