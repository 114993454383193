import React from 'react'
import SocialMediaList from './SocialMediaList'
import AuthorImage from './AuthorImage'
import parse from 'html-react-parser'
import { LocalisedAuthorBrief } from '../../../models/Author'

export default function AuthorInfo({ author }: { author: LocalisedAuthorBrief }) {
    return (
        <div className="authors-info row">
            <div className="image col-lg-3 col-md-5 col-sm-12">
                <span className="image">
                    <AuthorImage imageUrl={author.imgUrl} isMale={author.isMale} />
                </span>
            </div>
            <div className="content col-lg-9 col-md-7 col-sm-12">
                <h4 >{author.name}</h4>
                {author.bio && parse(author.bio)}
                <SocialMediaList socialMediaAccounts={author.socialMediaAccounts!} />
            </div>
        </div>
    )
}
