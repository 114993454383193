import React from 'react'
import PostListVerticalNoImage from './PostListVerticalNoImage'
import StickyBox from "react-sticky-box";
import { useLangStore } from '../../../state/stateManagement';
import { mostCommentedPostListSection } from '../../../translations/home/homePageTranslations';
import { LocalisedPost } from '../../../models/PostDetails';

export default function MostCommentedPostListSection({ posts, sidebar }: { posts: LocalisedPost[], sidebar: JSX.Element }) {
    const { selectedLanguage } = useLangStore();
    
    return (
        <section className="section-feature-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <h3>{mostCommentedPostListSection.heading.get(selectedLanguage)}</h3>
                            <p>{mostCommentedPostListSection.subheading.get(selectedLanguage)}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <StickyBox offsetTop={80}>
                            <PostListVerticalNoImage posts={posts} />
                        </StickyBox>
                    </div>
                    <div className="col-lg-4">
                        {sidebar}
                    </div>
                </div>
            </div>
        </section>
    )
}
