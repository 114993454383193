import { PostMultiLanguage } from "../models/PostDetails";

export function getTranslatedWord(translations: Translation[], language: string) {
    return translations.some(t => t.language === 'all')
        ? translations[0].word
        : translations.find(t => t.language === language && t.word !== "")?.word
}

export function getLanguagesForTranslations(translationsMatrix: Translation[][]) {

    let resultArray: Translation[] = [];

    for (const translations of translationsMatrix) {
        for (const translation of translations) {
            resultArray.push(translation);
        }
    }

    return [...new Set(resultArray.map(t => t.language))];
}

export function hasTranslationIn(language: string, post: PostMultiLanguage) {
    return getTranslatedWord(post.titleTranslations, language) !== undefined
        || getTranslatedWord(post.contentPreviewTranslations, language) !== undefined
        || getTranslatedWord(post.contentTranslations, language) !== undefined
}