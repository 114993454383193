import React from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useLangStore } from '../../../state/stateManagement'
import { signupForm } from '../../../translations/signup/signup'
import { isNotOnlyWhiteSpaces } from '../../../utils/predicates'
import InstagramPanel from '../../Instagram/InstagramPanel'

export default function SignUpPage() {

    const { selectedLanguage } = useLangStore();
    const { register, handleSubmit } = useForm<SignupForm>();

    return (
        <>
            <section className="login">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-8 m-auto">
                            <div className="login-content">
                                <h4>{signupForm.signUp.get(selectedLanguage)}</h4>
                                <form className="sign-form widget-form contact_form " method="post" onSubmit={handleSubmit(data => console.log(data))}>
                                    <div className="form-group">
                                        <input {...register('username', { required: true, maxLength: 40, validate: isNotOnlyWhiteSpaces })} type="text" className="form-control" placeholder={signupForm.usernamePlaceholder.get(selectedLanguage)} />
                                    </div>

                                    <div className="form-group">
                                        <input {...register('email', { required: true, validate: isNotOnlyWhiteSpaces })} type="email" className="form-control" placeholder={signupForm.emailPlaceholder.get(selectedLanguage)} />
                                    </div>

                                    <div className="form-group">
                                        <input {...register('password', { required: true, maxLength: 40, validate: isNotOnlyWhiteSpaces })} type="password" className="form-control" placeholder={signupForm.passwordPlaceholder.get(selectedLanguage)} />
                                    </div>
                                    <div className="sign-controls form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input {...register('acceptedTerms', { required: true })} type="checkbox" className="custom-control-input" id="rememberMe" />
                                            <label className="custom-control-label" htmlFor="rememberMe">
                                                {signupForm.termsAndConditionsText.get(selectedLanguage)}
                                                <Link to="#" className="btn-link">{signupForm.termsAndConditionsLink.get(selectedLanguage)}</Link>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn-custom">{signupForm.signUpBtn.get(selectedLanguage)}</button>
                                    </div>
                                    <p className="form-group text-center">{signupForm.haveAccount.get(selectedLanguage)} <Link to={'/login'} className="btn-link">{signupForm.loginLink.get(selectedLanguage)}</Link> </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <InstagramPanel />

        </>
    )
}
