import { BrowserAuthError, InteractionRequiredAuthError, SilentRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import { useCurrentUser, useLangStore } from "../state/stateManagement";
import { authenticationTranslations } from "../translations/auth/authentication";

export const useFetchWithToken = (): (scopes: string[], apiCall: (accessToken: string) => Promise<any>) => Promise<void> => {
    var fetchWithToken = useFetchWithTokenWithResult();
    return fetchWithToken;
};

export const useFetchWithTokenWithResult = () => {
    const { instance } = useMsal();
    const selectedLanguage = useLangStore(store => store.selectedLanguage);
    const { currentUser, reset } = useCurrentUser(store => ({ currentUser: store.currentUser, reset: store.reset }));

    return async (scopes: string[], apiCall: (accessToken: string) => Promise<any>) => {

        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {

            const accessTokenRequest = {
                scopes: scopes,
                account: accounts[0],
                loginHint: currentUser?.email
            } as SilentRequest;

            try {
                const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);

                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;

                // Call your API with token
                return await apiCall(accessToken);;

            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    try {
                        const accessTokenResponse = await instance.acquireTokenPopup(accessTokenRequest)

                        // Acquire token interactive success
                        let accessToken = accessTokenResponse.accessToken;

                        // Call your API with token
                        return await apiCall(accessToken);

                    } catch (error) {
                        if (error instanceof BrowserAuthError) {
                            if (error.errorCode === 'interaction_in_progress' || error.errorCode === 'user_cancelled') {
                                instance.logoutPopup({ extraQueryParameters: { ui_locales: selectedLanguage ?? 'bg' } }).then(reset)
                                return;
                            }
                        }

                        toast.error(authenticationTranslations.failedAuth.get(selectedLanguage));
                        return null;
                    };
                }

                toast.error(authenticationTranslations.failedAuth.get(selectedLanguage));
                return null;
            };
        }

        await instance.loginRedirect({
            scopes: scopes,
            account: accounts[0],
            loginHint: currentUser?.email
        });
    }
};