import { create } from 'zustand'
import { Author, PatchAuthorStoreObject } from '../models/Author';
import { getAuthor, getAuthorByEmail, getAuthors } from '../services/authorService';

// Authors Store
interface AuthorStore {
    authors: Author[],
    selectedAuthorId?: string,
    isInit: boolean,

    addAuthor: (authorToAdd: Author) => void,
    updateAuthor: (authorToUpdate: Author) => void,
    patchAuthor: (id: string, patchObject: PatchAuthorStoreObject) => void,
    fetchAuthors: () => Promise<void>,
    selectAuthor: (authorId: string) => Promise<Author>
    selectAuthorByEmail: (email: string) => Promise<Author>
}

export const useAuthorsStore = create<AuthorStore>((set, get) => ({
    authors: [],
    isInit: false,

    addAuthor: (authorToAdd) => {
        if (!get().authors.find(a => a.id === authorToAdd.id)) {
            set((state) => ({ authors: [...state.authors, authorToAdd] }))
        }
    },

    updateAuthor: (authorToUpdate) => {
        if (get().authors.find(a => a.id === authorToUpdate.id)) {
            set((state) => ({ authors: [...state.authors.filter(a => a.id !== authorToUpdate.id), authorToUpdate] }))
        }
    },

    patchAuthor: (id, updatedProperties) => {
        const authorToPatch = get().authors.find(a => a.id === id);
        if (authorToPatch) {
            set((state) => ({ authors: [...state.authors.filter(a => a.id !== id), { ...authorToPatch, ...updatedProperties }] }))
        }
    },

    fetchAuthors: async () => {
        const areAuthorsInit = get().isInit;

        if (!areAuthorsInit) {
            await fetchAuthors();
            set(() => ({ isInit: true }));
        }
    },

    selectAuthor: async (authorId) => {
        let author = get().authors.find(author => author.id === authorId);

        if (!author) {
            author = await getAuthor(authorId);
            get().addAuthor(author!);
        }

        set(() => ({ selectedAuthorId: author!.id }));

        return author as Author;
    },
    selectAuthorByEmail: async (email) => {
        let author = get().authors.find(author => author.email === email);

        if (!author) {
            author = await getAuthorByEmail(email);
            get().addAuthor(author!);
        }

        set(() => ({ selectedAuthorId: author!.id }));

        return author as Author;
    }
}));

export const fetchAuthors = async () => {
    const getAuthorsPromise = getAuthors();

    const [authors] = await Promise.all([getAuthorsPromise])

    useAuthorsStore.setState(() => ({ authors: authors }));
}