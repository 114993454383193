import React from 'react'
import { Link } from 'react-router-dom'
import { LocalisedCategory } from '../../../models/Category';
import { useLangStore } from '../../../state/stateManagement'
import { sidebarCategoriesSection } from '../../../translations/shared/sidebar';

export default function CategoriesSection({ categories }: { categories: LocalisedCategory[] }) {

    const { selectedLanguage } = useLangStore();

    return (
        <div className="widget ">
            <div className="widget-title">
                <h5>{sidebarCategoriesSection.categoriesHeading.get(selectedLanguage)}</h5>
            </div>
            <div className="widget-categories">
                {
                    categories.map(c =>
                        <Link key={c.id} className="category-item" to={`/categories/${c.id}`}>
                            <div className="image">
                                <img src={c.imgUrl} alt="" />
                            </div>
                            <p>{c.name}</p>
                        </Link>
                    )
                }
            </div>
        </div>
    )
}
