import React, { useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import EditorToolbarSm, { formatsSm, useModulesSm } from "./SmallToolbar";
import "react-quill/dist/quill.snow.css";

export const Editor = (
    {
        initValue = "",
        onChange,
        placeholder,
        maxContentLenght
    }: { initValue?: string, onChange: (val: string) => void, placeholder?: string, maxContentLenght?: number }
) => {
    const toolbarEditorToModulesConnectionId = Math.random().toString(36).replace(/[^a-z]+/g, '').slice(-5);

    var editorRef = useRef<ReactQuill>(null);
    var modules = useModulesSm(toolbarEditorToModulesConnectionId)

    // Placeholder change hack
    useEffect(() => {
        editorRef.current && (editorRef.current.getEditor().root.dataset.placeholder = placeholder || "");
    }, [editorRef, placeholder]);


    return (
        <div className="text-editor">
            <EditorToolbarSm id={toolbarEditorToModulesConnectionId} />
            <ReactQuill
                ref={editorRef}
                className="editor-sm"
                theme="snow"
                value={initValue}
                onKeyDown={(event) => {
                    if (maxContentLenght && editorRef.current && editorRef.current.unprivilegedEditor) {
                        const unprivilegedEditor = editorRef.current.unprivilegedEditor;
                        if (unprivilegedEditor.getLength() > maxContentLenght
                            && event.key !== 'Backspace'
                            && event.key !== 'Home'
                            && event.key !== 'End'
                            && event.key !== 'Delete'
                            && event.key !== 'ArrowLeft'
                            && event.key !== 'ArrowRight')
                            event.preventDefault();
                    }
                }}

                onChange={(content, delta, source, editor) => {
                    onChange(content);
                }}
                placeholder={placeholder}
                modules={modules}
                formats={formatsSm}
            />
        </div>
    );
};

export default Editor;
