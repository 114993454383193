import React from 'react'
import { LocalisedPost } from '../../../models/PostDetails';
import { useLangStore } from '../../../state/stateManagement';
import { recentPostListSection } from '../../../translations/home/homePageTranslations'
import PostItemHorizontal from '../Shared/PostItemHorizontal'

export default function RecentPostListSection({ posts }: { posts: LocalisedPost[] }) {

    const { selectedLanguage } = useLangStore();

    return (
        <section className="section-feature-4 ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 ">
                        <div className="section-title">
                            <h3>{recentPostListSection.heading.get(selectedLanguage)}</h3>
                            <p>{recentPostListSection.subheading.get(selectedLanguage)}</p>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    {
                        posts.slice(0, 3).map((post, i) =>
                            <div key={post.id} className={"col-lg-4  col-md-6"}>
                                <PostItemHorizontal post={post} />
                            </div>)
                    }
                </div>
            </div>
        </section>
    )
}
