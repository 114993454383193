import { useEffect } from "react";
import { NavigationType, useLocation, useNavigationType } from "react-router-dom";

const useBackButton = () => {
  const navType = useNavigationType();
  return navType === NavigationType.Pop;
};

export const ScrollToTop = ({ children }: any) => {
  const { pathname, search } = useLocation();
  const isPop = useBackButton();
  const scrollToTop = () => window.scrollTo(0, 0);

  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    scrollToTop();
  }, [pathname, search, isPop]);

  useEffect(() => {
    window.addEventListener("beforeunload", scrollToTop);
    return () => {
      window.removeEventListener("beforeunload", scrollToTop);
    };
  }, []);

  return children;
}