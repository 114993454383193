import React from 'react'
import { Link } from 'react-router-dom'
import { LocalisedPost } from '../../../models/PostDetails';
import { useLangStore } from '../../../state/stateManagement';
import { formatToBlogDate } from '../../../utils/dateFormatter';

export default function PostItemHorizontal({ post }: { post: LocalisedPost }) {

    const { selectedLanguage } = useLangStore();

    return (
        <div className="post-overly post-overly-2">
            <div className="post-ovetly-image">
                <img src={post.images?.length ? post.images[0].url : post.category.imgUrl} alt="" />
            </div>
            <div className="post-overly-content">
                <div className="entry-cat">
                    <Link to={`/categories/${post.category.id}`} className="category-style-2">{post.category.name}</Link>
                </div>
                <h5 className="entry-title">
                    <a href={`/posts/${post.id}`}>{post.title}</a>
                </h5>
                <ul className="entry-meta">
                    <li className="post-author"> <Link to={`/authors/${post.author.id}`}>{post.author.name}</Link></li>
                    <li className="post-date"> <span className="line"></span> {formatToBlogDate(post.createdOnUtc, selectedLanguage)}</li>
                </ul>
            </div>
        </div>
    )
}
