import React, { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import PostMultiple from '../Post/PostMultiplePage/PostMultiple'
import StickyBox from "react-sticky-box";
import Sidebar from '../Sidebar/Sidebar';
import { useLangStore, useLoading } from '../../state/stateManagement';
import Pagination from '../Pagination/Pagination';
import { postsCountSearchParamSelector, postsSearchParamSelector, usePostDetailsStore } from '../../state/postDetailsStore';
import { useCategoriesStore } from '../../state/categoriesStore';
import CategoryHeader from './CategorySinglePage/CategoryHeader';
import { PostOrdering, PostSearchParams, toUrlSearchParams } from '../../services/blogPostService';
import { toLocalisedCategory } from '../../utils/mappers/categoryMappers';
import { toLocalisedPost } from '../../utils/mappers/postMappers';
import SEO, { getLocalisation } from '../Shared/SEO';

export default function CategorySinglePage() {

    const { setLoading } = useLoading();
    const selectedLanguage = useLangStore(store => store.selectedLanguage);

    // Get query string
    const [params] = useSearchParams();

    // Local State
    const currentPage = Number(params.get('page') ?? '1');
    const pageSize = 8;
    const { categoryId } = useParams<string>();
    const searchParams: PostSearchParams = { page: currentPage, pageSize: pageSize, orderBy: PostOrdering.CreatedOnDesc, categoryId: categoryId };

    const { filteredPosts, fetchFilteredPosts, postCount, fetchPostsCount } = usePostDetailsStore(store => ({
        postCount: postsCountSearchParamSelector(store, searchParams),
        filteredPosts: postsSearchParamSelector(store, searchParams)?.map(p => toLocalisedPost(p, selectedLanguage)),
        fetchPostsCount: store.fetchPostsCount,
        fetchFilteredPosts: store.fetchFilteredPosts
    }));
    const { selectCategory, localisedSelectedCategory } = useCategoriesStore(store => ({
        selectCategory: store.selectCategory,
        localisedSelectedCategory: store.selectedCategory && toLocalisedCategory(store.selectedCategory, selectedLanguage)
    }));

    useEffect(() => {
        setLoading(true);
        Promise.all([selectCategory(categoryId!), fetchFilteredPosts(searchParams, selectedLanguage), fetchPostsCount(searchParams)])
            .then(() => { setLoading(false); });
    }, [categoryId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {localisedSelectedCategory && <SEO title={localisedSelectedCategory.name} description={localisedSelectedCategory.description} localisation={getLocalisation(selectedLanguage)} ogImage={localisedSelectedCategory.imgUrl} />}
            {localisedSelectedCategory && <CategoryHeader category={localisedSelectedCategory} />}

            {filteredPosts && <PostMultiple
                posts={filteredPosts}
                sidebar={<StickyBox offsetTop={100}> <Sidebar /></StickyBox>}
                pagingComponent={
                    <Pagination
                        currentPage={currentPage}
                        totalCount={postCount ?? filteredPosts.length}
                        pageSize={pageSize}
                        onPageChange={async page => {
                            if (page * pageSize < (postCount ?? filteredPosts.length) + pageSize) {
                                setLoading(true);
                                await fetchFilteredPosts({ ...searchParams, ...{ page: page } }, selectedLanguage);
                                setLoading(false);
                            }
                        }}
                        queryParams={toUrlSearchParams(searchParams)}
                        className="list-inline"
                    />}
            />}
        </>
    )
}
