import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { scopes } from '../../../config/authConfig';
import { CreateProductForm, CreateProductRequest } from '../../../models/Shop/Product';
import { useCurrentUserAzureFileUploader } from '../../../services/fileUploaderService';
import { createProduct } from '../../../services/shop/productService';
import { useCurrentUser, useLangStore, useLoading } from '../../../state/stateManagement';
import { productCreateHeadingTranslations } from '../../../translations/shop/product/productCreatePage';
import { isNotOnlyWhiteSpaces, isPositive } from '../../../utils/predicates';
import { useFetchWithTokenWithResult } from '../../../utils/useADAuth';
import MainEditor from '../../ContentEditorWrapper/MainEditor';
import SmallEditor from '../../ContentEditorWrapper/SmallEditor';
import { Dropdown } from '../../Shared/Dropdown';
import ImageGallery from '../../Shared/ImageGallery';

export default function ProductCreatePage() {

    const selectedLanguage = useLangStore(store => store.selectedLanguage)
    const setLoading = useLoading(store => store.setLoading)
    const userId = useCurrentUser(store => store.currentUser?.id)
    const { uploadProductImages } = useCurrentUserAzureFileUploader()

    //Redirect when product is created successfully
    const navigate = useNavigate();

    //fetch with access token
    const fetchWithToken = useFetchWithTokenWithResult();

    const { register, handleSubmit, setValue, getValues, setError, formState: { errors, dirtyFields, isDirty, isValid } } = useForm<CreateProductForm>({ mode: "onChange", reValidateMode: "onChange" });

    useEffect(() => {
        register('itemPrice.currency', { validate: (val: string) => ['BGN', 'EUR', 'USD'].some(v => v === val) })
    }, [register])

    return (
        <section className='post-create'>
            <div className='container'>
                <div className='row'>
                    <div className='col-xl-12'>
                        <div className='post-content'>
                            <h2>{productCreateHeadingTranslations.createProduct.get(selectedLanguage)}</h2>
                            <form onSubmit={
                                handleSubmit(async (data) => {
                                    setLoading(true);

                                    let request: CreateProductRequest = {
                                        name: [{ language: 'bg', word: data.nameBg }, { language: 'en', word: data.nameEn }],
                                        shortDescription: [{ language: 'bg', word: data.shortDescriptionBg }, { language: 'en', word: data.shortDescriptionEn }],
                                        description: [{ language: 'bg', word: data.descriptionBg }, { language: 'en', word: data.descriptionEn }],
                                        price: { amount: parseFloat(data.itemPrice.amount.toString()), currency: data.itemPrice.currency },
                                        ownerId: userId!
                                    }

                                    if (data.images.length) {
                                        request.images = await uploadProductImages(data.images.map(img => ({ imageData: img.urlOrBase64, name: img.name })))
                                    }

                                    const productId = await fetchWithToken([scopes.user_impersonation], (accessToken) => createProduct(accessToken, request))

                                    // Redirect to post
                                    navigate(`/products/${productId}`);
                                })}
                                className='create-post-form widget-form'
                            >
                                <div className='row'>
                                    <div className='col-xl-12 form-group image-gallery-container'>
                                        <ImageGallery onChange={(images) => {
                                            setValue('images', images);
                                        }} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12 form-group'>
                                        <input
                                            {...register('nameBg', { required: true, minLength: 5, validate: isNotOnlyWhiteSpaces })}
                                            onChange={(e => {
                                                setValue('nameBg', e.target.value, { shouldValidate: true, shouldDirty: true });
                                            })}
                                            placeholder={productCreateHeadingTranslations.productNamePlaceholder.get('bg')}
                                            className={`form-control ${!dirtyFields.nameBg ? "" : errors.nameBg ? "border-danger" : "border-success"}`}
                                        />
                                    </div>
                                    <div className='col-md-6 col-sm-12 form-group'>
                                        <input
                                            {...register('nameEn', { required: true, minLength: 5, validate: isNotOnlyWhiteSpaces })}
                                            onChange={(e => {
                                                setValue('nameEn', e.target.value, { shouldValidate: true, shouldDirty: true });
                                            })}
                                            placeholder={productCreateHeadingTranslations.productNamePlaceholder.get('en')}
                                            className={`form-control ${!dirtyFields.nameEn ? "" : errors.nameEn ? "border-danger" : "border-success"}`}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12 form-group'>
                                        <input
                                            {...register('itemPrice.amount', { required: true, validate: isPositive })}
                                            placeholder={productCreateHeadingTranslations.productPriceAmountPlaceholder.get(selectedLanguage)}
                                            className={`form-control ${!dirtyFields.itemPrice?.amount ? "" : errors.nameBg ? "border-danger" : "border-success"}`}
                                        />
                                    </div>
                                    <div className='col-md-6 col-sm-12 form-group'>
                                        <Dropdown
                                            value={getValues('itemPrice.currency')}
                                            label={"Currency"}
                                            placeholderElement={productCreateHeadingTranslations.productPriceCurrencyPlaceholder.get(selectedLanguage)}
                                            options={[{ id: 'EUR', name: 'EUR' }, { id: 'USD', name: 'USD' }, { id: 'BGN', name: 'BGN' }]}
                                            onChange={(e) => {
                                                setValue("itemPrice.currency", e.target.value as "USD" | "EUR" | "BGN", { shouldValidate: true, shouldDirty: true })
                                            }}
                                            className={`form-control ${!dirtyFields.itemPrice?.currency ? "" : errors.itemPrice?.currency ? "border-danger" : "border-success"}`}
                                        />
                                    </div>
                                </div>
                                {/* <div className='form-group row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <Dropdown
                                            value={getValues('categoryId')}
                                            label={"Category"}
                                            placeholderElement={productCreateHeadingTranslations.categorySelectDefault.get(selectedLanguage)}
                                            options={categories.map<LookupItem>(c => ({ id: c.id, name: c.name }))}
                                            onChange={(e) => {
                                                setValue("categoryId", e.target.value, { shouldValidate: true, shouldDirty: true })
                                                editDraft({ categoryId: e.target.value });
                                            }}
                                            className={`form-control ${!dirtyFields.categoryId ? "" : errors.categoryId ? "border-danger" : "border-success"}`}
                                        />
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <input
                                            {...register('timeToRead', { valueAsNumber: true, required: true, validate: (value => value > 0) })}
                                            onChange={e => { editDraft({ readTimeInMinutes: Number(e.target.value) }); }}
                                            type="number"
                                            placeholder={postCreatePageTranslations.timeToReadPlaceholder.get(selectedLanguage)}
                                            className={`form-control ${!dirtyFields.timeToRead ? "" : errors.timeToRead ? "border-danger" : "border-success"}`}
                                        />
                                    </div>
                                </div> */}
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12 form-group'>
                                        <SmallEditor
                                            initValue={getValues("shortDescriptionBg")}
                                            onChange={(content) => {
                                                setValue('shortDescriptionBg', content);
                                            }}
                                            placeholder={productCreateHeadingTranslations.productPreviewContentPlaceholder.get('bg')}
                                            maxContentLenght={200}
                                        />
                                    </div>
                                    <div className='col-md-6 col-sm-12 form-group'>
                                        <SmallEditor
                                            initValue={getValues("shortDescriptionEn")}
                                            onChange={(content) => {
                                                setValue('shortDescriptionEn', content);
                                            }}
                                            placeholder={productCreateHeadingTranslations.productPreviewContentPlaceholder.get('en')}
                                            maxContentLenght={200}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12 form-group'>
                                        <MainEditor
                                            initValue={getValues("descriptionBg")}
                                            onChange={(content) => {
                                                if (content === '<p><br></p>' || /^<p>\s*<[/]p>$/.test(content)) {
                                                    setValue('descriptionBg', content);
                                                    setError('descriptionBg', { type: 'required' })
                                                }
                                                else {
                                                    setValue('descriptionBg', content, { shouldValidate: true, shouldDirty: true });
                                                }
                                            }}
                                            placeholder={productCreateHeadingTranslations.productContentPlaceholder.get('bg')}
                                            className={!dirtyFields.descriptionBg ? "" : errors.descriptionBg ? "border border-danger" : "border border-success"}
                                        />
                                    </div>
                                    <div className='col-md-6 col-sm-12 form-group'>
                                        <MainEditor
                                            initValue={getValues("descriptionEn")}
                                            onChange={(content) => {
                                                if (content === '<p><br></p>' || /^<p>\s*<[/]p>$/.test(content)) {
                                                    setValue('descriptionEn', content);
                                                    setError('descriptionEn', { type: 'required' })
                                                }
                                                else {
                                                    setValue('descriptionEn', content, { shouldValidate: true, shouldDirty: true });
                                                }
                                            }}
                                            placeholder={productCreateHeadingTranslations.productContentPlaceholder.get('en')}
                                            className={!dirtyFields.descriptionEn ? "" : errors.descriptionEn ? "border border-danger" : "border border-success"}
                                        />
                                    </div>
                                </div>
                                <button
                                    type="submit" name="submit"
                                    className={`btn-custom ${!isDirty || !isValid ? "disabled" : ""}`}
                                    disabled={!isDirty || !isValid}
                                >
                                    {productCreateHeadingTranslations.sendBtnText.get(selectedLanguage)}
                                </button>
                                <button className={`btn-custom ml-1`} onClick={() => { navigate(-1); }}>
                                    {productCreateHeadingTranslations.cancelBtnText.get(selectedLanguage)}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
