import React, { useEffect } from 'react'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { scopes } from '../../config/authConfig';
import { useLangStore, useLoading } from '../../state/stateManagement'
import { useUsersStore } from '../../state/usersStore';
import { changeRoleTranslations, userTranslations } from '../../translations/users/users';
import { useFetchWithTokenWithResult } from '../../utils/useADAuth';
import CenterHeading from '../PageHeading/CenterHeading'
import Heading from '../PageHeading/Heading'
import parse from 'html-react-parser'
import AuthorImage from '../Author/Shared/AuthorImage';
import { Dropdown } from '../Shared/Dropdown';
import { useForm } from 'react-hook-form';
import { ChangeRoleForm } from '../../models/Author';
import { toast } from 'react-toastify';
import { changeUserRole } from '../../services/userService';

export default function UsersPage() {

    const selectedLanguage = useLangStore(store => store.selectedLanguage);
    const { setLoading } = useLoading();
    const fetchWithToken = useFetchWithTokenWithResult();
    const { users, fetchUsers } = useUsersStore();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<ChangeRoleForm>({ mode: "onChange" });

    useEffect(() => {
        register("role", { validate: (role) => { return ['Admin', 'Member', 'Author'].includes(role) } })

        setLoading(true);
        fetchWithToken([scopes.user_impersonation], fetchUsers).then(() => setLoading(false))
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='container'>
            <CenterHeading headingContent={
                <Heading heading={userTranslations.heading.get(selectedLanguage)!} segment={userTranslations.segment.get(selectedLanguage)!} />
            } />

            <div className='users-list'>
                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} >
                    {
                        [...users].map(user => (
                            <AccordionItem key={user.id}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <span className='user-name'>{user.name}</span>
                                        <span className='user-role float-right'>{user.role}</span>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className='author-preview row'>
                                        <div className='col-lg-2'>
                                            <AuthorImage imageUrl={user.image?.urlOrBase64} isMale={false} />
                                        </div>
                                        <div className='col-lg-8'>
                                            <h4>{user.name}</h4>
                                            <span className='mb-3 d-inline-block'>{user.email}</span>
                                            <div className='user-bio'> {parse(user.bio ?? "")} </div>
                                        </div>
                                        <div className='col-lg-2'>
                                            <form
                                                onSubmit={handleSubmit(form => {
                                                    if (errors.role) return;

                                                    toast.promise(fetchWithToken([scopes.user_impersonation],
                                                        (accessToken) => changeUserRole(accessToken, { userId: user.id, role: form.role })),
                                                        changeRoleTranslations.toastMessages.get(selectedLanguage)!);
                                                })}
                                            >
                                                <Dropdown
                                                    value={user.role}
                                                    label="Role"
                                                    onChange={(e) => { user.role = e.target.value; setValue("role", e.target.value, { shouldValidate: true, shouldDirty: true }) }}
                                                    options={[{ id: 'Admin', name: 'Admin' }, { id: 'Author', name: 'Author' }, { id: 'Member', name: 'Member' }]}
                                                    className={`form-control mb-2`}
                                                />
                                                <button type='submit' name="submit" className={`btn-custom form-control d-block`}>
                                                    {userTranslations.apply.get(selectedLanguage)}
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))
                    }
                </Accordion>
            </div>
        </div >
    )
}
