import React from 'react'
import { Link } from 'react-router-dom'
import { LocalisedCategory } from '../../../models/Category'

export default function CategoryListSection({ categories }: { categories: LocalisedCategory[] }) {
    return (
        <div className="categories">
            <div className="container">
                <div className="categories-area-2">
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="categories-items">
                                {
                                    categories.map(category =>
                                        <Link key={category.id} className="category-item" to={`/categories/${category.id}`}>
                                            <div className="image">
                                                <img src={category.imgUrl} alt="" />
                                            </div>
                                            <p>{category.name}  <span>{category.postsCount}</span> </p>
                                        </Link>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
