import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useLangStore } from '../../../state/stateManagement';
import { searchModalTranslations } from '../../../translations/shared/searchModal';
import { isNotOnlyWhiteSpaces } from '../../../utils/predicates';

export default function SearchModal(
    { searchClicked, closeClicked }: { searchClicked: SubmitHandler<FieldValues>, closeClicked: (val: any) => void }) {

    const { register, handleSubmit, reset } = useForm();
    const { selectedLanguage } = useLangStore();

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-md-10 m-auto">
                    <div className="search-width">
                        <button type="button" className="close" onClick={closeClicked}>
                            <FontAwesomeIcon className='far fa-times' icon={faTimes} />
                        </button>
                        <form className="search-form" onSubmit={handleSubmit(data => {
                            searchClicked(data);
                            reset();
                        })}>
                            <input  {...register('search', { required: true, maxLength: 40, validate: isNotOnlyWhiteSpaces })} type="search" required placeholder={searchModalTranslations.searchInputPlaceholder.get(selectedLanguage)} />
                            <button type="submit" className="search-btn">{searchModalTranslations.searchBtnText.get(selectedLanguage)}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
