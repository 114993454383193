export const signupForm = {
    signUp: new Map([["en", "Sign up"], ["bg", "Регистрация"]]),
    usernamePlaceholder: new Map([["en", "Username*"], ["bg", "Потребителско име*"]]),
    emailPlaceholder: new Map([["en", "Email Address*"], ["bg", "Е-мейл адрес*"]]),
    passwordPlaceholder: new Map([["en", "Password*"], ["bg", "Парола*"]]),
    termsAndConditionsText: new Map([["en", "Agree to our "], ["bg", "Съгласявам се с "]]),
    termsAndConditionsLink: new Map([["en", "terms & conditions"], ["bg", "правилата за ползване"]]),
    signUpBtn: new Map([["en", "Sign Up"], ["bg", "Регистриране"]]),
    haveAccount: new Map([["en", "Already have an account?"], ["bg", "Имате съществуващ акаунт?"]]),
    loginLink: new Map([["en", "Login"], ["bg", "Вход"]]),
}