import React from 'react'
import parse from 'html-react-parser'
import { ProductReview } from '../../../../models/Shop/Review'
import { getTimeSinceNow } from '../../../../utils/dateFormatter'
import { useLangStore } from '../../../../state/stateManagement'
import { Link } from 'react-router-dom'

export default function ReviewsList({ reviews }: { reviews: ProductReview[] }) {

    const selectedLanguage = useLangStore(store => store.selectedLanguage)

    return (
        <ul className="comments">
            {reviews &&
                reviews.map(review =>
                    <li key={review.id} className="comment-item">
                        <img src={review.createdBy.imgUrl} alt="" />
                        <div className="content">
                            <div className="meta">
                                <ul className="list-inline">
                                    <li><Link to="#">{review.createdBy.name}</Link> </li>
                                    <li className="slash"></li>
                                    <li>{getTimeSinceNow(review.createdOn, selectedLanguage)}</li>
                                </ul>
                            </div>
                            <p>{parse(review.content)}</p>
                            <div className="star">
                                <i className="las la-star"></i>
                                <i className="las la-star"></i>
                                <i className="las la-star"></i>
                                <i className="las la-star"></i>
                                <i className="las la-star"></i>
                            </div>
                        </div>
                    </li>
                )}
        </ul >
    )
}
