export const inqueriesTranslations = {
    heading: new Map([["en", "Contact forms"], ["bg", "Запитвания"]]),
    segment: new Map([["en", "Inqueries"], ["bg", "Запитвания"]]),
    from: new Map([["en", "from"], ["bg", "от"]])
}

export const inquerySingleTranslations = {
    deleteDialogTitle: new Map([["en", "Are you sure you want to delete the inquery?"], ["bg", "Наистина ли искате да изтриете контактната форма?"]]),
    deleteDialogBody: new Map([["en", `Clicking "OK" will remove the inquery permanently. You won't be able to restore this inquery anymore.`], ["bg", `С натискане на "Потвърди" контактната форма ще бъде изтрита перманентно. След това няма да имате възможност да я възстановите повече.`]]),
    yesBtnText: new Map([["en", "Delete"], ["bg", "Изтрий"]]),
    noBtnText: new Map([["en", "Cancel"], ["bg", "Отказ"]]),
}

export const becomeAuthorApproveTranslations = {
    makeAuthorDialogTitle: new Map([["en", "Are you sure you want to make '{0}' author?"], ["bg", "Наистина ли искате да направите '{0}' автор?"]]),
    makeAuthorDialogBody: new Map([["en", `Clicking "OK" will make '{0}' Author. He will be able to create posts.`], ["bg", `С натискане на "Потвърди" потребителят '{0}' ще стане автор. Това ще му позволи да пише статии.`]]),
    yesBtnText: new Map([["en", "Confirm"], ["bg", "Направи Автор"]]),
    noBtnText: new Map([["en", "Cancel"], ["bg", "Отказ"]]),
}