import { create } from 'zustand'
import { getTags } from '../services/tagService';

// Tags Store
interface TagsStore {
    tags: Tag[],
    isInit: boolean,

    fetchTags: () => Promise<void>
}

export const useTagsStore = create<TagsStore>((set, get) => ({
    tags: [],
    isInit: false,

    fetchTags: async () => {
        const areTagsInit = get().isInit;

        if (!areTagsInit) {
            const tags = await getTags()
            set(() => ({ tags: tags, isInit: true }));
        }
    }
}))