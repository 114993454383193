import React from 'react'
import { Link } from 'react-router-dom'
import { LocalisedPost } from '../../../models/PostDetails';
import { useLangStore } from '../../../state/stateManagement';
import { mainPostItem } from '../../../translations/home/homePageTranslations';
import { formatToBlogDate } from '../../../utils/dateFormatter';
import AuthorImage from '../../Author/Shared/AuthorImage';

export default function MainPost({ post }: { post: LocalisedPost }) {

    const { selectedLanguage } = useLangStore();

    return (
        <div className="blog blog-home5" >
            <div className="blog-banner" style={{ backgroundImage: `url('${post.images?.length ? post.images[0].url : post.category.imgUrl}')` }}> </div>
            <div className="container">
                <div className="post-list-hero"  >
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="post-list-content">
                                <h2 className="entry-title">
                                    <Link to={`/posts/${post.id}`}>{post.title}</Link>
                                </h2>
                                <ul className="entry-meta">
                                    <li className="post-author-img"><AuthorImage imageUrl={post.author.imgUrl} isMale={post.author.isMale} /></li>
                                    <li className="post-author"> <Link to={`/authors/${post.author.id}`}>{post.author.name}</Link></li>
                                    <li className="entry-cat"> <Link to={`/categories/${post.category.id}`} className="category-style-1"> <span className="line"></span> {post.category.name}</Link></li>
                                    <li className="post-date"> <span className="line"></span> {formatToBlogDate(post.createdOnUtc, selectedLanguage)}</li>
                                </ul>
                                <div className="post-exerpt">
                                    <p>{post.title}</p>
                                </div>
                                <div className="post-btn">
                                    <Link to={`/posts/${post.id}`} className="btn-read ">{mainPostItem.readMore.get(selectedLanguage)}<i className="las la-long-arrow-alt-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
