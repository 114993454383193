import { create } from 'zustand'
import produce from "immer";
import { getPost, getPosts, getPostsBy, getPostsCount, getPostsTranslations, PostBaseSearchParams, PostOrdering, PostSearchParams } from '../services/blogPostService';
import { commentsByPostId } from '../services/postCommentService';
import { PostComment } from '../models/Comment';
import { Post, PostMultiLanguage, PostTranslation } from '../models/PostDetails';
import { deepEqual } from '../utils/equality';
import { toMultiLangPost } from '../utils/mappers/postMappers';

export const postsSearchParamSelector = (store: PostDetailsStore, searchParams: PostSearchParams) => {
    let loadedPostIds: string[] | undefined = undefined;

    store.loadedPostsForSearchParamsMap.forEach((posts, loadedParams) => {
        if (deepEqual(searchParams, loadedParams)) { loadedPostIds = posts; }
    });

    if (!loadedPostIds) return undefined;

    let resultArr: PostMultiLanguage[] = [];
    (loadedPostIds as string[]).forEach(pId => {
        if (store.postsMap.has(pId))
            resultArr[resultArr.length] = store.postsMap.get(pId)!;
    })

    return resultArr;
}

export const postsCountSearchParamSelector = (store: PostDetailsStore, params: PostSearchParams) => {
    const postCountSearchParams: PostBaseSearchParams = { after: params.after, authorId: params.authorId, categoryId: params.categoryId, keyword: params.keyword, tag: params.tag };
    const postsCount = store.loadedPostCountsForSearchParams.find((kv) => {
        return deepEqual(postCountSearchParams, kv.params)
    })?.postsCount

    return postsCount
}

const orderedByPostsSelector = (store: PostDetailsStore, orderBy: PostOrdering, count: number) => {
    let orderedByPostIds: string[] | undefined = undefined;

    store.loadedPostsForSearchParamsMap.forEach((postIds, loadedParams) => {
        if (loadedParams.page === 1
            && loadedParams.orderBy === orderBy
            && (loadedParams.pageSize ?? 0) >= count) {
            orderedByPostIds = postIds.slice(0, count);
        }
    });

    if (!orderedByPostIds) return undefined;

    let resultArr: PostMultiLanguage[] = [];
    (orderedByPostIds as string[]).forEach(pId => {
        if (store.postsMap.has(pId))
            resultArr[resultArr.length] = store.postsMap.get(pId)!;
    })

    return resultArr;
}

const nextPrevPostSelector = (store: PostDetailsStore) => {
    if (!store.selectedPostId) {
        return undefined;
    }

    const selectedPost = store.postsMap.get(store.selectedPostId!);

    const categoryPostsInStore = Array.from(store.postsMap)
        .filter(p => p[1].category.id === selectedPost?.category.id)
        .map(p => p[1])
        .sort((objA, objB) => new Date(objB.createdOnUtc).getTime() - new Date(objA.createdOnUtc).getTime());

    if (categoryPostsInStore) {
        for (let index = 0; index < categoryPostsInStore.length; index++) {
            if (categoryPostsInStore[index].id === store.selectedPostId && index === categoryPostsInStore.length - 1) {
                return { nextPost: categoryPostsInStore[index - 1], prevPost: undefined }
            } else if (categoryPostsInStore[index].id === store.selectedPostId && index === 0) {
                return { nextPost: undefined, prevPost: categoryPostsInStore[index + 1] }
            } else if (categoryPostsInStore[index].id === store.selectedPostId && index > 0 && index < categoryPostsInStore.length - 1) {
                return { nextPost: categoryPostsInStore[index - 1], prevPost: categoryPostsInStore[index + 1] }
            }
        }
    }

    return undefined;
}
export const singlePostPageSelector = (store: PostDetailsStore) => {
    return {
        selectedPost: store.postsMap.get(store.selectedPostId!),
        selectPostItem: store.selectPostItem,
        nextAndPrevPost: nextPrevPostSelector(store),
        addPostTranslation: store.addPostTranslation,
        fetchNextAndPrevPosts: async (language: string = 'bg') => {
            if (!store.selectedPostId) return;

            const selectedPost = store.postsMap.get(store.selectedPostId!);
            const pageSize = 8;

            // check store
            const categoryPostsInStore = Array.from(store.postsMap)
                .filter(p => p[1].category.id === selectedPost?.category.id)
                .sort((objA, objB) => new Date(objB[1].createdOnUtc).getTime() - new Date(objA[1].createdOnUtc).getTime());

            // there are more than one post in the same category and is not the last
            // Assumption that posts are fetched page by page of certain size
            const isLastPost = categoryPostsInStore[categoryPostsInStore.length - 1][0] === store.selectedPostId;
            if (categoryPostsInStore.length > 1 && !isLastPost) return;

            // fetch till find
            for (let page = 1; true; page++) {
                let fetchedPosts = await store.fetchFilteredPosts({ categoryId: selectedPost?.category.id, orderBy: PostOrdering.CreatedOnDesc, page: page, pageSize: pageSize }, language)

                if (fetchedPosts.find(p => p.id === store.selectedPostId) !== undefined) {
                    if (fetchedPosts.length === pageSize
                        && fetchedPosts.indexOf(fetchedPosts.find(p => p.id === store.selectedPostId)!) === pageSize - 1) {
                        // if fetched results are with size of page and last in page, then fetch another page
                        await store.fetchFilteredPosts({ categoryId: selectedPost?.category.id, orderBy: PostOrdering.CreatedOnDesc, page: page + 1, pageSize: pageSize }, language)
                    }
                    break;
                }
                if (fetchedPosts.length === 0)
                    break
            }
        },
        removeCommentFromPost: store.removeCommentFromPost
    }
}

export const mostPopularPostsSelector = (store: PostDetailsStore) => orderedByPostsSelector(store, 5, 8);
const mostCommentedPostsSelector = (store: PostDetailsStore) => orderedByPostsSelector(store, 3, 8);
const mostRecentPostsSelector = (store: PostDetailsStore) => orderedByPostsSelector(store, 1, 8);
const mainPostSelector = (store: PostDetailsStore) => orderedByPostsSelector(store, 5, 1)?.find(() => true);

export const homePageSelector = (store: PostDetailsStore) => {
    return {
        mainPost: mainPostSelector(store),
        mostPopularPosts: mostPopularPostsSelector(store),
        mostCommentedPosts: mostCommentedPostsSelector(store),
        mostRecentPosts: mostRecentPostsSelector(store),

        addPostsTranslations: store.addPostsTranslations,

        fetchHomePagePosts: [store.fetchMostPopular, store.fetchMostCommentedPosts, store.fetchMostRecentPosts]
    };
}

// Post details store
export interface PostDetailsStore {
    postsMap: Map<string, PostMultiLanguage>,
    arePostsFetched: boolean;

    loadedPostCountsForSearchParams: { params: PostBaseSearchParams, postsCount: number }[],
    loadedPostsForSearchParamsMap: Map<PostSearchParams, string[]>

    selectedPostId?: string,

    addPostItem: (key: string, post: PostMultiLanguage) => void
    removePost: (key: string) => void

    addPostTranslation: (translation: PostTranslation, language: string) => void
    addPostsTranslations: (translations: PostTranslation[], language: string) => void

    addCommentToPost: (postId: string, comment: PostComment) => void
    removeCommentFromPost: (postId: string, commentId: string) => void

    selectPostItem: (key: string, language?: string) => Promise<void>

    fetchPostsCount: (params: PostSearchParams) => Promise<void>
    fetchFilteredPosts: (params: PostSearchParams, language: string) => Promise<Post[]>
    fetchPostItems: (page?: number, pageSize?: number, language?: string) => Promise<void>
    fetchMostPopular: (language: string) => Promise<void>
    fetchMostCommentedPosts: (language: string) => Promise<void>
    fetchMostRecentPosts: (language: string) => Promise<void>
}

export const usePostDetailsStore = create<PostDetailsStore>((set, get) => ({
    postsMap: new Map<string, PostMultiLanguage>(),
    arePostsFetched: false,

    loadedPostsForSearchParamsMap: new Map<PostSearchParams, string[]>(),
    loadedPostCountsForSearchParams: [],

    selectedPostId: undefined,
    filteredPosts: [],

    addPostItem: (key, post) => {
        if (!get().postsMap.has(key))
            set(produce<PostDetailsStore>((draft) => { draft.postsMap.set(key, post); }))
    },
    removePost: (key: string) => {
        if (get().postsMap.has(key))
            set(produce<PostDetailsStore>((draft) => { draft.postsMap.delete(key); }))
    },

    addPostTranslation(translation, language) {
        let post = get().postsMap.get(translation.id);
        if (!post) return;

        //TODO: If the translation is present then don't push
        if (!post.titleTranslations.some(t => t.language === language)) {
            post = produce(post, draft => {
                draft.titleTranslations.push({ language: language, word: translation.title });
                draft.contentPreviewTranslations.push({ language: language, word: translation.contentPreview });
                draft.contentTranslations.push({ language: language, word: translation.content });
            });
        }

        set(produce<PostDetailsStore>((draft) => { draft.postsMap.set(post!.id, post!) }))
    },

    addPostsTranslations(translations, language) {
        for (let index = 0; index < translations.length; index++) {
            const translation = translations[index];
            get().addPostTranslation(translation, language);
        }
    },

    selectPostItem: async (key: string, language: string = 'bg') => {
        let post = get().postsMap.get(key);

        if (!post) {
            // Post not in the store
            const response = await getPost(key, language);
            post = toMultiLangPost(response, language)
            get().addPostItem(response.id, post);
        } else if (!post.titleTranslations.some(t => t.language === language)) {
            // Post is in the store, but not with that translation
            const translations = await getPostsTranslations([post.id], language);
            get().addPostTranslation(translations[0], language);
        }

        if (post && !post.comments) {
            // Post is in the store but no comments are loaded
            const comments = await commentsByPostId(key);
            set(produce<PostDetailsStore>((draft) => { draft.postsMap.set(key, { ...post!, comments: comments }) }))
        }

        set(() => ({ selectedPostId: post!.id }));
    },
    addCommentToPost(postId: string, comment: PostComment) {
        let post = get().postsMap.get(postId);
        if (!post) return;

        post = produce(post, draft => { draft.comments?.push(comment); });

        set(produce<PostDetailsStore>((draft) => { draft.postsMap.set(post!.id, post!) }))
    },
    removeCommentFromPost(postId: string, commentId: string) {
        let post = get().postsMap.get(postId);
        if (!post || !post.comments) return;

        post = produce(post, draft => {
            const index = draft.comments!.findIndex(c => c.id === commentId);
            if (index !== -1) draft.comments?.splice(index, 1)
        })

        set(produce<PostDetailsStore>((draft) => { draft.postsMap.set(post!.id, post!) }))
    },
    async fetchPostItems(page, pageSize, language = 'bg') {
        if (get().arePostsFetched) return;

        const response = await getPosts(page, pageSize);

        for (const post in response) {
            if (Object.prototype.hasOwnProperty.call(response, post)) {
                const element = response[post];
                get().addPostItem(element.id, toMultiLangPost(element, language));
            }
        }

        set(() => ({ arePostsFetched: true }));
    },
    async fetchPostsCount(params: PostSearchParams) {
        const postCountSearchParams: PostBaseSearchParams = { after: params.after, authorId: params.authorId, categoryId: params.categoryId, keyword: params.keyword, tag: params.tag };
        let postsCount = get().loadedPostCountsForSearchParams.find(p => deepEqual(p.params, postCountSearchParams))?.postsCount;

        if (!postsCount)
            postsCount = await getPostsCount(postCountSearchParams);

        set((state) => ({
            loadedPostCountsForSearchParams: [...state.loadedPostCountsForSearchParams, { params: postCountSearchParams, postsCount: postsCount! }]
        }));
    },
    async fetchFilteredPosts(params: PostSearchParams, language = 'bg') {
        let postIdsForSearchParams: string[] | undefined = undefined;

        get().loadedPostsForSearchParamsMap.forEach((posts, loadedParams) => {
            if (deepEqual(params, loadedParams)) { postIdsForSearchParams = posts; }
        });


        if (!postIdsForSearchParams) {
            const response = await getPostsBy(params, language);

            for (const post in response) {
                if (Object.prototype.hasOwnProperty.call(response, post)) {
                    const postItem = response[post];
                    get().addPostItem(postItem.id, toMultiLangPost(postItem, language));
                }
            }

            set((state) => ({
                filteredPosts: response,
                loadedPostsForSearchParamsMap: produce(state.loadedPostsForSearchParamsMap, draft => draft.set(params!, response.map(p => p.id)))
            }));

            return response;
        }
        else {
            let filteredPosts: Post[] = [];
            (postIdsForSearchParams as string[]).forEach(pId => {
                if (get().postsMap.has(pId))
                    filteredPosts[filteredPosts.length] = get().postsMap.get(pId)!;
            })

            return filteredPosts;
        }
    },
    async fetchMostPopular(language = 'bg') {
        let mostPopularPostIds: string[] | undefined = undefined;

        get().loadedPostsForSearchParamsMap.forEach((posts, loadedParams) => {
            if (loadedParams.page === 1
                && loadedParams.orderBy === PostOrdering.VisitCountDesc
                && (loadedParams.pageSize ?? 0) >= 5) {
                mostPopularPostIds = posts;
            }
        });

        if (!mostPopularPostIds) {
            get().fetchFilteredPosts({ orderBy: PostOrdering.VisitCountDesc, page: 1, pageSize: 8 }, language);
        }
    },
    async fetchMostCommentedPosts(language = 'bg') {
        let mostCommentedPostIds: string[] | undefined = undefined;

        get().loadedPostsForSearchParamsMap.forEach((posts, loadedParams) => {
            if (loadedParams.page === 1
                && loadedParams.orderBy === PostOrdering.CommentCountDesc
                && (loadedParams.pageSize ?? 0) >= 5) {
                mostCommentedPostIds = posts;
            }
        });

        if (!mostCommentedPostIds) {
            get().fetchFilteredPosts({ orderBy: PostOrdering.CommentCountDesc, page: 1, pageSize: 8 }, language);
        }
    },
    async fetchMostRecentPosts(language = 'bg') {
        let mostRecentPostIds: string[] | undefined = undefined;

        get().loadedPostsForSearchParamsMap.forEach((posts, loadedParams) => {
            if (loadedParams.page === 1
                && loadedParams.orderBy === PostOrdering.CreatedOnDesc
                && (loadedParams.pageSize ?? 0) >= 5) {
                mostRecentPostIds = posts;
            }
        });

        if (!mostRecentPostIds) {
            get().fetchFilteredPosts({ orderBy: PostOrdering.CreatedOnDesc, page: 1, pageSize: 8 }, language);
        }
    }
}));