export const blogApiConfig = {
    baseUrl: process.env.REACT_APP_BACKEND_URL
};

export const appName = process.env.REACT_APP_NAME;
export const appDomain = process.env.REACT_APP_DOMAIN;
export const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;
export const companyName = process.env.REACT_APP_COMPANY_NAME;
export const postImagesFolder = process.env.REACT_APP_ARTICLE_IMAGES_FOLDER;
export const authorProfileImagesFolder = process.env.REACT_APP_AUTHOR_PROFILE_IMAGES_FOLDER;
export const productImagesFolder = process.env.REACT_APP_USER_PRODUCT_IMAGES_FOLDER;