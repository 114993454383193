import React from 'react'
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom'
import { useLangStore } from '../../../state/stateManagement'
import { loginForm } from '../../../translations/login/login';
import { isNotOnlyWhiteSpaces } from '../../../utils/predicates';
import InstagramPanel from '../../Instagram/InstagramPanel'

export default function LoginPage() {

    const { selectedLanguage } = useLangStore();
    const { register, handleSubmit } = useForm<LoginForm>();

    return (
        <>
            <section className="login">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-8 m-auto">
                            <div className="login-content">
                                <h4>{loginForm.login.get(selectedLanguage)}</h4>
                                <p></p>
                                <form className="sign-form widget-form " method="post" onSubmit={handleSubmit(data => console.log(data))}>
                                    <div className="form-group">
                                        <input {...register('username', { required: true, maxLength: 40, validate: isNotOnlyWhiteSpaces })} type="text" className="form-control" placeholder={loginForm.usernamePlaceholder.get(selectedLanguage)} />
                                    </div>
                                    <div className="form-group">
                                        <input {...register('password', { required: true, validate: isNotOnlyWhiteSpaces })} type="password" className="form-control" placeholder={loginForm.passwordPlaceholder.get(selectedLanguage)} />
                                    </div>
                                    <div className="sign-controls form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input {...register('rememberMe', { required: false })} type="checkbox" className="custom-control-input" id="rememberMe" />
                                            <label className="custom-control-label" htmlFor="rememberMe">{loginForm.rememberMe.get(selectedLanguage)}</label>
                                        </div>
                                        <Link to='#' className="btn-link ">{loginForm.forgotPassword.get(selectedLanguage)}</Link>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn-custom">{loginForm.loginBtn.get(selectedLanguage)}</button>
                                    </div>
                                    <p className="form-group text-center">{loginForm.notAccount.get(selectedLanguage)}
                                        <Link to='/signup' className="btn-link">{loginForm.createAccountLink.get(selectedLanguage)}</Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <InstagramPanel />
        </>
    )
}
