import React from 'react'
import VerticalAd from "../../../assets/img/ads/ads-vertical.webp"

export default function AdVertical() {
    return (
        <div className="widget pb-0">
                <img src={VerticalAd} alt="" />
        </div>
    )
}
