import { Configuration, LogLevel } from "@azure/msal-browser";
import { appDomain } from "./config";

export const b2cPolicies = {
    names: {
        signUpSignIn: "b2c_1_susi_reset_v2",
        forgotPassword: "b2c_1_reset_v2",
        // editProfile: "b2c_1_edit_profile_v2"
    },
    authorities: {
        signUpSignIn: {
            authority: process.env.REACT_APP_IDENTITY_PROVIDER_SIGNIN_URL!
        },
        forgotPassword: {
            authority: process.env.REACT_APP_IDENTITY_PROVIDER_FORGOTPASSWORD_URL!
        },

        // editProfile: {
        //     authority: "https://blogspace.onmicrosoft.com.b2clogin.com/blogspace.onmicrosoft.com.onmicrosoft.com/b2c_1_edit_profile_v2"
        // }
    },
    authorityDomain: process.env.REACT_APP_IDENTITY_PROVIDER_DOMAIN!
};


export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_IDENTITY_PROVIDER_CLIENTID!, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: appDomain, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: appDomain, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        iframeHashTimeout: 10000,
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }

                if (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'stage') {
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                    }
                }
            }
        }
    }
}

export const protectedResources = {
    commentAddApi: {
        endpoint: `${appDomain}comments`,
        scopes: ["https://blogspace.onmicrosoft.com/blog-api/Comments.Write"],
    },
}

export const scopes = {
    openId: "openid",
    email: "email",
    user_impersonation: "https://blogspace.onmicrosoft.com/blog-api/user_impersonation",
    comments: {
        write: "https://blogspace.onmicrosoft.com/blog-api/Comments.Write",
    },
    posts: {
        write: "https://blogspace.onmicrosoft.com/blog-api/Posts.Write"
    }
}