import React from 'react'
import BlackLogo from '../../../assets/img/logo/SYP_white_theme.webp'
import WhiteLogo from '../../../assets/img/logo/SYP_black_theme.webp'

export default function Logo({ isWhite }: { isWhite: boolean }) {
    return (
        isWhite
            ? <img src={WhiteLogo} alt="logo" />
            : <img src={BlackLogo} alt="logo" />
    )
}
