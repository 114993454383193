import React from 'react'
import { Link } from 'react-router-dom'
import { useLangStore } from '../../../state/stateManagement'
import { formatToBlogDate } from '../../../utils/dateFormatter'
import parse from 'html-react-parser'
import { LocalisedPost } from '../../../models/PostDetails'

export default function PostHorizontalNoImage({ post }: { post: LocalisedPost }) {

    const { selectedLanguage } = useLangStore();

    return (
        <div className="post-list post-list-style6">
            <div className="post-list-content">
                <ul className="entry-meta">
                    <li className="entry-cat"> <Link to={`/categories/${post.category.id}`} className="category-style-1"> {post.category.name}</Link></li>
                    <li className="post-author"> <Link to={`/authors/${post.author.id}`}><span className="line"></span>{post.author.name}</Link></li>
                    <li className="post-date"> <span className="line"></span> {formatToBlogDate(post.createdOnUtc, selectedLanguage)}</li>
                </ul>
                <h4 className="entry-title">
                    <Link to={`/posts/${post.id}`}>{post.title}</Link>
                </h4>
                <div className="post-exerpt">
                    {parse(post.contentPreview)}
                </div>
            </div>
        </div>
    )
}
