export const headerTranslations = {
    home: new Map([["en", "Home"], ["bg", "Начало"]]),
    categories: new Map([["en", "Categories"], ["bg", "Категории"]]),
    categoryCreate: new Map([["en", "Create Category"], ["bg", "Създай Категория"]]),
    posts: new Map([["en", "Posts"], ["bg", "Статии"]]),
    postCreate: new Map([["en", "Create Post"], ["bg", "Напиши Статия"]]),
    authors: new Map([["en", "Authors"], ["bg", "Автори"]]),
    unapprovedPosts: new Map([["en", "Unapproved Posts"], ["bg", "Непотвърдени Статии"]]),
    about: new Map([["en", "About"], ["bg", "За нас"]]),
    shop: new Map([["en", "Shop"], ["bg", "Магазин"]]),
    contact: new Map([["en", "Contact"], ["bg", "Контакти"]]),

    becomeAuthor: new Map([["en", "Become Author"], ["bg", "Стани Автор"]]),

    createProduct: new Map([["en", "Create Product"], ["bg", "Създай Продукт"]]),

    inqueries: new Map([["en", "Inqueries"], ["bg", "Контактни форми"]]),
    users: new Map([["en", "Users"], ["bg", "Потребители"]]),

    signupBtnText: new Map([["en", "Register"], ["bg", "Регистрация"]]),
    signInBtnText: new Map([["en", "Log In"], ["bg", "Вход"]]),
    signOutBtnText: new Map([["en", "Log Out"], ["bg", "Изход"]]),
}