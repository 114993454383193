import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { useLangStore } from '../../state/stateManagement';
import { imageEditableTranslations } from '../../translations/shared/imageEditable';
import { changeExtention, getImageDimensions, resizeFile } from '../../utils/fileUtils';
import HiddenImageInput from '../Post/PostCreatePage/HiddenImageInput';
import ReactModal from 'react-modal';
import { imageEditorTranslations } from '../../translations/posts/postCreatePage';
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import { BlogImage } from '../../models/Image';

export default function ImageEditable({ imageData, onSave, onImageLoad, onEditorClose }: { imageData?: BlogImage, onSave?: (image?: BlogImage) => void, onImageLoad?: (image: BlogImage) => void, onEditorClose?: () => void }) {

    //Hiden image input triggering helper
    const imageInput = useRef<HTMLInputElement>(null);

    // Language
    const selectedLanguage = useLangStore(store => store.selectedLanguage);

    // Preview image (after edited in the Image editor)
    const [previewImage, setPreviewImage] = useState<BlogImage | undefined>(imageData);

    // Image Editor photo
    const [editorImage, setEditorImage] = useState<BlogImage>();

    // Modal actions
    const [isImageEditorModalOpen, setIsImageEditorModalOpen] = useState<boolean>(false);
    const openImageEditorModal = async (image: BlogImage) => {
        let imageToRenderInEditor = { ...image };

        if (image.widthInPx && image.heightInPx && image.widthInPx < 200 && image.heightInPx < 200) {
            const resizedImageBase64 = await resizeFile(image.urlOrBase64, 200, 200, 'JPEG');
            imageToRenderInEditor = { ...imageToRenderInEditor, ...{ urlOrBase64: resizedImageBase64, heightInPx: 200, widthInPx: 200 } };
        }

        setEditorImage(imageToRenderInEditor);
        setIsImageEditorModalOpen(true);
        onImageLoad && onImageLoad(imageToRenderInEditor);
    }
    const closeImageEditorModal = () => {
        setIsImageEditorModalOpen(false);
        onEditorClose && onEditorClose()
    }

    useEffect(() => {
        setPreviewImage(imageData);
    }, [imageData])

    return (
        <>
            {previewImage
                ? <div className='image-preview'>
                    <FontAwesomeIcon
                        className='right'
                        icon={faClose}
                        onClick={() => { setPreviewImage(undefined); onSave && onSave(undefined); }}
                    />
                    <img
                        alt='preview'
                        className='edited-image'
                        onClick={async () => {
                            let imgToLoadInEditor = previewImage;
                            if (!imgToLoadInEditor.heightInPx || !imgToLoadInEditor.widthInPx) {
                                const dimentions = await getImageDimensions(previewImage.urlOrBase64);
                                imgToLoadInEditor = { ...previewImage, ...{ heightInPx: dimentions.h, widthInPx: dimentions.w } };
                            }

                            openImageEditorModal(imgToLoadInEditor);
                        }}
                        src={previewImage.urlOrBase64}
                    />
                </div>
                : <div
                    onClick={() => imageInput.current?.click()}
                    className='image-upload-click-area'>
                    <span>{imageEditableTranslations.clickToUploadImage.get(selectedLanguage)}</span>
                </div>}

            <ReactModal
                isOpen={isImageEditorModalOpen}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                appElement={document.getElementById('root') as HTMLElement}
                style={{ content: { inset: '110px 10% 5% 10%' } }}
            >
                {editorImage && <FilerobotImageEditor
                    showBackButton={false}
                    translations={{
                        save: imageEditorTranslations.imageEditorSaveBtnText.get(selectedLanguage),
                        defaultPost: imageEditorTranslations.cropPostSize.get(selectedLanguage)
                    }}
                    source={editorImage.urlOrBase64}
                    closeAfterSave={true}
                    defaultSavedImageType={'webp'}
                    onBeforeSave={() => { return false }}
                    onSave={(editedImageObject) => {
                        if (editedImageObject.imageBase64) {
                            const editedPreviewImage = { name: changeExtention(editorImage.name, 'webp'), urlOrBase64: editedImageObject.imageBase64, heightInPx: editedImageObject.height, widthInPx: editedImageObject.width };
                            setPreviewImage(editedPreviewImage);
                            onSave && onSave(editedPreviewImage!);
                            closeImageEditorModal();
                        }
                    }}
                    onClose={closeImageEditorModal}
                    tabsIds={[TABS.ADJUST]} // or {['Adjust']}
                    defaultTabId={TABS.ADJUST} // or 'ADJUST'
                    defaultToolId={TOOLS.RESIZE} // or 'RESIZE'
                    savingPixelRatio={3}
                    previewPixelRatio={3}
                    showCanvasOnly={false}
                    Crop={{
                        presetsItems: [
                            {
                                titleKey: 'Post List Image Size',
                                descriptionKey: '1:1',
                                ratio: (1).toString()
                                // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
                            },
                            {
                                titleKey: 'Post Main Image Size',
                                descriptionKey: '3.8:1',
                                ratio: (3.8).toString()
                                // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
                            }
                        ]
                    }}
                />}
            </ReactModal>

            <HiddenImageInput
                ref={imageInput}
                onImageLoaded={(imageUrl, imageName, heightInPx, widthInPx) => {
                    openImageEditorModal({ urlOrBase64: imageUrl, name: imageName, heightInPx, widthInPx });
                }}
            />
        </>
    )
}
