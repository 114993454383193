import React from 'react'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'
import { LocalisedProduct } from '../../../../models/Shop/Product'
import { useLangStore } from '../../../../state/stateManagement'
import { productMainItemTranslations } from '../../../../translations/shop/product/productSinglePage';

export default function ProductMainContent({ product }: { product: LocalisedProduct }) {

    const selectedLanguage = useLangStore(store => store.selectedLanguage);

    return (
        <div className="shop-single-content">
            <h2>{product.name}</h2>
            <div className="rate d-flex align-items-center">
                <div className="star">
                    <i className="las la-star"></i>
                    <i className="las la-star"></i>
                    <i className="las la-star"></i>
                    <i className="las la-star"></i>
                    <i className="las la-star"></i>
                </div>
                <small>({product.reviewsSummary?.reviewsCount ?? 0} {productMainItemTranslations.reviews.get(selectedLanguage)})</small>
            </div>

            <h3 className="price">{product.price.amount} {product.price.currency}</h3>
            <section className='description'>
                {parse(product.shortDescription)}
            </section>

            <div className="controller">
                <div className="quantity">
                    <Link className="min btn-action" to="#">
                        <i className="las la-minus"></i>
                    </Link>

                    <div className="btn-number">
                        <input type="number" value="1" />
                    </div>

                    <Link className="max btn-action" to="#">
                        <i className="las la-plus"></i>
                    </Link>
                </div>
                <Link className='btn-custom' to={'cart'}>{productMainItemTranslations.addToCart.get(selectedLanguage)}</Link>
            </div>
        </div>
    )
}
