import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import PostMultiple from './PostMultiplePage/PostMultiple'
import Sidebar from '../Sidebar/Sidebar'
import StickyBox from "react-sticky-box";
import { useLangStore, useLoading } from '../../state/stateManagement';
import { postListPage } from '../../translations/posts/postListPage';
import Heading from '../PageHeading/Heading';
import LeftAlignHeading from '../PageHeading/LeftAlignHeading';
import Pagination from '../Pagination/Pagination';
import { postsCountSearchParamSelector, postsSearchParamSelector, usePostDetailsStore } from '../../state/postDetailsStore';
import { getPostsTranslations, PostOrdering, PostSearchParams, toUrlSearchParams } from '../../services/blogPostService';
import { toLocalisedPost } from '../../utils/mappers/postMappers';
import SEO, { getLocalisation } from '../Shared/SEO';

export default function PostMultiplePage() {
    const { loadingFetch } = useLoading();
    const { selectedLanguage } = useLangStore();

    // Get query string
    const [params] = useSearchParams();

    // Local State
    const currentPage = Number(params.get('page') ?? '1');
    const pageSize = 8;
    const searchParams: PostSearchParams = { page: currentPage, pageSize: pageSize, orderBy: PostOrdering.CreatedOnDesc };

    const { postCount, fetchPostsCount, filteredPosts, filteredLocalisedPosts, fetchFilteredPosts, addPostsTranslations } = usePostDetailsStore(store => ({
        postCount: postsCountSearchParamSelector(store, searchParams),
        filteredPosts: postsSearchParamSelector(store, searchParams),
        filteredLocalisedPosts: postsSearchParamSelector(store, searchParams)?.map(p => toLocalisedPost(p, selectedLanguage)),
        fetchPostsCount: store.fetchPostsCount,
        fetchFilteredPosts: store.fetchFilteredPosts,
        addPostsTranslations: store.addPostsTranslations
    }));

    useEffect(() => {
        loadingFetch([fetchPostsCount(searchParams), fetchFilteredPosts(searchParams, selectedLanguage).then()])
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (filteredPosts && filteredPosts.some(p => !p.titleTranslations.some(t => t.language === selectedLanguage))) {
            const postIdsWithoutTranslationsLoaded = filteredPosts.filter(p => !p.titleTranslations.some(t => t.language === selectedLanguage)).map(p => p.id);
            loadingFetch([
                getPostsTranslations(postIdsWithoutTranslationsLoaded, selectedLanguage).then(translations => { addPostsTranslations(translations, selectedLanguage); })
            ])
        }
    }, [selectedLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {<SEO title={postListPage.pageTitle.get(selectedLanguage)!} description={postListPage.description.get(selectedLanguage)!} localisation={getLocalisation(selectedLanguage)} ogImage={''} />}
            <LeftAlignHeading headingContent={
                <Heading
                    heading={postListPage.heading.get(selectedLanguage)!}
                    segment={postListPage.segment.get(selectedLanguage)!}
                    description={postListPage.description.get(selectedLanguage)}
                />
            } />

            {filteredLocalisedPosts &&
                <PostMultiple
                    posts={filteredLocalisedPosts}
                    sidebar={<StickyBox offsetTop={100}> <Sidebar /></StickyBox>}
                    pagingComponent={
                        <Pagination
                            currentPage={currentPage}
                            totalCount={postCount ?? filteredLocalisedPosts.length}
                            pageSize={pageSize}
                            onPageChange={async page => {
                                if (page * pageSize < ((postCount ?? filteredLocalisedPosts.length) + pageSize)) {
                                    await loadingFetch([fetchFilteredPosts({ ...searchParams, ...{ page: page } }, selectedLanguage).then()])
                                }
                            }}
                            queryParams={toUrlSearchParams(searchParams)}
                            className="list-inline"
                        />}
                />}
        </>
    )
}
