import { timespanTranslations } from "../translations/utils/timeTransformations";
import { FormatString } from "./stringFormatter";

export const formatForBackend = (date: Date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDay() + 1}`;
}

export const formatToBlogDate = (date: Date, lang?: string): string => {
    if (!date) return '';

    date = new Date(date);
    const monthResolver = new Intl.DateTimeFormat(lang ?? 'en', { month: 'long' });
    const monthName = monthResolver.format(date);
    const day = date.getDate();
    const year = date.getFullYear();


    return `${monthName} ${day}, ${year}`;
}

export const getMinutesBetween = (startDate: Date, endDate: Date): number => {
    return Math.round((startDate.getTime() - endDate.getTime()) / 60 * 1000);
}

export const getTimeBetween = (startDate: Date, endDate: Date): TimeDifference => {

    startDate = new Date(startDate);
    endDate = new Date(endDate);

    const years = endDate.getFullYear() - startDate.getFullYear();
    const months = endDate.getMonth() - startDate.getMonth();
    const days = endDate.getDate() - startDate.getDate();
    const hours = endDate.getHours() - startDate.getHours();
    const minutes = endDate.getMinutes() - startDate.getMinutes();

    return {
        years: years,
        months: months,
        days: days,
        hours: hours,
        minutes: minutes
    };
}

export interface TimeDifference {
    years: number,
    months: number,
    days: number,
    hours: number,
    minutes: number
}

export const getTimeSinceNow = (startDate: Date, lang: string): string => {
    return getTimeBetweenAsString(startDate, new Date(), lang);
}

export const getTimeBetweenAsString = (startDate: Date, endDate: Date, lang: string): string => {
    var timespan = getTimeBetween(startDate, endDate);

    if (timespan.years === 1)
        return FormatString(timespanTranslations.year.get(lang)!, `${timespan.years}`);

    if (timespan.years > 1)
        return FormatString(timespanTranslations.years.get(lang)!, `${timespan.years}`);

    if (timespan.months === 1)
        return FormatString(timespanTranslations.month.get(lang)!, `${timespan.months}`);

    if (timespan.months > 1)
        return FormatString(timespanTranslations.months.get(lang)!, `${timespan.months}`);

    if (timespan.days === 1)
        return FormatString(timespanTranslations.day.get(lang)!, `${timespan.days}`);

    if (timespan.days > 1)
        return FormatString(timespanTranslations.days.get(lang)!, `${timespan.days}`);

    if (timespan.hours === 1)
        return FormatString(timespanTranslations.hour.get(lang)!, `${timespan.hours}`);

    if (timespan.hours > 1)
        return FormatString(timespanTranslations.hours.get(lang)!, `${timespan.hours}`);

    if (timespan.minutes === 1)
        return FormatString(timespanTranslations.minute.get(lang)!, `${timespan.minutes}`);

    if (timespan.minutes > 1)
        return FormatString(timespanTranslations.minutes.get(lang)!, `${timespan.minutes}`);

    return FormatString(timespanTranslations.minutes.get(lang)!, `1`);;
}