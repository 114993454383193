import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { PostImage } from '../models/PostDetails'

interface PostDraft {
    title?: string,
    categoryId?: string,
    readTimeInMinutes?: number
    contentPreview?: string,
    content?: string,
    images?: PostImage[],
    tags?: string[],
}

interface PostDraftStore {
    draft?: PostDraft,
    editDraft: (changes: PostDraft) => void,
    clearDraft: () => void
}

export const usePostCreateDraftStore = create<PostDraftStore>()(
    persist(
        (set, get) => ({
            editDraft: (newDraft) => {
                set(d => ({ draft: { ...d.draft, ...newDraft } }));
            },
            clearDraft: () => set(() => ({ draft: undefined }))
        }),
        {
            name: 'draftPost',
            getStorage: () => localStorage,
        }
    )
);