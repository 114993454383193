import React, { useEffect } from 'react'
import { useAuthorsStore } from '../../state/authorsStore'
import { useLangStore, useLoading } from '../../state/stateManagement'
import { authorListPage } from '../../translations/author/authorListPage'
import { toLocalisedAuthor } from '../../utils/mappers/authorMappers'
import CenterHeading from '../PageHeading/CenterHeading'
import Heading from '../PageHeading/Heading'
import SEO, { getLocalisation } from '../Shared/SEO'
import AuthorSingleSmall from './AuthorMultiplePage/AuthorSingleSmall'

export default function AuthorMultiplePage() {

    const { setLoading } = useLoading();
    const { selectedLanguage } = useLangStore();

    const { authors, fetchAuthors } = useAuthorsStore(store => ({ authors: store.authors.map(a => toLocalisedAuthor(a, selectedLanguage)), fetchAuthors: store.fetchAuthors }));

    useEffect(() => {
        setLoading(true);
        fetchAuthors().then(() => { setLoading(false); })

    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            {<SEO title={authorListPage.pageTitle.get(selectedLanguage)!} description={authorListPage.metaDescription.get(selectedLanguage)!} localisation={getLocalisation(selectedLanguage)} ogImage={''} />}
            <CenterHeading headingContent={
                <Heading heading={authorListPage.heading.get(selectedLanguage)!} segment={authorListPage.segment.get(selectedLanguage)!} />
            } />
            <div className="authors ">
                <div className="container">
                    <div className="authors-area">
                        <div className="row">
                            {authors
                                && authors.map(author =>
                                    <div key={author.id} className="col-md-6 ">
                                        <AuthorSingleSmall author={author} />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
