import { getWithToken } from "./baseHttpClient";

const sasTokenUrl = "api/authors/images/get-sas"
export const getUserContainerSasUrl = async (accessToken: string): Promise<string> => {
    const response = await getWithToken(accessToken, sasTokenUrl);
    return response;
}

const categoriesSasTokenUrl = "api/categories/images/get-sas"
export const getCategoriesContainerSasUrl = async (accessToken: string): Promise<string> => {
    const response = await getWithToken(accessToken, categoriesSasTokenUrl);
    return response;
}