import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquareFacebook, faSquareInstagram, faSquarePinterest, faSquareTwitter, faSquareYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Link } from 'react-router-dom'
import { useLangStore } from '../../../state/stateManagement';
import { sidebarSocialMediaSection } from '../../../translations/shared/sidebar';

export default function SocialMediaPopularity({ socialMediaInfo }: { socialMediaInfo: SocialMediaFollows[] }) {
    
    const { selectedLanguage } = useLangStore();

    const socialMediaMap : Map<string, IconProp> = new Map([
        ["facebook", faSquareFacebook],
        ["instagram", faSquareInstagram],
        ["twitter", faSquareTwitter],
        ["youtube", faSquareYoutube],
        ["pinterest", faSquarePinterest]
    ]);

    return (
        <div className="widget">
            <div className="widget-title">
                <h5>{sidebarSocialMediaSection.stayConnectedHeading.get(selectedLanguage)}</h5>
            </div>

            <div className="widget-stay-connected">
                <div className="list">
                    {
                        socialMediaInfo.map(sm =>
                            <div key={sm.name} className={`item color-${sm.name}`}>
                                <Link to={sm.socialMediaPageUrl}> <FontAwesomeIcon icon={socialMediaMap.get(sm.name)!} /> <i className={`fab fa-${sm.name}`}></i></Link>
                                <p>{sm.name} {sm.followers}</p>
                            </div>)
                    }
                </div>
            </div>
        </div>
    )
}
