import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser'
import { useLangStore, useLoading } from '../../../state/stateManagement';
import { productDetailsNavTranslations, productSingleHeadingTranslations } from '../../../translations/shop/product/productSinglePage';
import CenterHeading from '../../PageHeading/CenterHeading';
import Heading from '../../PageHeading/Heading';
import ProductMainContent from './ProductSinglePage/ProductMainContent'
import ProductMainImage from './ProductSinglePage/ProductMainImage'
import ReviewsList from './ProductSinglePage/ReviewsList';
import Specifications from './ProductSinglePage/Specifications';
import { useProductStore } from '../../../state/Shop/productStore';
import { toLocalisedProduct } from '../../../utils/mappers/productMappers';

export default function ProductSinglePage() {

    const { productId } = useParams<string>();

    const setLoading = useLoading(store => store.setLoading);
    const selectedLanguage = useLangStore(store => store.selectedLanguage);

    const { selectProduct, selectedProduct } = useProductStore(store => ({
        selectProduct: store.selectProduct,
        selectedProduct: store.selectedProductId && toLocalisedProduct(store.products.find(p => p.id === productId)!, selectedLanguage)
    }))

    useEffect(() => {
        setLoading(true);
        productId && selectProduct(productId).then(() => setLoading(false));
    }, [productId, selectProduct, setLoading])

    return (
        <>
            <CenterHeading headingContent={
                <Heading segment={productSingleHeadingTranslations.segment.get(selectedLanguage)!} />
            } />
            {selectedProduct && <section className="shop">
                <div className="container">
                    <div className="shop-single">
                        <div className="row ">
                            {/* <!--shop-single-image--> */}
                            <div className="col-lg-5 col-md-5">
                                <ProductMainImage product={selectedProduct} />
                            </div>
                            {/* <!--shop-single-content--> */}
                            <div className="col-lg-7 col-md-7">
                                <ProductMainContent product={selectedProduct} />
                            </div>
                            {/* <!--/--> */}
                        </div>
                        <div className="row mt-50">
                            <div className="col-lg-12">
                                {/* <!--shop-single-details--> */}
                                <div className="shop-single-tap">
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <a className="nav-item nav-link active" id="nav-desc-tab" data-toggle="tab" href="#nav-desc" role="tab" aria-controls="nav-desc" aria-selected="true">{productDetailsNavTranslations.description.get(selectedLanguage)}</a>
                                            <a className="nav-item nav-link" id="nav-speci-tab" data-toggle="tab" href="#nav-speci" role="tab" aria-controls="nav-speci" aria-selected="false">{productDetailsNavTranslations.specifications.get(selectedLanguage)}</a>
                                            <a className="nav-item nav-link" id="nav-review-tab" data-toggle="tab" href="#nav-review" role="tab" aria-controls="nav-review" aria-selected="false">{productDetailsNavTranslations.reviews.get(selectedLanguage)} ( {selectedProduct.reviewsSummary?.reviewsCount ?? 0} )</a>
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        {/* <!--Description--> */}
                                        <div className="tab-pane fade show active" id="nav-desc" role="tabpanel" aria-labelledby="nav-desc-tab">
                                            <p>{parse(selectedProduct.description)}</p>
                                        </div>
                                        {/* <!--Specification--> */}
                                        <div className="tab-pane fade" id="nav-specifications" role="tabpanel" aria-labelledby="nav-speci-tab">
                                            {selectedProduct.specifications && <Specifications specifications={selectedProduct.specifications} />}
                                        </div>
                                        {/* <!--Reviews--> */}
                                        <div className="tab-pane fade" id="nav-review" role="tabpanel" aria-labelledby="nav-review-tab">
                                            <ReviewsList reviews={[]} />
                                        </div>
                                    </div>

                                </div>
                                {/* <!--/--> */}
                                {/* <!--related-products--> */}
                                <div className="shop-single-related-products">

                                </div>
                                {/* <!--/--> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        </>
    )
}
