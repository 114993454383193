import React from 'react'
import { Link } from 'react-router-dom';
import { useLangStore } from '../../../state/stateManagement';
import { footerTranslations } from '../../../translations/shared/footer';
import Newsletter from './Newsletter';

export default function Footer(): any {

    const { selectedLanguage } = useLangStore();

    return (
        <div className="footer">
            <div className="footer-area">
                <div className="footer-area-content">
                    <div className="container">
                        <div className="row ">
                            <div className="col-md-3">
                                <div className="menu">
                                    <h6>{footerTranslations.menu.get(selectedLanguage)}</h6>
                                    <ul>
                                        <li><Link to="/">{footerTranslations.home.get(selectedLanguage)}</Link></li>
                                        <li><Link to="about">{footerTranslations.aboutUs.get(selectedLanguage)}</Link></li>
                                        <li><Link to="contact">{footerTranslations.contactUs.get(selectedLanguage)}</Link></li>
                                        <li><Link to="privacy">{footerTranslations.privacy.get(selectedLanguage)}</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <Newsletter />
                            </div>

                            <div className="col-md-3">
                                <div className="menu">
                                    <h6>{footerTranslations.followUs.get(selectedLanguage)}</h6>
                                    <ul>
                                        <li><a href="https://www.facebook.com/profile.php?id=100088438481858" target={'_blank'} rel="noopener noreferrer">facebook</a></li>
                                        <li><a href="https://www.instagram.com/start.your.post/" target={'_blank'} rel="noopener noreferrer">instagram</a></li>
                                        {/* <li><Link to="#" target={'_blank'}>youtube</Link></li>
                                        <li><Link to="#" target={'_blank'}>twitter</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-area-copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright">
                                    <p>{footerTranslations.tradeMark.get(selectedLanguage)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
