import React from 'react'
import { Link } from 'react-router-dom'
import { useCurrentUser, useLangStore } from '../../../state/stateManagement'
import { headerTranslations } from '../../../translations/shared/header'
import { EndSessionPopupRequest, IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

function handleSignOut(instance: IPublicClientApplication, language?: string) {
    let logoutRequest: EndSessionPopupRequest = {
        extraQueryParameters: { ui_locales: language ?? 'bg' },
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/'
    }

    return instance.logoutPopup(logoutRequest);
}

export default function SignOutBtn({ className = "btn-login" }) {

    const { instance } = useMsal();
    const { selectedLanguage } = useLangStore();
    const { reset } = useCurrentUser();

    return (
        <Link onClick={() => { handleSignOut(instance).then(reset); }} to="#" className={className}>
            {headerTranslations.signOutBtnText.get(selectedLanguage)}
        </Link>
    )
}
