import { create } from "zustand";
import { PostMultiLanguage } from "../models/PostDetails";
import { getUnapprovedPosts } from "../services/blogPostService";

interface PostsUnapprovedStore {
    unapprovedPosts: PostMultiLanguage[],
    arePostsFetched: boolean,

    removeUnapprovedPost(id: string): void,
    fetchPosts(accessToken: string): Promise<PostMultiLanguage[]>
}

export const useUnapprovedPostsStore = create<PostsUnapprovedStore>((set, get) => ({
    unapprovedPosts: [],
    arePostsFetched: false,

    removeUnapprovedPost: (id) => {
        if (get().unapprovedPosts.find(p => p.id === id))
            set({ unapprovedPosts: [...get().unapprovedPosts.filter(p => p.id !== id)] })
    },
    fetchPosts: async (accessToken) => {

        if (get().arePostsFetched)
            return;

        const response = await getUnapprovedPosts(accessToken)
        set({ unapprovedPosts: response, arePostsFetched: true });

        return response;
    }
}))