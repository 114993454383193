import { create } from 'zustand'
import { languages } from '../translations/supportedLanguages';
import { persist } from 'zustand/middleware'
import { CurrentUser, UnregisteredUser } from '../models/CurrentUser';

// Dark mode store
interface DarkModeStore {
    isDarkModeOn: boolean,
    setDarkMode: (value: boolean) => void,
    toggleDarkMode: () => void
}

export const useDarkModeStore = create<DarkModeStore>()(
    persist(
        (set, get) => ({
            isDarkModeOn: false,
            setDarkMode: (value) => {
                if (get().isDarkModeOn !== value)
                    set({ isDarkModeOn: value })
            },
            toggleDarkMode: () => {
                set((store) => ({ isDarkModeOn: !store.isDarkModeOn }))
            }
        }),
        {
            name: "darkModeToggle",
            getStorage: () => localStorage
        }
    )
)

// Language store
interface LanguageStore {
    selectedLanguage: string,
    setLanguage: (language: string) => void
}

export const useLangStore = create<LanguageStore>()(
    persist(
        (set) => ({
            selectedLanguage: 'bg',
            setLanguage: (language) => {
                if (languages.has(language))
                    set(() => ({ selectedLanguage: language }))
            }
        }),
        {
            name: 'siteLanguage',
            getStorage: () => localStorage,
        }
    )
)

// Loading store
interface LoadingContextType {
    loading: boolean;
    setLoading: (value: boolean) => void;
    loadingFetch: (apiCalls: Promise<void>[]) => Promise<void>
}

export const useLoading = create<LoadingContextType>((set, get) => ({
    loading: false,
    setLoading: (isLoading) => {
        set(() => ({ loading: isLoading }));
    },
    loadingFetch: async (apiCalls: Promise<void>[]) => {
        get().setLoading(true);
        await Promise.all(apiCalls);
        get().setLoading(false);
    }
}));

export const useLoadingFetch = () => {
    const { setLoading } = useLoading();

    return (apiCall: () => Promise<void>) => {
        setLoading(true);
        apiCall().then(() => setLoading(false));
    }
}

// Current user store
interface CurrentUserStore {
    currentUser?: CurrentUser;
    setCurrentUser: (value: CurrentUser) => void;
    reset: () => void;
}


export const useCurrentUser = create<CurrentUserStore>()(
    persist(
        (set) => ({
            setCurrentUser: (user) => {
                set(() => ({ currentUser: user }));
            },
            reset: () => { set(() => ({ currentUser: undefined })) }
        }),
        {
            name: 'currentUser',
            getStorage: () => localStorage,
        })
);

// Comments for unregistered users
interface UnregisteredUserStore {
    user: UnregisteredUser;
    setUser: (value: UnregisteredUser) => void;
}

export const useUnregisteredUser = create<UnregisteredUserStore>()(
    persist(
        (set) => ({
            user: { name: "", email: "" },
            setUser: (user) => {
                set(() => ({ user: user }))
            }
        }),
        {
            name: 'unregistered-user',
            getStorage: () => localStorage,
        }
    )
);

// Sas Tokens Store
interface UserStorageStore {
    getProfileImagesFolderUrl: () => string,
    getPostImagesFolderUrl: () => string,
}

export const useUserStorageStore = create<UserStorageStore>((get, set) => ({
    getProfileImagesFolderUrl: () => { return ""; },
    getPostImagesFolderUrl: () => ("dsa")
}))