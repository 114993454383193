import { BlobClient, ContainerClient } from '@azure/storage-blob';
import { dataURLtoFile } from './fileUtils';

// <snippet_isStorageConfigured>
// Feature flag - disable storage feature to app if not configured
// export const isStorageConfigured = () => {
//   return (!storageAccountName || !sasToken) ? false : true;
// }
// </snippet_isStorageConfigured>

const createBlobInContainer = async (containerClient: ContainerClient, filePath: string, file: File) => {

    // create blobClient for container
    const blobClient = containerClient.getBlockBlobClient(filePath);

    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } };

    // upload file
    await blobClient.uploadData(file, options);

    return blobClient.url.substring(0, blobClient.url.lastIndexOf('?'));
}

export const uploadFileToBlob = (file: File, containerSasUrl: string): Promise<string> => {
    const blobContainerService = new ContainerClient(containerSasUrl);

    // upload file
    return createBlobInContainer(blobContainerService, file.name, file);
};

export const uploadBase64ImageToBlob = (base64Data: string, containerSasUrl: string, fileName: string): Promise<string> => {
    var file = dataURLtoFile(base64Data, fileName)
    return uploadFileToBlob(file, containerSasUrl);
};

export const deleteBlobByUrl = (blobUrl: string, containerSasUrl: string) => deleteBlobByName(new BlobClient(blobUrl).name, containerSasUrl)

export const deleteBlobByName = async (blobName: string, containerSasUrl: string): Promise<void> => {
    const blobContainerService = new ContainerClient(containerSasUrl);
    await blobContainerService.deleteBlob(blobName, { deleteSnapshots: "include" })
}

export const bulkDeleteBlobByName = async (blobUrls: string[], containerSasUrl: string): Promise<void> => {
    const blobContainerService = new ContainerClient(containerSasUrl);
    await Promise.all(blobUrls.map(blobUrl => blobContainerService.deleteBlob(new BlobClient(blobUrl).name, { deleteSnapshots: "include" })));
}