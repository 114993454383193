import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useCurrentUser, useLangStore } from '../../../state/stateManagement';
import { headerTranslations } from '../../../translations/shared/header';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import SignInBtn from './SignInBtn';
import SignOutBtn from './SignOutBtn';
import ProfileImage from './ProfileImage';
import Logo from './Logo';
import NotificationsButton from './NotificationsButton';

export default function Header(
    { isDarkThemeOn, handleThemeChange, openModalClicked }: { isDarkThemeOn: boolean, handleThemeChange(value: boolean): void, openModalClicked(value: boolean): void }) {

    const { selectedLanguage, setLanguage } = useLangStore();

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const [isPostDropdownOpen, setIsPostDropdownOpen] = useState(false);
    const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
    const [isContactDropdownOpen, setIsContactDropdownOpen] = useState(false);
    const [isShopDropdownOpen, setIsShopDropdownOpen] = useState(false);

    const { currentUser } = useCurrentUser();
    const { pathname, search } = useLocation();

    useEffect(() => {
        setIsNavCollapsed(true);
    }, [pathname, search])

    return (
        <header className="header navbar-expand-lg fixed-top">
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="header-area header-padding2">
                            {/* <!--logo--> */}
                            <div className="logo">
                                <Link to="/">
                                    <Logo isWhite={isDarkThemeOn} />
                                </Link>
                            </div>
                            {/* <!--/--> */}
                            <div className="header-navbar">
                                <nav className="navbar">
                                    {/* <!--navbar-collapse--> */}
                                    <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}>
                                        <ul className="navbar-nav">
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/"> {headerTranslations.home.get(selectedLanguage)} </NavLink>
                                            </li>
                                            <li className="nav-item dropdown" onClick={() => setIsCategoryDropdownOpen(!isCategoryDropdownOpen)}>
                                                <NavLink className={`nav-link ${(currentUser?.role === "Admin") && "dropdown-toggle"}`} to="categories"> {headerTranslations.categories.get(selectedLanguage)} </NavLink>
                                                {
                                                    (currentUser?.role === "Admin") &&
                                                    <ul className={`dropdown-menu fade-up ${isCategoryDropdownOpen && "show"}`}>
                                                        <li><Link to={'categories/create'} className='dropdown-item'>{headerTranslations.categoryCreate.get(selectedLanguage)}</Link></li>
                                                    </ul>
                                                }
                                            </li>
                                            <li className="nav-item dropdown" onClick={() => setIsPostDropdownOpen(!isPostDropdownOpen)}>
                                                <NavLink
                                                    className={`nav-link ${(currentUser?.role === "Admin" || currentUser?.role === "Author") && "dropdown-toggle"}`}
                                                    to="posts"> {headerTranslations.posts.get(selectedLanguage)} </NavLink>
                                                {
                                                    (currentUser?.role === "Admin" || currentUser?.role === "Author") &&
                                                    <ul className={`dropdown-menu fade-up ${isPostDropdownOpen && "show"}`}>
                                                        <li><Link to={'posts/create'} className='dropdown-item'>{headerTranslations.postCreate.get(selectedLanguage)}</Link></li>
                                                    </ul>
                                                }
                                            </li>
                                            <li className="nav-item dropdown">
                                                <NavLink
                                                    className={`nav-link ${(currentUser?.role === "Member") && "dropdown-toggle"}`}
                                                    to="authors"
                                                > {headerTranslations.authors.get(selectedLanguage)} </NavLink>
                                                {
                                                    (currentUser?.role === "Member") &&
                                                    <ul className={`dropdown-menu fade-up ${isContactDropdownOpen && "show"}`}>
                                                        <li><Link to={'inqueries/become-author'} className='dropdown-item'>{headerTranslations.becomeAuthor.get(selectedLanguage)}</Link></li>
                                                    </ul>
                                                }
                                            </li>
                                            {process.env.REACT_APP_SHOW_SHOP && <li className="nav-item dropdown" onClick={() => setIsShopDropdownOpen(!isShopDropdownOpen)}>
                                                <NavLink
                                                    className={`nav-link ${(currentUser?.role === "Author" || currentUser?.role === "Admin") && "dropdown-toggle"}`}
                                                    to="shop"> {headerTranslations.shop.get(selectedLanguage)} </NavLink>
                                                {
                                                    (currentUser?.role === "Author" || currentUser?.role === "Admin") &&
                                                    <ul className={`dropdown-menu fade-up ${isShopDropdownOpen && "show"}`}>
                                                        <li>
                                                            <Link to={'products/create'} className='dropdown-item'>{headerTranslations.createProduct.get(selectedLanguage)}</Link>
                                                        </li>
                                                    </ul>
                                                }
                                            </li>}
                                            <li className="nav-item">
                                                <NavLink className='nav-link' to="about"> {headerTranslations.about.get(selectedLanguage)} </NavLink>
                                            </li>
                                            <li className="nav-item dropdown" onClick={() => setIsContactDropdownOpen(!isContactDropdownOpen)}>
                                                <NavLink
                                                    className={`nav-link ${(currentUser?.role === "Admin") && "dropdown-toggle"}`}
                                                    to="contact"> {headerTranslations.contact.get(selectedLanguage)} </NavLink>
                                                {
                                                    (currentUser?.role === "Admin") &&
                                                    <ul className={`dropdown-menu fade-up ${isContactDropdownOpen && "show"}`}>
                                                        <li><Link to={'inqueries'} className='dropdown-item'>{headerTranslations.inqueries.get(selectedLanguage)}</Link></li>
                                                        <li><Link to={'users'} className='dropdown-item'>{headerTranslations.users.get(selectedLanguage)}</Link></li>
                                                        <li><Link to={'authors/promote-trusted'} className='dropdown-item'>{headerTranslations.authors.get(selectedLanguage)}</Link></li>
                                                        <li><Link to={'posts/unapproved'} className='dropdown-item'>{headerTranslations.unapprovedPosts.get(selectedLanguage)}</Link></li>
                                                    </ul>
                                                }
                                            </li>
                                            <AuthenticatedTemplate>
                                                <li className="nav-item d-lg-none d-md-none">
                                                    <SignOutBtn className='nav-link' />
                                                </li>
                                            </AuthenticatedTemplate>
                                        </ul>
                                    </div>
                                    {/* <!--/--> */}
                                </nav>
                            </div>

                            {/* <!--header-right--> */}
                            <div className="header-right">

                                <div className='language-switch-wrapper' onClick={() => { selectedLanguage === 'en' ? setLanguage('bg') : setLanguage('en') }}>
                                    <span className='language-switch'>
                                        {selectedLanguage}
                                    </span>
                                </div>

                                {/* <!--theme-switch--> */}
                                <div className="theme-switch-wrapper">
                                    <label className="theme-switch" htmlFor="checkbox">
                                        <span className="slider round" onClick={_ => handleThemeChange(!isDarkThemeOn)}>
                                            {
                                                <>
                                                    <i className={`lar la-sun ${isDarkThemeOn ? 'd-none' : ''}`}></i>
                                                    <i className={`lar la-moon ${isDarkThemeOn ? '' : 'd-none'}`}></i>
                                                </>
                                            }
                                        </span>
                                    </label>
                                </div>

                                {/* <!--search-icon--> */}
                                <div className="search-icon" onClick={e => openModalClicked(true)}>
                                    <i className="las la-search"></i>
                                </div>

                                {/* <!--notifications-icon--> */}
                                {currentUser && <NotificationsButton />}

                                {/* <!--navbar-toggler--> */}
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={() => setIsNavCollapsed(!isNavCollapsed)}>
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                {/* <!--/--> */}
                            </div>

                            <AuthenticatedTemplate>
                                <div className='profile-sub'>
                                    <ProfileImage />
                                </div>
                                <div className="button-sub d-none d-lg-block d-md-block">
                                    <SignOutBtn />
                                </div>
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <div className="button-sub">
                                    <SignInBtn />
                                </div>
                            </UnauthenticatedTemplate>
                        </div>
                    </div>
                </div>
            </div >
        </header >
    )

}
