import { scopes } from "../config/authConfig";
import { authorProfileImagesFolder, postImagesFolder, productImagesFolder } from "../config/config";
import { bulkDeleteBlobByName, uploadBase64ImageToBlob, uploadFileToBlob } from "../utils/azure-blob-storage";
import { useFetchWithTokenWithResult } from "../utils/useADAuth";
import { getCategoriesContainerSasUrl, getUserContainerSasUrl } from "./sasTokenService";

export interface ImageDto {
    imageData: string,
    name: string
}

export const useCurrentUserAzureFileUploader = () => {
    const fetchWithToken = useFetchWithTokenWithResult();

    const uploadFile = (image: ImageDto): Promise<string> => fetchWithToken([scopes.user_impersonation],
        async (accessToken) => {
            const sasUrl = await getUserContainerSasUrl(accessToken);
            return await uploadBase64ImageToBlob(image.imageData, sasUrl, image.name)
        });

    const uploadFiles = (images: ImageDto[]): Promise<string[]> => fetchWithToken([scopes.user_impersonation],
        async (accessToken) => {
            const sasUrl = await getUserContainerSasUrl(accessToken);
            const uploadFilePromises = images.map(img => uploadBase64ImageToBlob(img.imageData, sasUrl, img.name));

            return await Promise.all(uploadFilePromises);
        });

    const uploadFileBlob = (fileData: File): Promise<string> => fetchWithToken([scopes.user_impersonation],
        async (accessToken: string) => {
            const sasUrl = await getUserContainerSasUrl(accessToken);
            const imageUrl = await uploadFileToBlob(fileData, sasUrl);
            return imageUrl;
        });

    const deleteFileBlobs = (blobUrls: string[]): Promise<void> => fetchWithToken([scopes.user_impersonation],
        async (accessToken: string) => {
            const postsFolderSasUrl = await getUserContainerSasUrl(accessToken);
            await bulkDeleteBlobByName(blobUrls, postsFolderSasUrl);
        });

    const uploadProfileImage = (imageData: string, fileName: string) => uploadFile({ imageData: imageData, name: `${authorProfileImagesFolder}/${fileName}` });
    const uploadPostImage = (imageData: string, fileName: string) => uploadFile({ imageData: imageData, name: `${postImagesFolder}/${fileName}` });
    const uploadProductImages = (images: ImageDto[]) => uploadFiles(images.map(img => ({ imageData: img.imageData, name: `${productImagesFolder}/${img.name}` })));

    return {
        uploadFileBlob: uploadFileBlob,
        uploadProfileImage: uploadProfileImage,
        uploadPostImage: uploadPostImage,
        uploadProductImages: uploadProductImages,
        deleteImages: deleteFileBlobs
    }
}

export const useCategoryImagesUploader = () => {
    const fetchWithToken = useFetchWithTokenWithResult();

    const uploadFile = (imageData: string, relativePath: string): Promise<string> => fetchWithToken([scopes.openId, scopes.posts.write],
        async (accessToken) => {
            const sasUrl = await getCategoriesContainerSasUrl(accessToken);
            return await uploadBase64ImageToBlob(imageData, sasUrl, relativePath)
        });

    const uploadCategoryImage = (imageData: string, categoryName: string, fileName: string) => uploadFile(imageData, `${categoryName}/${fileName}`);

    return {
        uploadCategoryImage: uploadCategoryImage
    }
}