import React from 'react'
import { Link } from 'react-router-dom'
import { LocalisedPost } from '../../../models/PostDetails'

export default function SingleFilteredPost({ post }: { post: LocalisedPost }) {
    return (
        <div className="post-list post-list-style1 ">
            <div className="post-list-image">
                <Link to={`/posts/${post.id}`}>
                    <img src={post.images?.length ? post.images[0].url : post.category.imgUrl} alt="" />
                </Link>
            </div>
            <div className="post-list-title">
                <div className="entry-title">
                    <h5>
                        <Link to={`/posts/${post.id}`}>{post.title}</Link>
                    </h5>
                </div>
            </div>
            <div className="post-list-category">
                <div className="entry-cat">
                    <Link to={`/posts?category=${post.category.name}`} className="category-style-1">{post.category.name}</Link>
                </div>
            </div>
        </div>
    )
}
