import React from 'react'
import { usePagination, DOTS } from './usePagination';
import { Link } from 'react-router-dom';
import { IPagerOptions } from '../../models/IPagenable';

const addPageNumberAsSearchParam = (pageNumber: number, pageSize: number, currentSearchParams?: URLSearchParams) => {
    if (!currentSearchParams)
        return new URLSearchParams({ page: `${pageNumber}`, pageSize: `${pageSize}` });

    const newParams = new URLSearchParams(currentSearchParams);

    if (newParams.has("page")) newParams.delete('page')
    if (newParams.has("pageSize")) newParams.delete('pageSize')
    if (newParams.get('categoryId') && window.location.href.includes(newParams.get('categoryId')!)) newParams.delete('categoryId')
    if (newParams.get('authorId') && window.location.href.includes(newParams.get('authorId')!)) newParams.delete('authorId')

    newParams.set("page", `${pageNumber}`)
    newParams.set("pageSize", `${pageSize}`)

    return newParams;
}

function Pagination({
    onPageChange,
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
    className,
    queryParams
}: IPagerOptions) {

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    const onNext = () => {
        onPageChange!(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange!(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange!.length - 1] ?? 1;

    return (
        <div className="pagination">
            <div className="pagination-area text-left">
                <div className="pagination-list">
                    <ul className={`pagination-container ${className} `}>
                        <li
                            className={`pagination-item`}
                            onClick={() => { if (currentPage !== 1) onPrevious() }}
                        >
                            <Link
                                className={`${currentPage === 1 ? "disabled" : ""}`}
                                to={"?" + addPageNumberAsSearchParam(currentPage - 1, pageSize, queryParams).toString()}>
                                <i className="las la-arrow-left"></i>
                            </Link>
                        </li>
                        {paginationRange!.map(pageNumber => {
                            if (pageNumber === DOTS) {
                                return <li key={pageNumber} className="pagination-item dots">&#8230;</li>
                            }
                            return (
                                <li key={pageNumber}
                                    className={`pagination-item ${pageNumber === currentPage ? "selected" : ""}`}
                                    onClick={() => onPageChange!(pageNumber as number)}
                                >
                                    <Link to={"?" + addPageNumberAsSearchParam(Number(pageNumber), pageSize, queryParams).toString()} className={pageNumber === currentPage ? 'active' : ''}>{pageNumber}</Link>
                                </li>
                            );
                        })}
                        <li
                            className={`pagination-item`}
                            onClick={() => { if (currentPage === lastPage) onNext() }}
                        >
                            <Link
                                className={`${currentPage === lastPage ? "disabled" : ""}`}
                                to={"?" + addPageNumberAsSearchParam(Number(currentPage + 1), pageSize, queryParams).toString()}>
                                <i className="las la-arrow-right"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div >
    );
}

export default Pagination;
