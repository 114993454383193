import { ImageResize } from "quill-image-resize-module-ts";
import { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";

export function SetupQuillEditor() {

    // Add sizes to whitelist and register them
    const Size = Quill.import("formats/size");
    Size.whitelist = ["small", "medium", "large"];
    Quill.register(Size, true);

    // Add fonts to whitelist and register them
    const Font = Quill.import("formats/font");
    Font.whitelist = [
        "roboto",
        "raleway",
        "monserat",
        "lato",
        "rubik",
        "poor-story",
        "griffy"
    ];
    Quill.register(Font, true);

    Quill.register('modules/imageResize', ImageResize);

    Quill.register("modules/imageUploader", ImageUploader);
}