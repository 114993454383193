export const homePageTranslations = {
    pageTitle: new Map([["en", "Home"], ["bg", "Начало"]]),
    pageDescription: new Map([["en", "Web site for sharing ideas, information and know-how about wide variaty of categories of any topics"], ["bg", "Страница за споделяне на идеи, информация и опит по-голямо разнообразие от теми и категории"]])
}

export const mainPostItem = {
    readMore: new Map([["en", "Read More"], ["bg", "Виж още"]]),
}

export const popularPostListSection = {
    heading: new Map([["en", "Popular Articles"], ["bg", "Полулярни статии"]]),
    subheading: new Map([["en", "Discover the most outstanding articles in all topics."], ["bg", "Разгледайте най-популярните статии от всички теми."]]),
    minsRead: new Map([["en", "Mins Read"], ["bg", "Минути"]]),
}

export const mostCommentedPostListSection = {
    heading: new Map([["en", "Most Commented Articles"], ["bg", "Най-коментираните статии"]]),
    subheading: new Map([["en", "Discover the most talked about articles in all topics."], ["bg", "Разгледайте най-коментираните статии от всички теми."]]),
}

export const recentPostListSection = {
    heading: new Map([["en", "Most Recent Articles"], ["bg", "Най-нови статии"]]),
    subheading: new Map([["en", "Discover the most recent articles in all topics."], ["bg", "Разгледайте най-новите статии от всички теми."]]),
}