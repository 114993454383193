import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useCurrentUser, useLangStore, useLoading } from '../../../state/stateManagement'
import ContactImage from '../../../assets/img/other/paper-pencil-author.webp'
import { becomeAuthorTranslations, createInqueryTranslations } from '../../../translations/contact/contactform';
import { genericTranslations } from '../../../translations/generic/inputTooltip';
import { isNotOnlyWhiteSpaces } from '../../../utils/predicates';
import CenterHeading from '../../PageHeading/CenterHeading'
import Heading from '../../PageHeading/Heading'
import { createBecomeAuthorInquery } from '../../../services/contactService';
import { CreateInquery, InqueryType } from '../../../models/Inqueries';
import { useInqueriesStore } from '../../../state/inqueries';
import { useFetchWithToken } from '../../../utils/useADAuth';
import { scopes } from '../../../config/authConfig';


export default function BecomeAuthorPage() {

    const { setLoading } = useLoading()
    const selectedLanguage = useLangStore(store => store.selectedLanguage);
    const currentUser = useCurrentUser(c => c.currentUser);
    const { register, handleSubmit, setValue, reset, formState: { errors, dirtyFields, isValid } } = useForm<CreateInquery>({ mode: 'onChange' });
    const fetchWithToken = useFetchWithToken()
    const { becomeAuthorInquery, fetchBecomeAuthorInquery } = useInqueriesStore();

    useEffect(() => {
        setLoading(true);
        fetchWithToken([scopes.user_impersonation], fetchBecomeAuthorInquery).then(() => setLoading(false));
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <CenterHeading headingContent={
                <Heading heading={becomeAuthorTranslations.heading.get(selectedLanguage)!} segment={becomeAuthorTranslations.segment.get(selectedLanguage)!} />
            } />

            <section className="contact">
                <div className="container">
                    <div className="contact-area">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="contact-image">
                                    <img src={ContactImage} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                {becomeAuthorInquery &&
                                    <div className="contact-info">
                                        <h3>{becomeAuthorTranslations.inqueryInProgress.get(selectedLanguage)}</h3>
                                        <p>{becomeAuthorInquery.description}</p>
                                    </div>
                                }
                                {!becomeAuthorInquery && <>
                                    <div className="contact-info">
                                        <h3>{becomeAuthorTranslations.formHeading.get(selectedLanguage)}</h3>
                                        <p>{becomeAuthorTranslations.formDetails.get(selectedLanguage)}</p>
                                    </div>
                                    <form
                                        onSubmit={
                                            handleSubmit(data => {
                                                if (errors.description || !currentUser || !currentUser.email)
                                                    return;

                                                data.email = currentUser.email;
                                                data.name = currentUser.name;
                                                data.subject = becomeAuthorTranslations.inquerySubject.get(selectedLanguage)!;
                                                data.type = InqueryType.BecomeAuthor;

                                                toast.promise(
                                                    fetchWithToken([scopes.user_impersonation],
                                                        async (accessToken) => {
                                                            const inqueryId = await createBecomeAuthorInquery(accessToken, data)
                                                            reset();
                                                            inqueryId !== null
                                                                ? toast.success(createInqueryTranslations.toastMessages.get(selectedLanguage)!.success?.toString())
                                                                : toast.success(createInqueryTranslations.alreadySubmited.get(selectedLanguage));

                                                            await fetchBecomeAuthorInquery(accessToken);
                                                        }),
                                                    {
                                                        error: createInqueryTranslations.toastMessages.get(selectedLanguage)!.error,
                                                        pending: createInqueryTranslations.toastMessages.get(selectedLanguage)!.pending
                                                    }
                                                );
                                            })
                                        }
                                        className="form contact_form"
                                        id="main_contact_form"
                                    >
                                        <div className="form-group">
                                            <textarea
                                                title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                                {...register('description', { required: true, validate: isNotOnlyWhiteSpaces })}
                                                onChange={e => { setValue('description', e.target.value, { shouldValidate: true, shouldDirty: true }) }}
                                                cols={30} rows={5}
                                                className={`form-control ${!dirtyFields.description ? "" : errors.description ? "border-danger" : "border-success"}`}
                                                placeholder={becomeAuthorTranslations.formMessageInputPlaceholder.get(selectedLanguage)}>
                                            </textarea>
                                        </div>

                                        <button
                                            type="submit"
                                            name="submit"
                                            disabled={!isValid}
                                            className={`btn-custom ${isValid ? "" : "disabled"}`}>
                                            {becomeAuthorTranslations.formSubmitBtnText.get(selectedLanguage)}
                                        </button>
                                    </form>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}