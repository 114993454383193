import React, { useEffect, useState } from 'react'
import { Dropdown } from '../Shared/Dropdown'
import { useLangStore, useLoading } from '../../state/stateManagement';
import 'react-quill/dist/quill.snow.css';
import MainEditor from '../ContentEditorWrapper/MainEditor';
import SmallEditor from '../ContentEditorWrapper/SmallEditor';
import { postCreatePageTranslations } from '../../translations/posts/postCreatePage';
import TagsArea from './PostCreatePage/TagsArea';
import { useCategoriesStore } from '../../state/categoriesStore';
import { useTagsStore } from '../../state/tagsStore';
import { useForm } from 'react-hook-form';
import { useFetchWithTokenWithResult } from '../../utils/useADAuth';
import { scopes } from '../../config/authConfig';
import { createPost } from '../../services/blogPostService';
import { useNavigate } from 'react-router-dom';
import ImageEditable from '../Shared/ImageEditable';
import { BlogImage } from '../../models/Image';
import { useCurrentUserAzureFileUploader } from '../../services/fileUploaderService';
import { CreatePostForm, CreatePostRequest } from '../../models/PostDetails';
import { isNotOnlyWhiteSpaces } from '../../utils/predicates';
import { usePostCreateDraftStore } from '../../state/postDraftStore';
import { toLocalisedCategory } from '../../utils/mappers/categoryMappers';

export default function PostCreatePage() {
    // Loader
    const { setLoading, loadingFetch } = useLoading();

    //Redirect when post is created successfully
    const navigate = useNavigate();

    // file uploader
    const { uploadPostImage } = useCurrentUserAzureFileUploader();

    //fetch with access token
    const fetchWithToken = useFetchWithTokenWithResult();

    // Language
    const selectedLanguage = useLangStore(store => store.selectedLanguage);

    // Lookups
    const { categories, fetchCategories } = useCategoriesStore(store => ({ categories: store.categories.map(c => toLocalisedCategory(c, selectedLanguage)), fetchCategories: store.fetchCategories }));
    const { predefinedTags, fetchTags } = useTagsStore(store => ({ predefinedTags: store.tags.map(t => ({ id: t.name, text: t.name })), fetchTags: store.fetchTags }));

    // Draft
    const { draft, editDraft, clearDraft } = usePostCreateDraftStore();

    // Form
    const { register, handleSubmit, setValue, getValues, setError, formState: { errors, dirtyFields, isDirty, isValid } } = useForm<CreatePostForm>({ mode: "onChange", reValidateMode: "onChange" });

    // Preview image (after edited in the Image editor)
    const [editedImageData, setEditedImageData] = useState<BlogImage>();

    useEffect(() => {
        register('content', { required: true, validate: isNotOnlyWhiteSpaces });
        register('categoryId', { required: true, validate: isNotOnlyWhiteSpaces });

        loadingFetch([fetchCategories(), fetchTags()]);

        if (draft) {
            draft.title && setValue('title', draft.title, { shouldDirty: true, shouldValidate: true });
            draft.categoryId && setValue('categoryId', draft.categoryId, { shouldDirty: true, shouldValidate: true });
            draft.readTimeInMinutes && setValue('timeToRead', draft.readTimeInMinutes, { shouldDirty: true, shouldValidate: true });
            draft.contentPreview && setValue('previewContent', draft.contentPreview, { shouldDirty: true, shouldValidate: true });
            draft.content && setValue('content', draft.content, { shouldDirty: true, shouldValidate: true });
            draft.tags && setValue('tags', draft.tags, { shouldDirty: true, shouldValidate: true });
            // TODO: bring the image out of the draft
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <section className='post-create'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-9 col-md-12 m-auto'>
                        <div className='post-content'>
                            <h2>{postCreatePageTranslations.createPostHeading.get(selectedLanguage)}</h2>
                            <form onSubmit={
                                handleSubmit(async (data) => {
                                    setLoading(true);

                                    const request: CreatePostRequest = {
                                        categoryId: data.categoryId,
                                        title: data.title,
                                        timeToRead: Number(data.timeToRead),
                                        tags: data.tags ? data.tags.map(t => t.toLowerCase()) : [],
                                        previewContent: data.previewContent,
                                        content: data.content,
                                        imageUrl: null
                                    }

                                    if (editedImageData) {
                                        request.imageUrl = await uploadPostImage(editedImageData.urlOrBase64, editedImageData.name)
                                    }

                                    const postId = await fetchWithToken([scopes.openId, scopes.posts.write],
                                        (accessToken) => createPost(accessToken, request))

                                    clearDraft();

                                    // Redirect to post
                                    navigate(`/posts/${postId}`);
                                })}
                                className='create-post-form widget-form'
                            >
                                <div className='form-group image-container'>
                                    <ImageEditable imageData={editedImageData} onSave={(image) => setEditedImageData(image)} />
                                </div>
                                <div className='form-group'>
                                    <input
                                        {...register('title', { required: true, minLength: 10, validate: isNotOnlyWhiteSpaces })}
                                        onChange={(e => {
                                            setValue('title', e.target.value, { shouldValidate: true, shouldDirty: true });
                                            editDraft({ title: e.target.value });
                                        })}
                                        placeholder={postCreatePageTranslations.postTitlePlaceholder.get(selectedLanguage)}
                                        className={`form-control ${!dirtyFields.title ? "" : errors.title ? "border-danger" : "border-success"}`}
                                    />
                                </div>
                                <div className='form-group row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <Dropdown
                                            value={getValues('categoryId')}
                                            label={"Category"}
                                            placeholderElement={postCreatePageTranslations.categorySelectDefault.get(selectedLanguage)}
                                            options={categories.map<LookupItem>(c => ({ id: c.id, name: c.name }))}
                                            onChange={(e) => {
                                                setValue("categoryId", e.target.value, { shouldValidate: true, shouldDirty: true })
                                                editDraft({ categoryId: e.target.value });
                                            }}
                                            className={`form-control ${!dirtyFields.categoryId ? "" : errors.categoryId ? "border-danger" : "border-success"}`}
                                        />
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <input
                                            {...register('timeToRead', { valueAsNumber: true, required: true, validate: (value => value > 0) })}
                                            onChange={e => { editDraft({ readTimeInMinutes: Number(e.target.value) }); }}
                                            type="number"
                                            placeholder={postCreatePageTranslations.timeToReadPlaceholder.get(selectedLanguage)}
                                            className={`form-control ${!dirtyFields.timeToRead ? "" : errors.timeToRead ? "border-danger" : "border-success"}`}
                                        />
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <SmallEditor
                                        initValue={getValues("previewContent")}
                                        onChange={(content) => {
                                            setValue('previewContent', content);
                                            editDraft({ contentPreview: content });
                                        }}
                                        placeholder={postCreatePageTranslations.postPreviewContentPlaceholder.get(selectedLanguage)}
                                        maxContentLenght={200}
                                    />
                                </div>
                                <div className='form-group'>
                                    <MainEditor
                                        initValue={getValues("content")}
                                        onChange={(content) => {
                                            if (content === '<p><br></p>' || /^<p>\s*<[/]p>$/.test(content)) {
                                                setValue('content', content);
                                                setError('content', { type: 'required' })
                                            }
                                            else {
                                                setValue('content', content, { shouldValidate: true, shouldDirty: true });
                                                editDraft({ content: content });
                                            }
                                        }}
                                        placeholder={postCreatePageTranslations.postContentPlaceholder.get(selectedLanguage)}
                                        className={!dirtyFields.content ? "" : errors.content ? "border border-danger" : "border border-success"}
                                    />
                                </div>
                                <div className='form-group'>
                                    <TagsArea
                                        tags={getValues("tags")}
                                        onTagAdded={alltags => {
                                            setValue("tags", alltags, { shouldValidate: true })
                                            editDraft({ tags: alltags });
                                        }}
                                        language={selectedLanguage}
                                        suggestions={predefinedTags}
                                    />
                                </div>
                                <button type="submit" name="submit" className={`btn-custom ${!isDirty || !isValid ? "disabled" : ""}`} disabled={!isDirty || !isValid}>
                                    {postCreatePageTranslations.sendBtnText.get(selectedLanguage)}
                                </button>
                                <button className={`btn-custom ml-1`} onClick={() => { navigate(-1); }}>
                                    {postCreatePageTranslations.cancelBtnText.get(selectedLanguage)}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
