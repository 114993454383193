import { ChangeEventHandler } from "react";

export const Dropdown = (
    { label, placeholderElement, value, options, onChange, className }:
        { label: string, placeholderElement?: string, value?: string, options: LookupItem[], onChange: ChangeEventHandler<HTMLSelectElement>, className?: string }
) => {
    return (
        <select aria-label={label} className={className} value={value} onChange={onChange}>
            {placeholderElement && <option value={''}>{placeholderElement}</option>}
            {options.map((option) => (
                <option key={option.id} value={option.id}>{option.name}</option>
            ))}
        </select>
    );
};