import { faCheckCircle, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { scopes } from '../../config/authConfig';
import { changeUserRole } from '../../services/userService';
import { useAuthorsStore } from '../../state/authorsStore';
import { useInqueriesStore } from '../../state/inqueries';
import { useLangStore, useLoading } from '../../state/stateManagement';
import { useYesNoDialogStore } from '../../state/yesNoDialogStore';
import { becomeAuthorApproveTranslations, inquerySingleTranslations } from '../../translations/inqueries/inqueries';
import { formatToBlogDate } from '../../utils/dateFormatter';
import { toLocalisedAuthor } from '../../utils/mappers/authorMappers';
import { FormatString } from '../../utils/stringFormatter';
import { useFetchWithTokenWithResult } from '../../utils/useADAuth';
import YesNoDialog from '../Modals/Dialogs/YesNoDialog';

export default function InquerySinglePage() {

    const { inqueryId } = useParams<string>();
    const setLoading = useLoading(store => store.setLoading);
    const selectedLanguage = useLangStore(store => store.selectedLanguage);
    const fetchWithToken = useFetchWithTokenWithResult();
    const { selectedInquery, fetchInquery, deleteInquery } = useInqueriesStore(store => ({
        selectedInquery: store.inqueries.find(i => i.id === inqueryId),
        fetchInquery: store.fetchSingleInquery,
        deleteInquery: store.deleteInquery
    }));

    const { selectByEmail, localisedSelectedAuthor, patchAuthor } = useAuthorsStore(store => ({
        selectByEmail: store.selectAuthorByEmail,
        patchAuthor: store.patchAuthor,
        localisedSelectedAuthor: store.authors.find(a => a.id === store.selectedAuthorId) && toLocalisedAuthor(store.authors.find(a => a.id === store.selectedAuthorId)!, selectedLanguage)
    }))

    const { openDialog } = useYesNoDialogStore();

    useEffect(() => {
        setLoading(true);
        fetchWithToken([scopes.user_impersonation], token => fetchInquery(token, inqueryId!)).then(() => setLoading(false))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedInquery?.type === 'BecomeAuthor' && selectedInquery?.personEmail)
            selectByEmail(selectedInquery.personEmail)
    }, [selectedInquery]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {selectedInquery &&
                <div className='container single-inquery-wrapper'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h1 className='inquery-subject'>{selectedInquery.subject}</h1>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 className='inquery-author'>{selectedInquery.personName} ({selectedInquery.personEmail})</h3>
                        </div>
                    </div>
                    <div className='row mt-3 mb-4'>
                        <div className='offset-lg-2 col-lg-8 offset-md-3 col-md-6 col-sm-12'>
                            <p>
                                {selectedInquery.description}
                            </p>
                        </div>
                        <div className='col-md-2 col-sm-12'>
                            <div className='inquery-actions'>
                                {
                                    selectedInquery.type === 'BecomeAuthor' && localisedSelectedAuthor &&
                                    (localisedSelectedAuthor.role === 'Author'
                                        ? <span > <FontAwesomeIcon className='approve-become-author text-success' icon={faCheckCircle} /> </span>
                                        : <span className='action-btn' onClick={() => {
                                            openDialog(
                                                {
                                                    title: FormatString(becomeAuthorApproveTranslations.makeAuthorDialogTitle.get(selectedLanguage)!, localisedSelectedAuthor.name),
                                                    description: FormatString(becomeAuthorApproveTranslations.makeAuthorDialogBody.get(selectedLanguage)!, localisedSelectedAuthor.name),
                                                    yesBtnText: becomeAuthorApproveTranslations.yesBtnText.get(selectedLanguage),
                                                    noBtnText: becomeAuthorApproveTranslations.noBtnText.get(selectedLanguage)
                                                },
                                                () => fetchWithToken([scopes.user_impersonation],
                                                    async (token) => {
                                                        await changeUserRole(token, { userId: localisedSelectedAuthor.id, role: 'Author' })
                                                        patchAuthor(localisedSelectedAuthor.id, { role: 'Author' })
                                                    }

                                                )
                                            )
                                        }
                                        }
                                        >
                                            <FontAwesomeIcon className='approve-become-author' icon={faCheckCircle} />
                                        </span>
                                    )
                                }
                                <span className='action-btn' onClick={() => {
                                    openDialog(
                                        {
                                            title: inquerySingleTranslations.deleteDialogTitle.get(selectedLanguage)!,
                                            description: inquerySingleTranslations.deleteDialogBody.get(selectedLanguage),
                                            yesBtnText: inquerySingleTranslations.yesBtnText.get(selectedLanguage),
                                            noBtnText: inquerySingleTranslations.noBtnText.get(selectedLanguage)
                                        },
                                        () => fetchWithToken([scopes.user_impersonation], (token) => deleteInquery(token, selectedInquery.id))
                                    )
                                }}>
                                    <FontAwesomeIcon className='inquery-delete' icon={faTrashCan} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2 mb-5'>
                        <div className='offset-lg-2 col-lg-8 offset-md-3 col-md-6 col-sm-12'>
                            <span>{formatToBlogDate(new Date(selectedInquery.createdOn), selectedLanguage)}</span>
                        </div>
                    </div>
                </div>
            }

            <YesNoDialog />
        </>
    )
}
