import { Helmet } from 'react-helmet-async'

export interface SiteSEO {
    title: string,
    description: string,
    localisation: "en_US" | "bg_BG"
    ogImage?: string
}

export function getLocalisation(language: string) {
    return language.toLowerCase() === 'en' ? "en_US" : "bg_BG";
}

export default function SEO({ description, title, localisation, ogImage }: SiteSEO) {
    return (
        <Helmet
            title={title}
            titleTemplate={title ? `%s | Start Your Post` : 'Start Your Post'}
            link={[{ rel: 'canonical', href: window.location.href }]}
            meta={[
                {
                    name: `description`,
                    content: description,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `og:url`,
                    content: window.location.href,
                },
                {
                    property: `og:image`,
                    content: ogImage,
                },
                {
                    property: `og:locale`,
                    content: localisation ?? "bg_BG",
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: description,
                },
            ]}
        />
    )
}