import { toast } from "react-toastify";
import { blogApiConfig } from "../config/config";
import { httpStatusTranslations } from "../translations/generic/httpstatuses";

async function handleHttpErrors(response: Response): Promise<Response> {
    if (!response.ok) {
        var responseJson = await response.json();
        const language = 'bg';

        let errorMessage: string;
        switch (response.status) {
            case 401:
            case 403:
                errorMessage = httpStatusTranslations.notAuthorized.get(language)!
                break;
            case 404:
                errorMessage = httpStatusTranslations.notFound.get(language)!
                break;
            case 400:
                errorMessage = httpStatusTranslations.badRequest.get(language)!
                break;
            default:
                errorMessage = httpStatusTranslations.serverError.get(language)!
                break;
        }

        toast.error(errorMessage);

        throw new Error(responseJson.message);
    }
    return response;
}

const blogFetch = async (apiEndpoint: string, method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE", headersInit?: HeadersInit, accessToken?: string, body?: any) => {

    const headers = new Headers(headersInit);
    if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
    }

    const response = await fetch(blogApiConfig.baseUrl + apiEndpoint, { headers: headers, method: method, body: body ? JSON.stringify(body) : undefined });

    await handleHttpErrors(response);

    return response.status !== 204 && response.status !== 202
        ? await response.json()
        : null;
}

export const getWithToken = (accessToken: string, apiEndpoint: string, headers?: HeadersInit) => blogFetch(apiEndpoint, "GET", headers, accessToken)

export const get = (apiEndpoint: string, headers?: HeadersInit) => blogFetch(apiEndpoint, "GET", headers)

export const postWithToken = (accessToken: string, apiEndpoint: string, body: any, headers?: HeadersInit) => blogFetch(apiEndpoint, "POST", headers, accessToken, body)

export const post = (apiEndpoint: string, body: any, headers?: HeadersInit) => blogFetch(apiEndpoint, "POST", headers, undefined, body)

export const deleteRequest = (apiEndpoint: string, body?: any, headers?: HeadersInit) => blogFetch(apiEndpoint, "DELETE", headers, undefined, body);

export const deleteRequestWithToken = (accessToken: string, apiEndpoint: string, body?: any, headers?: HeadersInit) => blogFetch(apiEndpoint, "DELETE", headers, accessToken, body);

export const patchWithToken = (accessToken: string, apiEndpoint: string, body: any, headers?: HeadersInit) => blogFetch(apiEndpoint, "PATCH", headers, accessToken, body);

export const patch = (apiEndpoint: string, body: any, headers?: HeadersInit) => blogFetch(apiEndpoint, "PATCH", headers, undefined, body);