import { create } from "zustand";
import produce from "immer";
import { Product } from "../../models/Shop/Product";
import { getProduct, getProducts, getProductsCount, ProductSearchParams } from "../../services/shop/productService";
import { deepEqual } from "../../utils/equality";
import { getReviewsByPostId as getReviewsByProductId } from "../../services/shop/reviewService";

export const productsCountSearchParamSelector = (store: ProductStore, params: ProductSearchParams) => {
    const productsCount = store.loadedProductCountsForSearchParams.find((kv) => {
        return deepEqual(params, kv.params)
    })?.productsCount

    return productsCount
}

export interface ProductStore {
    selectedProductId?: string,

    areProductsFetched: boolean,
    products: Product[],

    loadedProductsForSearchParamsMap: Map<ProductSearchParams, string[]>,
    loadedProductCountsForSearchParams: { params: ProductSearchParams, productsCount: number }[],

    selectProduct: (id: string) => Promise<void>
    fetchProducts: (searchParams: ProductSearchParams) => Promise<void>,
    fetchProductsCount: (params: ProductSearchParams) => Promise<void>,

    fetchReviews: (productId: string) => Promise<void>,

    addProductItem: (product: Product) => void
}

export const useProductStore = create<ProductStore>((set, get) => ({
    products: [],
    areProductsFetched: false,

    loadedProductsForSearchParamsMap: new Map<ProductSearchParams, string[]>(),
    loadedProductCountsForSearchParams: [],

    selectProduct: async (id) => {
        let product = get().products.find(p => p.id === id);

        if (!product) {
            product = await getProduct(id);
            get().addProductItem(product);
        }

        set(() => ({ selectedProductId: product!.id }));
    },
    fetchReviews: async (productId) => {
        if (get().products.find(p => p.id === productId)?.reviews) return;

        const reviews = getReviewsByProductId(productId);
        if (!reviews.length) return;

        let productToAddReviewsTo = get().products.find(p => p.id === productId);
        if (!productToAddReviewsTo) return;

        productToAddReviewsTo.reviews = reviews;

        set((store) => ({ products: [...store.products.filter(p => p.id !== productId), productToAddReviewsTo!] }))
    },
    fetchProducts: async (params: ProductSearchParams) => {
        let productIdsForSearchParams: string[] | undefined = undefined;

        get().loadedProductsForSearchParamsMap.forEach((products, loadedParams) => {
            if (deepEqual(params, loadedParams)) { productIdsForSearchParams = products; }
        });

        if (!productIdsForSearchParams) {
            const fetchedProducts = await getProducts(params);

            for (const products in fetchedProducts) {
                if (Object.prototype.hasOwnProperty.call(fetchedProducts, products)) {
                    const productItem = fetchedProducts[products];
                    get().addProductItem(productItem);
                }
            }

            set((state) => ({
                products: fetchedProducts,
                loadedPostsForSearchParamsMap: produce(state.loadedProductsForSearchParamsMap, draft => draft.set(params!, fetchedProducts.map(p => p.id)))
            }));
        }
        else {
            let filteredProducts: Product[] = [];
            (productIdsForSearchParams as string[]).forEach(pId => {
                if (get().products.some(p => p.id === pId))
                    filteredProducts[filteredProducts.length] = get().products.find(p => p.id === pId)!;
            })
        }
    },
    async fetchProductsCount(params: ProductSearchParams) {
        let postsCount = get().loadedProductCountsForSearchParams.find(p => deepEqual(p.params, params))?.productsCount;

        if (!postsCount)
            postsCount = await getProductsCount(params);

        set((state) => ({
            loadedProductCountsForSearchParams: [...state.loadedProductCountsForSearchParams, { params: params, productsCount: postsCount! }]
        }));
    },

    addProductItem(product) {
        if (!get().products.find(p => p.id === product.id))
            set(store => ({ products: [...store.products, product] }))
    },
    addMultipleProductItems(products: Product[]) {
        set(_ => ({ products: [...new Set([...get().products, ...products])] }))
    }
}));