import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { NotificationItemDto } from '../../../models/Notifications'
import parse from 'html-react-parser'
import { notificationTranslations } from '../../../translations/notifications/notifications'
import { useCurrentUser, useLangStore, useLoadingFetch } from '../../../state/stateManagement'
import { getTimeSinceNow } from '../../../utils/dateFormatter'
import { getTranslatedWord } from '../../../utils/translations'
import { useNotificationsStore } from '../../../state/notificationsStore'
import { useFetchWithToken } from '../../../utils/useADAuth'
import { scopes } from '../../../config/authConfig'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import Scrollbars from 'react-custom-scrollbars-2'

export default function NotificationsButton() {
    const selectedLanguage = useLangStore(store => store.selectedLanguage);
    const { notifications, fetchNotifications, markAsViewed, markAllAsRead } = useNotificationsStore();
    const fetchWithToken = useFetchWithToken()
    const loadingFetch = useLoadingFetch();
    const { currentUser } = useCurrentUser();
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const { pathname, search } = useLocation();

    useEffect(() => {
        if (currentUser)
            loadingFetch(() => fetchWithToken([scopes.user_impersonation], (token) => fetchNotifications(token)))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isNotificationOpen && notifications.some(n => !n.isViewed))
            fetchWithToken([scopes.user_impersonation], accessToken => markAsViewed(accessToken))
    }, [isNotificationOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setIsNotificationOpen(false);
    }, [pathname, search]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="notifications-icon" onClick={() => {
                setIsNotificationOpen(!isNotificationOpen);
            }}>
                <FontAwesomeIcon icon={faBell} />
                {notifications.some(n => !n.isViewed) && <span>{notifications.filter(n => !n.isViewed).length}</span>}
            </div>
            {isNotificationOpen &&
                <div className='notifications-container'>
                    <div aria-label="Notifications" role="dialog">
                        <div style={{ paddingLeft: 12 }}>
                            <h3>{notificationTranslations.title.get(selectedLanguage)}</h3>
                        </div>
                        <div aria-label="Notifications" role="grid">
                            <div style={{ padding: '0 10px', paddingBottom: 2, fontSize: 13, display: 'flex', justifyContent: 'space-between' }}>
                                <Link className='notifications-mark-read' to={''} onClick={e => {
                                    e.preventDefault();
                                    fetchWithToken([scopes.user_impersonation], markAllAsRead)
                                }} >
                                    <span>{notificationTranslations.markAsRead.get(selectedLanguage)}</span>
                                </Link>
                                <Link className='notifications-see-all' to={'/notifications'}>
                                    <span>{notificationTranslations.seeAll.get(selectedLanguage)}</span>
                                </Link>
                            </div>
                            {notifications.length > 0 ?
                                <Scrollbars autoHeight autoHeightMin={200} autoHeightMax={window.innerHeight - 150} autoHide className='notification-items' >
                                    <div className='row no-gutters'>
                                        <div className='col-lg-12'>
                                            {notifications.map(n => <NotificationItem key={n.id} notificationItem={n} />)}
                                        </div>
                                    </div>
                                </Scrollbars>
                                : <p className='text-center mt-4'>{notificationTranslations.noNotifications.get(selectedLanguage)}</p>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


export function NotificationItem({ notificationItem }: { notificationItem: NotificationItemDto }) {
    const selectedLanguage = useLangStore(store => store.selectedLanguage);
    const { markAsRead } = useNotificationsStore();
    const fetchWithToken = useFetchWithToken();

    return (
        <Link to={notificationItem.itemLink} role="link" onClick={e => {
            if (!notificationItem.isRead)
                fetchWithToken([scopes.user_impersonation], (accessToken) => markAsRead(accessToken, notificationItem.id))
        }}>
            <div className='notification-item'>
                <div className='notification-image'>
                    <img src={notificationItem.image} alt='notification from' />
                    <FontAwesomeIcon icon={notificationItem.notificationIcon} className='icon-notification-type' />
                </div>
                <div className='notification-info'>
                    <div className='notification-content'>
                        <span>
                            {parse(getTranslatedWord(notificationItem.message, selectedLanguage) ?? "")}
                        </span>
                        <span className='notification-since'>{getTimeSinceNow(notificationItem.createdOnUtc, selectedLanguage)}</span>
                    </div>
                    <div className='notification-status'>
                        {!notificationItem.isRead && <span className='notification-unvisited'></span>}
                    </div>
                </div>
            </div>
        </Link>
    )
}