import React from 'react'
import InstagramPanel from '../../Instagram/InstagramPanel'
import NotFoundImage from '../../../assets/img/other/error404.png'
import { Link } from 'react-router-dom'
import { useLangStore } from '../../../state/stateManagement'
import { notFoundPage } from '../../../translations/notfound/notfound'

export default function NotFoundPage() {

    const { selectedLanguage } = useLangStore();

    return (
        <>
            <div className="page404">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 m-auto">
                            <div className="page404-content">
                                <img src={NotFoundImage} alt="" />
                                <h3>{notFoundPage.title.get(selectedLanguage)}</h3>
                                <p>{notFoundPage.subTitle.get(selectedLanguage)}
                                </p>
                                <Link to="/" className="btn-custom">{notFoundPage.backBtnText.get(selectedLanguage)}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <InstagramPanel />
        </>
    )
}
