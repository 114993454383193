import { faComment, faEnvelope, faFileAlt, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { create } from "zustand";
import produce from "immer";
import { NotificationItemDto, NotificationResponseItem } from "../models/Notifications";
import { getCurrentUserNotifications, markAllCurrentUserNotificationAsRead, markCurrentUserNotificationAsRead, markCurrentUserNotificationsAsViewed } from "../services/notificationService";
import { notificationTranslations } from "../translations/notifications/notifications";
import { supportedLanguages } from "../translations/supportedLanguages";
import { FormatString } from "../utils/stringFormatter";
import { getTranslatedWord } from "../utils/translations";
import PostNotificationImagePlaceholder from "../assets/img/notifications/post-notificaiton-placeholder-image.webp"
import EmailSubscriptionNotificationImagePlaceholder from "../assets/img/notifications/email-subscription-default-image.webp"
import ContactFormNotificationImagePlaceholder from "../assets/img/notifications/contact-form-default-image.svg"
import DefaultMaleAvatar from '../assets/img/author/male-avatar.png'

interface NotificationsStore {
    notifications: NotificationItemDto[],
    isInit: boolean,

    fetchNotifications: (accessToken: string) => Promise<void>
    markAsViewed: (accessToken: string) => Promise<void>
    markAllAsRead: (accessToken: string) => Promise<void>
    markAsRead: (accessToken: string, notificationId: string) => Promise<void>
}

export const useNotificationsStore = create<NotificationsStore>((set, get) => ({
    notifications: [],
    isInit: false,

    fetchNotifications: async (accessToken) => {
        if (!get().isInit) {
            const result = await getCurrentUserNotifications(accessToken);
            const transformedNotifications = result.map(n => mapTo(n)).filter(n => n !== undefined);

            set({ notifications: transformedNotifications as NotificationItemDto[], isInit: true });
        }
    },
    markAsViewed: async (accessToken) => {
        await markCurrentUserNotificationsAsViewed(accessToken); // Later we might want to use the Ids of the Viewed posts returned here

        set({ notifications: produce(get().notifications, draft => { draft.map(d => d.isViewed = true) }) })
    },
    markAllAsRead: async (accessToken) => {
        await markAllCurrentUserNotificationAsRead(accessToken); // Later we might want to use the Ids of the Read posts returned here

        set({ notifications: produce(get().notifications, draft => { draft.map(d => d.isRead = true) }) })
    },
    markAsRead: async (accessToken, notificationId) => {
        await markCurrentUserNotificationAsRead(accessToken, notificationId); // Later we might want to use the Ids of the Read posts returned here

        set({ notifications: produce(get().notifications, draft => { draft.find(n => n.id === notificationId)!.isRead = true }) })
    }
}))


function mapTo(item: NotificationResponseItem): NotificationItemDto | undefined {

    let notificationIcon: IconDefinition | undefined = undefined;
    let notificationMessage: Translation[] | undefined = undefined;
    let itemLink: string | undefined = item.itemLink; // default link, some notification types might change it
    let image: string | undefined = item.imageUrl;

    switch (item.notificationType) {
        case "BlogPost": {
            notificationIcon = faFileAlt;
            itemLink = `/posts/${item.itemId}`;
            image = image ?? PostNotificationImagePlaceholder;

            const postTitleTranslations = item.parameters.find(p => p.parameter === 'postTitle')?.translations;
            // const categoryNameTranslations = item.parameters.find(p => p.parameter == 'categoryName')!.translations;

            if (!postTitleTranslations) return;

            notificationMessage = supportedLanguages.map(lang => lang.shortName)
                .map(lang => {
                    return {
                        language: lang,
                        word: FormatString(
                            notificationTranslations.postCreatedMessage.get(lang)!,
                            ((item.senderName && getTranslatedWord(item.senderName, lang)) ?? ""),
                            getTranslatedWord(postTitleTranslations, lang) ?? postTitleTranslations[0].word)
                    }
                });
            break;
        }
        case "EmailSubscription": {
            notificationIcon = faEnvelope;
            image = image ?? EmailSubscriptionNotificationImagePlaceholder;

            const email = item.parameters.find(p => p.parameter === 'email')?.translations; // Not translatable
            if (!email) return;

            notificationMessage = supportedLanguages.map(lang => lang.shortName).map(lang => {
                return {
                    language: lang, word: FormatString(
                        notificationTranslations.emailSubscriptionCreatedMessage.get(lang)!,
                        getTranslatedWord(email, lang) ?? email[0].word)
                };
            })
            break;
        }
        case "PostComment": {
            notificationIcon = faComment;
            itemLink = `/posts/${item.itemId}`;
            image = image ?? DefaultMaleAvatar;

            const postTitleForComment = item.parameters.find(p => p.parameter === 'postTitle')!.translations;

            if (!postTitleForComment) return;

            notificationMessage = supportedLanguages.map(lang => lang.shortName).map(lang => {
                return {
                    language: lang,
                    word: FormatString(
                        notificationTranslations.commentCreatedMessage.get(lang)!,
                        ((item.senderName && getTranslatedWord(item.senderName, lang)) ?? ""),
                        getTranslatedWord(postTitleForComment, lang) ?? postTitleForComment[0].word
                    )
                }
            });
            break;
        }
        case "Inquery": {
            notificationIcon = faComment;
            itemLink = `/inqueries/${item.itemId}`;
            image = image ?? ContactFormNotificationImagePlaceholder;

            const personName = item.senderName;
            const email = item.parameters.find(p => p.parameter === 'email')?.translations;
            const subject = item.parameters.find(p => p.parameter === 'subject')?.translations;

            if (!email || !subject || !personName) return;

            notificationMessage = supportedLanguages.map(lang => lang.shortName).map(lang => {
                return {
                    language: lang,
                    word: FormatString(
                        notificationTranslations.inqueryCreatedMessage.get(lang)!,
                        getTranslatedWord(personName, lang) ?? personName[0].word,
                        getTranslatedWord(email, lang) ?? email[0].word,
                        getTranslatedWord(subject, lang) ?? subject[0].word
                    )
                }
            });
            break;
        }
        case "AuthorProfile": {
            notificationIcon = faComment;
            itemLink = `/authors/${item.itemId}`;
            image = image ?? DefaultMaleAvatar;

            const userDisplayName = item.senderName!;
            const email = item.parameters.find(p => p.parameter === 'email')!.translations;

            if (!email || !userDisplayName) return;

            notificationMessage = supportedLanguages.map(lang => lang.shortName).map(lang => {
                return {
                    language: lang,
                    word: FormatString(
                        notificationTranslations.authorCreatedMessage.get(lang)!,
                        getTranslatedWord(userDisplayName, lang) ?? userDisplayName[0].word,
                        getTranslatedWord(email, lang) ?? email[0].word)
                }
            });
            break;
        }
        default:
            break;
    }

    var result = {
        id: item.id,
        image: image,
        isRead: item.isClicked,
        isViewed: item.isViewed,
        itemLink: itemLink,
        createdOnUtc: new Date(item.createdOnUtc),
        notificationIcon: notificationIcon,
        message: notificationMessage
    } as NotificationItemDto

    return result;
}