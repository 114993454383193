import React from 'react'
import { useForm } from 'react-hook-form';
import { useLangStore } from '../../../state/stateManagement';
import { sidebarSearchSection } from '../../../translations/shared/sidebar';
import { isNotOnlyWhiteSpaces } from '../../../utils/predicates';
import { useNavigateSearch } from '../../../utils/useNavigateSearch';

export default function SearchSection() {
    const { selectedLanguage } = useLangStore();
    const { register, handleSubmit, reset } = useForm();
    const searchNavigate = useNavigateSearch();

    return (
        <div className="widget">
            <div className="widget-title">
                <h5>{sidebarSearchSection.searchHeading.get(selectedLanguage)}</h5>
            </div>
            <div className=" widget-search">
                <form onSubmit={handleSubmit(data => {
                    searchNavigate('/posts/search', { keyword: data.search });
                    reset();
                })}>
                    <input  {...register('search', { required: true, maxLength: 40 , validate: isNotOnlyWhiteSpaces})} type="search" placeholder={sidebarSearchSection.searchInputPlaceholder.get(selectedLanguage)} />
                    <button className="btn-submit">
                        <i className="las la-search"></i>
                    </button>
                </form>
            </div>
        </div>
    )
}
