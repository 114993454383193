import React, { useEffect } from 'react'
import { useLangStore, useLoading } from '../../../state/stateManagement';
import { productMultipleHeadingTranslations, productMultipleTotalResultsTranslations } from '../../../translations/shop/product/productMultiplePage'
import CenterHeading from '../../PageHeading/CenterHeading'
import Heading from '../../PageHeading/Heading'
import ProductCard from './ProductMultiplePage/ProductCard';
import Pagination from '../../Pagination/Pagination';
import { useSearchParams } from 'react-router-dom';
import { ProductSearchParams, toUrlSearchParams } from '../../../services/shop/productService';
import { productsCountSearchParamSelector, useProductStore } from '../../../state/Shop/productStore';
import { toLocalisedProduct } from '../../../utils/mappers/productMappers';

export default function ProductMultiplePage() {

    const selectedLanguage = useLangStore(store => store.selectedLanguage);
    const setLoading = useLoading(store => store.setLoading);

    // Get query string
    const [params] = useSearchParams();

    // Local State
    const currentPage = Number(params.get('page') ?? '1');
    const pageSize = 8;
    const searchParams: ProductSearchParams = { page: currentPage, pageSize: pageSize };

    const { fetchProducts, fetchProductsCount, products, productsCount } = useProductStore(store => ({
        fetchProducts: store.fetchProducts,
        fetchProductsCount: store.fetchProductsCount,
        productsCount: productsCountSearchParamSelector(store, searchParams),
        products: store.products
    }))

    useEffect(() => {
        setLoading(true);
        Promise.all([fetchProducts(searchParams), fetchProductsCount(searchParams)]).then(() => { setLoading(false) });
    }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <CenterHeading headingContent={
                <Heading heading={productMultipleHeadingTranslations.heading.get(selectedLanguage)!} segment={productMultipleHeadingTranslations.segment.get(selectedLanguage)!} />
            } />

            <div className="shop">
                <div className="container">
                    <div className="shop-area">

                        <div className="shop-filters">
                            {productsCount && <h6>{productMultipleTotalResultsTranslations.showing.get(selectedLanguage)} {pageSize > productsCount ? productsCount : pageSize} {productMultipleTotalResultsTranslations.of.get(selectedLanguage)} {productsCount} {productMultipleTotalResultsTranslations.results.get(selectedLanguage)}</h6>}
                            <form className="woocommerce-ordering" method="get">
                                <select name="orderby" className="orderby" aria-label="Shop order">
                                    <option value="menu_order" >Default sorting</option>
                                    <option value="popularity" >Sort by popularity</option>
                                    <option value="rating" >Sort by average rating</option>
                                    <option value="date" >Sort by latest</option>
                                    <option value="price" >Sort by price: low to high</option>
                                    <option value="price-desc" >Sort by price: high to low</option>
                                </select>
                                <input type="hidden" name="paged" value="1" />
                            </form>
                        </div>

                        <div className="row">
                            {
                                products.map(product =>
                                    <div key={product.id} className=" col-md-4  col-sm-6">
                                        <ProductCard product={toLocalisedProduct(product, selectedLanguage)} />
                                    </div>)
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="pagination">
                <div className="container">
                    <Pagination
                        currentPage={currentPage}
                        totalCount={productsCount ?? products.length}
                        pageSize={pageSize}
                        onPageChange={async (page: number) => {
                            if (page * pageSize < (productsCount ?? products.length) + pageSize) {
                                setLoading(true);
                                await fetchProducts({ ...searchParams, ...{ page: page } });
                                setLoading(false);
                            }
                        }}
                        queryParams={toUrlSearchParams(searchParams)}
                        className="list-inline"
                    />
                </div>
            </div>
        </>
    )
}
