import { get } from "./baseHttpClient";

const tagsUrl = 'api/tags';

export async function getPopularTags(count?: number) {
    const response = await get(tagsUrl + '/popular' + (count ? `?count=${count}` : ``), new Headers({ 'Accept-Language': 'bg' }));
    return response;
}

export async function getTags() {
    const response = await get(tagsUrl, new Headers({ 'Accept-Language': 'bg' }));
    return response;
}