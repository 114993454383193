import React, { useEffect } from 'react'
import TagsSection from './Children/TagsSection'
import PopularPostsSection from './Children/PopularPostsSection'
import SocialMediaPopularity from './Children/SocialMediaPopularity'
import CategoriesSection from './Children/CategoriesSection'
import SubscribeSection from './Children/SubscribeSection'
import AdVertical from '../Generic/Ads/AdVertical'
import SearchSection from './Children/SearchSection'
import { useSocialMediaFollowersStore } from '../../state/socialMediaFollowersStore'
import { useCategoriesStore } from '../../state/categoriesStore'
import { mostPopularPostsSelector, usePostDetailsStore } from '../../state/postDetailsStore'
import { useTagsStore } from '../../state/tagsStore'
import { toLocalisedCategory } from '../../utils/mappers/categoryMappers'
import { useLangStore, useLoading } from '../../state/stateManagement'
import { getPostsTranslations } from '../../services/blogPostService'
import { toLocalisedPost } from '../../utils/mappers/postMappers'

function Sidebar() {

    // Loading
    const { loadingFetch } = useLoading();

    // Language
    const selectedLanguage = useLangStore(store => store.selectedLanguage);

    // Sidebar data
    const { socialMediaFollowers, fetchSocialMediaFollowers } = useSocialMediaFollowersStore();

    const { tags, fetchTags } = useTagsStore()
    // Categories store
    const { categories, fetchCategories } = useCategoriesStore();

    // Posts store
    const { popularPosts, fetchMostPopular, addPostsTranslations } = usePostDetailsStore(store => ({
        popularPosts: mostPopularPostsSelector(store),
        fetchMostPopular: store.fetchMostPopular,
        addPostsTranslations: store.addPostsTranslations
    }));

    useEffect(() => {
        Promise.all([fetchSocialMediaFollowers(), fetchTags(), fetchCategories(), fetchMostPopular(selectedLanguage)]);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (popularPosts && popularPosts.some(p => !p.titleTranslations.some(t => t.language === selectedLanguage))) {
            const postIdsWithoutTranslationsLoaded = popularPosts.filter(p => !p.titleTranslations.some(t => t.language === selectedLanguage)).map(p => p.id);
            loadingFetch([
                getPostsTranslations(postIdsWithoutTranslationsLoaded, selectedLanguage)
                    .then(translations => {
                        addPostsTranslations(translations, selectedLanguage);
                    })
            ])
        }
    }, [selectedLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="sidebar">

            <SearchSection />

            {!!categories.length && <CategoriesSection categories={categories.map(c => toLocalisedCategory(c, selectedLanguage))} />}

            <SubscribeSection />

            {!!socialMediaFollowers.length && <SocialMediaPopularity socialMediaInfo={socialMediaFollowers} />}

            {!!tags.length && <TagsSection tags={tags} />}

            {!!popularPosts?.length && <PopularPostsSection posts={popularPosts.map(p => toLocalisedPost(p, selectedLanguage))} />}

            <AdVertical />
        </div>
    )
}

export default Sidebar;