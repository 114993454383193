import React from 'react'
import HorizontalAd from "../../../assets/img/ads/ads-wide.webp"

export default function AdHorizontal() {
    return (
        <div className="post-single-ads">
            <div className="image">
                <img style={{ height: '144px', width: '1110px' }} src={HorizontalAd} alt="" />
            </div>
        </div>
    )
}
