import React, { useEffect } from 'react'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { useForm } from 'react-hook-form';
import { useLangStore, useLoading } from '../../state/stateManagement';
import CenterHeading from '../PageHeading/CenterHeading';
import Heading from '../PageHeading/Heading';
import parse from 'html-react-parser'
import { toast } from 'react-toastify';
import { useFetchWithTokenWithResult } from '../../utils/useADAuth';
import { scopes } from '../../config/authConfig';
import { useUnapprovedPostsStore } from '../../state/postUnapprovedStore';
import { toLocalisedPost } from '../../utils/mappers/postMappers';
import { postsUnapprovedPage } from '../../translations/posts/postUnapprovedPage';
import { Link } from 'react-router-dom';
import YesNoDialog from '../Modals/Dialogs/YesNoDialog';
import { useYesNoDialogStore } from '../../state/yesNoDialogStore'
import { FormatString } from '../../utils/stringFormatter';
import { approvePost } from '../../services/blogPostService';

export default function PostMultipleUnapprovedPage() {
    const selectedLanguage = useLangStore(store => store.selectedLanguage);
    const { loadingFetch } = useLoading();
    const fetchWithToken = useFetchWithTokenWithResult();
    const { unapprovedPosts, removeUnapprovedPost, fetchPosts } = useUnapprovedPostsStore(store => ({
        unapprovedPosts: store.unapprovedPosts.map(p => toLocalisedPost(p, selectedLanguage)),
        removeUnapprovedPost: store.removeUnapprovedPost,
        fetchPosts: store.fetchPosts
    }));
    const { openDialog } = useYesNoDialogStore();

    const { handleSubmit, } = useForm();

    useEffect(() => {
        loadingFetch([fetchWithToken([scopes.user_impersonation], fetchPosts)]);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='container'>
            <CenterHeading headingContent={
                <Heading heading={postsUnapprovedPage.heading.get(selectedLanguage)!} segment={postsUnapprovedPage.segment.get(selectedLanguage)!} />
            } />

            <div className='users-list'>
                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} >
                    {
                        unapprovedPosts.map(post => (
                            <AccordionItem key={post.id}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <span className='post-title font-weight-bold'>{post.title} {`( ${post.category.name} )`}</span>
                                        <span className='post-author font-weight-bold float-right'>{post.author.name}</span>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className='row'>
                                        <div className='post-preview col-lg-10'>
                                            {parse(post.contentPreview)}
                                        </div>
                                        <div className='post-actions col-lg-2 text-center'>
                                            <Link to={`/posts/${post.id}`} className="d-block pb-3 font-weight-bold">{postsUnapprovedPage.toPost.get(selectedLanguage)}</Link>
                                            <form
                                                onSubmit={handleSubmit(_ => {
                                                    openDialog({
                                                        title: postsUnapprovedPage.yesNoApproveDialog.title.get(selectedLanguage)!,
                                                        description: FormatString(postsUnapprovedPage.yesNoApproveDialog.body.get(selectedLanguage)!, post.title ?? ""),
                                                        yesBtnText: postsUnapprovedPage.yesNoApproveDialog.yesBtnText.get(selectedLanguage)!,
                                                        noBtnText: postsUnapprovedPage.yesNoApproveDialog.noBtnText.get(selectedLanguage)!
                                                    },
                                                        () => toast.promise(
                                                            fetchWithToken(
                                                                [scopes.user_impersonation],
                                                                async (accessToken) => {
                                                                    const approvedPostId = await approvePost(accessToken, post.id);
                                                                    approvedPostId && removeUnapprovedPost(approvedPostId);
                                                                }
                                                            ),
                                                            postsUnapprovedPage.approvePostToastMessages.get(selectedLanguage)!
                                                        )
                                                    );
                                                })}
                                            >
                                                <button type='submit' name="submit" className={`btn-custom form-control d-block`}>
                                                    {postsUnapprovedPage.approve.get(selectedLanguage)}
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))
                    }
                </Accordion>
            </div>

            <YesNoDialog />
        </div >
    )
}
