import { ToastPromiseParams } from "react-toastify"

export const postSinglePage = {
    previousPost: new Map([["en", "Previous Post"], ["bg", "Предишна статия"]]),
    nextPost: new Map([["en", "Next Post"], ["bg", "Следваща статия"]]),
    pendingPost: new Map([["en", "Pending Approval..."], ["bg", "Очаква Потвърждение..."]])
}

export const commentSingle = {
    reply: new Map([["en", "Reply"], ["bg", "Отговори"]]),
}

export const commentList = {
    commentSingular: new Map([["en", "Comment"], ["bg", "Коментар"]]),
    commentPlural: new Map([["en", "Comments"], ["bg", "Коментара"]]),
}

export const commentForm = {
    heading: new Map([["en", "Leave a Reply"], ["bg", "Остави коментар"]]),
    details: new Map([["en", "Your email adress will not be published. Required fileds are marked*."], ["bg", "Е-мейл адресът няма да бъде публикуван. Задължителните полета са отбелязани с *."]]),
    nameInputPlaceholder: new Map([["en", "Name*"], ["bg", "Име*"]]),
    emailInputPlaceholder: new Map([["en", "Email*"], ["bg", "Е-мейл*"]]),
    messageInputPlaceholder: new Map([["en", "Add Comment here*"], ["bg", "Напиши коментар*"]]),
    checkBoxDescription: new Map([["en", "save my name , email and website in this browser for the next time I comment."], ["bg", "запази името, е-мейлът и страницата в браузърът за следващ път когато коментирам."]]),
    sendBtnText: new Map([["en", "Send Comment"], ["bg", "Запиши коментар"]]),
}

export const deleteCommentDialog = {
    title: new Map([["en", "Are you sure you want to delete the comment?"], ["bg", "Наистина ли искате да изтриете коментара ?"]]),
    body: new Map([["en", `Clicking "OK" will remove the comment permanently. You won't be able to restore this comment anymore.`], ["bg", `С натискане на "Потвърди" коментарът ще бъде изтрит перманентно. След това няма да имате възможност да го възстановите повече.`]]),
    yesBtnText: new Map([["en", "Confirm"], ["bg", "Потвърди"]]),
    noBtnText: new Map([["en", "Cancel"], ["bg", "Откажи"]]),
}

export const createCommentToastsTranslations = {
    toastMessages: new Map<string, ToastPromiseParams<void>>([
        ["en", {
            pending: "Creating comment...",
            success: "Comment created successfully!",
            error: "Comment creation failed !"
        }],
        ["bg", {
            pending: "Създаване на коментар...",
            success: "Коментарът беше създадено успешно!",
            error: "Коментарът не беше създадено успешно!"
        }]
    ])
}

export const deleteCommentToastsTranslations = {
    toastMessages: new Map<string, ToastPromiseParams<void>>([
        ["en", {
            pending: "Deleting comment...",
            success: "Comment deleted successfully!",
            error: "Comment deletion failed !"
        }],
        ["bg", {
            pending: "Изтриване на коментар...",
            success: "Коментарът беше изтрит успешно!",
            error: "Коментарът не беше изтрит успешно!"
        }]
    ])
}