import React from 'react'
import SingleFilteredPost from './SingleFilteredPost';
import StickyBox from "react-sticky-box";
import { LocalisedPost } from '../../../models/PostDetails';

export default function ListFilteredPostPanel(
    { posts, pagerElement, sidebar }:
        { posts: LocalisedPost[], pagerElement: any, sidebar: any }) {
    return (
        <div className="blog-search">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <StickyBox offsetTop={100}>

                            {
                                posts.map(post => <SingleFilteredPost key={post.id} post={post} />)
                            }

                            {pagerElement}

                        </StickyBox>
                    </div>
                    <div className='col-lg-4'>
                        {sidebar}
                    </div>
                </div>
            </div>
        </div>

    )
}
