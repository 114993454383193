import React from 'react'
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import './App.css';
import './assets/styles/bootstrap.min.css'
import './assets/styles/style.css'
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
import Layout from './components/Layout/Layout';
import { Routes, Route } from 'react-router-dom';
import About from './components/Generic/AboutPage/AboutPage';
import AuthorSinglePage from './components/Author/AuthorSinglePage';
import PostSinglePage from './components/Post/PostSinglePage';
import PostMultiplePage from './components/Post/PostMultiplePage';
import CategorySinglePage from './components/Category/CategorySinglePage';
import AuthorMultiplePage from './components/Author/AuthorMultiplePage';
import CategoriesListPage from './components/Category/CategoriesListPage';
import HomePage from './components/Home/HomePage';
import PostFilteredPage from './components/Post/PostFilteredPage';
import PostCreatePage from './components/Post/PostCreatePage';
import PostEditPage from './components/Post/PostEditPage';
import CategoryEditPage from './components/Category/CategoryEditPage';
import CategoryCreatePage from './components/Category/CategoryCreatePage';
import GuardedRoute from './components/Shared/GuardedRoute';
import CongratsPage from './components/Generic/Congrats/CongratsPage';
import InqueriesPage from './components/Inqueries/InqueriesPage';
import UsersPage from './components/Users/UsersPage';
import { msalConfig } from './config/authConfig';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import AuthorPromoteTrustedPage from './components/Author/AuthorPromoteTrustedPage';
import PostMultipleUnapprovedPage from './components/Post/PostMultipleUnapprovedPage';
import InquerySinglePage from './components/Inqueries/InquerySinglePage';
import ProductMultiplePage from './components/Shop/Product/ProductMultiplePage';
import SignUpPage from './components/Generic/SignUpPage/SignUpPage';
import LoginPage from './components/Generic/LoginPage/LoginPage';
import PrivacyPolicy from './components/Generic/TermsPage/PrivacyPolicyPage';
import NotAuthorizedPage from './components/Generic/NotAuthorizedPage/NotAuthorizedPage';
import ContactPage from './components/Generic/ContactPages/ContactPage';
import NotFoundPage from './components/Generic/NotFoundPage/NotFoundPage';
import BecomeAuthorPage from './components/Generic/ContactPages/BecomeAuthorPage';
import ProductSinglePage from './components/Shop/Product/ProductSinglePage';
import ProductCreatePage from './components/Shop/Product/ProductCreatePage';

// Setup google analytics
if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production')
  process.env.REACT_APP_GA_MEASUREMENT_ID && ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <HelmetProvider>
      <MsalProvider instance={msalInstance}>
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="authors" element={<AuthorMultiplePage />} />
            <Route path="authors/:authorId" element={<AuthorSinglePage />} />
            <Route path="authors/promote-trusted" element={<GuardedRoute allowedRoles={["admin"]} ><AuthorPromoteTrustedPage /></GuardedRoute>} />
            <Route path="posts" element={<PostMultiplePage />} />
            <Route path="posts/unapproved" element={<PostMultipleUnapprovedPage />} />
            <Route path="posts/:postId" element={<PostSinglePage />} />
            <Route path="posts/search" element={<PostFilteredPage />} />
            <Route path='posts/create' element={<GuardedRoute allowedRoles={["author", "admin"]} > <PostCreatePage /> </GuardedRoute>} />
            <Route path='posts/:postId/edit' element={<GuardedRoute allowedRoles={["author", "admin"]} ><PostEditPage /></GuardedRoute>} />
            <Route path="categories" element={<CategoriesListPage />} />
            <Route path="categories/create" element={<GuardedRoute allowedRoles={["admin"]} ><CategoryCreatePage /></GuardedRoute>} />
            <Route path="categories/:categoryId" element={<CategorySinglePage />} />
            <Route path="categories/:categoryId/edit" element={<GuardedRoute allowedRoles={["admin"]} ><CategoryEditPage /></GuardedRoute>} />
            <Route path='signup' element={<SignUpPage />} />
            <Route path='login' element={<LoginPage />} />
            <Route path='privacy' element={<PrivacyPolicy />} />
            <Route path='congrats' element={<CongratsPage />} />
            <Route path='email-subscriptions/:subscriptionId/confirm' />
            <Route path='inqueries' element={<GuardedRoute allowedRoles={["admin"]} ><InqueriesPage /></GuardedRoute>} />
            <Route path='inqueries/:inqueryId' element={<GuardedRoute allowedRoles={["admin"]} ><InquerySinglePage /></GuardedRoute>} />
            <Route path='inqueries/become-author' element={<GuardedRoute allowedRoles={["member"]} ><BecomeAuthorPage /></GuardedRoute>} />
            <Route path='users' element={<GuardedRoute allowedRoles={["admin"]} ><UsersPage /></GuardedRoute>} />

            {process.env.REACT_APP_SHOW_SHOP && <Route path='shop' element={<ProductMultiplePage />} />}
            <Route path='products/:productId' element={<ProductSinglePage />} />
            <Route path='products/create' element={<ProductCreatePage />} />

            <Route path='unauthorized' element={<NotAuthorizedPage />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </Layout>
      </MsalProvider>
    </HelmetProvider>
  );
}

export default App;
