import { ToastPromiseParams } from "react-toastify"

export const postsUnapprovedPage = {
    heading: new Map([["en", "Unapproved Posts"], ["bg", "Непотвърдени Статии"]]),
    segment: new Map([["en", "Unapproved Posts"], ["bg", "Непотвърдени Статии"]]),
    toPost: new Map([["en", "Check out the post"], ["bg", "Виж статията"]]),
    approve: new Map([["en", "Approve"], ["bg", "Потвърди"]]),
    approvePostToastMessages: new Map<string, ToastPromiseParams<void>>([
        ["en", { pending: "Approving the Post...", success: "The Post is Approved!", error: "The Approve Failed!" }],
        ["bg", { pending: "Потвърждаване на Статията...", success: "Постът е Потвърден!", error: "Потвърждението е неуспешно!" }]
    ]),

    yesNoApproveDialog: {
        title: new Map([["en", "You want to approve the Article ?"], ["bg", "Искаш да потвърдиш статията ?"]]),
        body: new Map([["en", "If you want to approve '{0}' click Approve button !"], ["bg", "Ако искаш да потвърдиш '{0}' натисни бутона Потвърди"]]),
        yesBtnText: new Map([["en", "Approve"], ["bg", "Потвърди"]]),
        noBtnText: new Map([["en", "Cancel"], ["bg", "Откажи"]])
    }
}