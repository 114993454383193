import React from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useLangStore } from '../../../state/stateManagement';
import { subscribeWithEmail } from '../../../services/emailSubscriptionService';
import { sidebarSubscribeSection, sidebarSubscribeFormTranslations } from '../../../translations/shared/sidebar';
import { isValidEmail } from '../../../utils/field-validations';
import { genericTranslations } from '../../../translations/generic/inputTooltip';

export default function SubscribeSection() {

    const { selectedLanguage } = useLangStore();
    const { register, handleSubmit, formState: { errors, dirtyFields, isValid }, reset } = useForm<SubscribeModel>({ mode: 'onChange' });

    return (
        <div className="widget widget-newsletter">
            <h5>{sidebarSubscribeSection.headingText.get(selectedLanguage)}</h5>
            <p>{sidebarSubscribeSection.subHeadingText.get(selectedLanguage)}</p>
            <form
                className="newsletter-form"
                onSubmit={
                    handleSubmit(async data => {
                        if (isValid) {
                            await toast.promise(
                                subscribeWithEmail(data.email).then(p => { reset() }),
                                sidebarSubscribeFormTranslations.toastMessages.get(selectedLanguage)!
                            )
                        }
                    })
                }
            >
                <div className="form-flex">
                    <div className="form-group">
                        <input
                            {...register('email', { required: true, validate: isValidEmail })}
                            title={genericTranslations.emailRequiredTooltip.get(selectedLanguage)}
                            className={`form-control ${!dirtyFields.email ? "" : errors.email ? "border-danger" : "border-success"}`}
                            placeholder={sidebarSubscribeSection.emailInputPlaceholder.get(selectedLanguage)}
                        />
                    </div>
                    <button
                        className={`btn-custom`}
                        type="submit">
                        {sidebarSubscribeSection.subscribeBtnText.get(selectedLanguage)}
                    </button>
                </div>
            </form>
        </div>
    )
}
