import React from 'react'
import { Link } from 'react-router-dom'
import { LocalisedAuthor } from '../../../models/Author';
import { useLangStore } from '../../../state/stateManagement'
import { authorListPage } from '../../../translations/author/authorListPage';
import AuthorImage from '../Shared/AuthorImage'

function AuthorSingleSmall({ author }: { author: LocalisedAuthor }) {
    const selectedLanguage = useLangStore(store => store.selectedLanguage);

    return (
        <div className="authors-single">
            <div className="authors-single-image">
                <Link to={`/authors/${author.id}`}>
                    <AuthorImage imageUrl={author.image?.urlOrBase64} isMale={author.isMale} />
                </Link>
            </div>
            <div className="authors-single-content">
                <div className="left">
                    <h6>
                        <Link to={`/authors/${author.id}`}>{author.name}</Link>
                    </h6>
                    <p>{author.articlesCount ?? 0} {author.articlesCount === 1 ? authorListPage.articlesSingular.get(selectedLanguage) : authorListPage.articles.get(selectedLanguage)}</p>
                </div>
                <div className="right">
                    <Link to={`/authors/${author.id}`}>
                        <div className="more-icon">
                            <i className="las la-angle-double-right"></i>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default AuthorSingleSmall