import React from 'react';
import { Navigate } from "react-router-dom";
import { useCurrentUser } from '../../state/stateManagement';

const GuardedRoute = ({ allowedRoles, redirectPath = '/unauthorized', children }: { allowedRoles: string[], redirectPath?: string, children: any }): JSX.Element => {

    const { currentUser } = useCurrentUser()
    if (!currentUser || !allowedRoles.includes(currentUser.role.toLowerCase())) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
}

export default GuardedRoute;