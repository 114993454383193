import React from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useLangStore } from '../../../state/stateManagement'
import { addComment } from '../../../services/postCommentService';
import { commentForm, createCommentToastsTranslations } from '../../../translations/posts/postSinglePage';
import { useFetchWithToken } from '../../../utils/useADAuth';
import { usePostDetailsStore } from '../../../state/postDetailsStore';
import { scopes } from '../../../config/authConfig';
import { CreateCommentUnregisteredUserRequest } from '../../../models/Comment';
import { isNotOnlyWhiteSpaces } from '../../../utils/predicates';

export default function CommentFormRegistered({ onSubmit }: { onSubmit?: () => void }) {

    const fetchWithToken = useFetchWithToken();
    const { selectedLanguage } = useLangStore();
    const { selectedPostId, addCommentToPost } = usePostDetailsStore();

    const { register, setValue, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm<CreateCommentUnregisteredUserRequest>({ mode: "onChange" });

    return (
        <form className="form"
            onSubmit={
                handleSubmit(data => {
                    toast.promise(async () => {

                        if (errors.commentText)
                            return;

                        await fetchWithToken([scopes.openId, scopes.comments.write],
                            async (accessToken) => {
                                const comment = await addComment(accessToken, selectedPostId!, { commentText: data.commentText })
                                addCommentToPost(selectedPostId!, comment);
                                reset();
                            })

                    },
                        createCommentToastsTranslations.toastMessages.get(selectedLanguage)!
                    )

                    onSubmit && onSubmit();
                })
            }>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <textarea
                            {...register("commentText", { required: true, minLength: 2, maxLength: 500, validate: isNotOnlyWhiteSpaces })}
                            onChange={e => { setValue("commentText", e.target.value, { shouldDirty: true, shouldValidate: true }); }}
                            name="message"
                            id="message"
                            cols={30} rows={5}
                            className="form-control"
                            placeholder={commentForm.messageInputPlaceholder.get(selectedLanguage)}>
                        </textarea>
                    </div>
                </div>

                <div className="col-lg-12">
                    <button type="submit" name="submit" className={`btn-custom ${!isDirty || !isValid ? "disabled" : ""}`} disabled={!isDirty || !isValid}>
                        {commentForm.sendBtnText.get(selectedLanguage)}
                    </button>
                </div>
            </div>
        </form>
    )
}
