export const productMainItemTranslations = {
    reviews: new Map([["en", "reviews"], ["bg", "ревюта"]]),
    addToCart: new Map([["en", "Add to cart"], ["bg", "Добави в количката"]]),
}

export const productDetailsNavTranslations = {
    description: new Map([["en", "Description"], ["bg", "Описание"]]),
    specifications: new Map([["en", "Specifications"], ["bg", "Спецификации"]]),
    reviews: new Map([["en", "Reviews"], ["bg", "Ревюта"]]),
}

export const productSingleHeadingTranslations = {
    heading: new Map([["en", "Home"], ["bg", "Начало"]]),
    segment: new Map([["en", "Shop"], ["bg", "Магазин"]]),
}