import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useLangStore, useUnregisteredUser } from '../../../state/stateManagement'
import { addCommentAnonymous } from '../../../services/postCommentService';
import { commentForm, createCommentToastsTranslations } from '../../../translations/posts/postSinglePage';
import { usePostDetailsStore } from '../../../state/postDetailsStore';
import { isValidEmail } from '../../../utils/field-validations';
import { CommentUnregisteredUserForm } from '../../../models/Comment';
import { isNotOnlyWhiteSpaces } from '../../../utils/predicates';

export default function CommentFormUnregistered({ onSubmit }: { onSubmit?: () => void }) {

    const { selectedLanguage } = useLangStore();
    const { selectedPostId, addCommentToPost } = usePostDetailsStore();
    const { user, setUser } = useUnregisteredUser();
    const { register, handleSubmit, setValue, getValues, reset, formState: { errors, dirtyFields, isDirty, isValid } } = useForm<CommentUnregisteredUserForm>({ mode: 'onChange' });


    useEffect(() => {
        register("commentText", { required: true, minLength: 2, maxLength: 500, validate: isNotOnlyWhiteSpaces })
        if (user.name) setValue('name', user.name, { shouldDirty: true, shouldValidate: true });
        if (user.email) setValue('email', user.email, { shouldDirty: true, shouldValidate: true });
        if (user.email && user.name) setValue('saveNameForLaterCommenting', true, { shouldDirty: true, shouldValidate: true });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <form
            className="form"
            onSubmit={
                handleSubmit(data => {

                    if (errors.commentText || errors.email || errors.name || errors.saveNameForLaterCommenting)
                        return;

                    if (data.saveNameForLaterCommenting) {
                        setUser({ name: data.name, email: data.email });
                    }

                    toast.promise(
                        addCommentAnonymous(selectedPostId!, { commentText: data.commentText, name: data.name, email: data.email })
                            .then(comment => {
                                addCommentToPost(selectedPostId!, comment);
                                if (getValues("saveNameForLaterCommenting")) { reset({ ...data, ...{ commentText: "" } }) }
                                else reset();
                            }),
                        createCommentToastsTranslations.toastMessages.get(selectedLanguage)!
                    );

                    onSubmit && onSubmit();
                })
            }
            id="main_contact_form"
        >
            <p>{commentForm.details.get(selectedLanguage)}</p>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <input
                            {...register("name", { required: true, validate: isNotOnlyWhiteSpaces })}
                            type="text"
                            className={`form-control ${!dirtyFields.name ? "" : (errors.name ? "border-danger" : "border-success")}`}
                            placeholder={commentForm.nameInputPlaceholder.get(selectedLanguage)}
                            required={true}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <input
                            {...register("email", { required: true, maxLength: 60, validate: isValidEmail })}
                            type="email"
                            className={`form-control ${!dirtyFields.email ? "" : (errors.email ? "border-danger" : "border-success")}`}
                            placeholder={commentForm.emailInputPlaceholder.get(selectedLanguage)}
                            required={true}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <textarea
                            value={getValues('commentText')}
                            onChange={e => setValue("commentText", e.target.value, { shouldDirty: true, shouldValidate: true })}
                            cols={30}
                            rows={5}
                            className={`form-control ${!dirtyFields.commentText ? "" : errors.commentText ? "border-danger" : "border-success"}`}
                            placeholder={commentForm.messageInputPlaceholder.get(selectedLanguage)}
                            required={true}>
                        </textarea>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="mb-20">
                        <input type="checkbox" id='saveNameForLaterCommenting' {...register("saveNameForLaterCommenting")} />
                        <label htmlFor="saveNameForLaterCommenting" style={{ marginLeft: "5px" }}>
                            <span>{commentForm.checkBoxDescription.get(selectedLanguage)}</span>
                        </label>
                    </div>

                    <button type="submit" name="submit" className={`btn-custom ${!isDirty || !isValid ? "disabled" : ""}`} disabled={!isDirty || !isValid}>
                        {commentForm.sendBtnText.get(selectedLanguage)}
                    </button>
                </div>
            </div>
        </form >
    )
}
