import Resizer from "react-image-file-resizer";

export function dataURLtoFile(base64File: string, filename: string) {

    const arr = base64File.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = window.atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export const getBase64FromUrl = async (url: string): Promise<string> => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();

        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result as string;
            resolve(base64data);
        }
    });
}

export function validURL(url: string) {
    //eslint-disable-next-line
    var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
}

export function getImageDimensions(imgSrc: string): Promise<{ w: number, h: number }> {
    return new Promise(function (resolved) {
        var i = new Image()
        i.onload = function () {
            resolved({ w: i.width, h: i.height })
        };
        i.src = imgSrc
    })
}


export const resizeFile = async (imgUrlOrBase64: string, width: number = 200, height: number = 200, imageType: string = "JPEG") => {
    const file = await (await (await fetch(imgUrlOrBase64)).blob());
    return new Promise((resolve: (value: string) => void) => {
        Resizer.imageFileResizer(file, width, height, imageType, 100, 0, (uri) => { resolve(uri as string); }, "base64", width, height);
    });
}

export const changeExtention = (fullFileName: string, newExtention: string) => {
    let pos = fullFileName.lastIndexOf(".");
    let ext = newExtention.startsWith('.') ? newExtention : `.${newExtention}`
    return fullFileName.substring(0, pos < 0 ? fullFileName.length : pos) + ext.toLowerCase();
}