import { create } from 'zustand'
import { Category } from '../models/Category';
import { getCategories, getCategory } from '../services/categoryService';

//Categories Store
interface CategoriesStore {
    categories: Category[],
    isInit: boolean,

    selectedCategory?: Category,

    addCategoryItem: (category: Category) => void,
    fetchCategories: () => Promise<void>,
    selectCategory: (categoryId: string) => Promise<void>
}

export const useCategoriesStore = create<CategoriesStore>((set, get) => ({
    categories: [],
    isInit: false,

    selectedCategory: undefined,

    addCategoryItem: (category) => {
        if (!get().categories.find(c => c.id === category.id))
            set((state) => ({ categories: [...state.categories, category] }));
    },

    fetchCategories: async () => {
        const areCategoriesInit = get().isInit;

        if (!areCategoriesInit) {
            await fetchCategories();
            set(() => ({ isInit: true }));
        }
    },

    selectCategory: async (categoryId) => {
        let category = get().categories.find(c => c.id === categoryId);

        if (!category) {
            category = await getCategory(categoryId);
            get().addCategoryItem(category);
        }

        set(() => ({ selectedCategory: category }));
    }
}));

export const fetchCategories = async () => {
    const categoriesPromise = getCategories();

    const [categories] = await Promise.all([categoriesPromise])
    useCategoriesStore.setState(() => ({ categories: categories }));
}