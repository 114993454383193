import React, { useMemo } from "react";
import { useCurrentUserAzureFileUploader } from "../../services/fileUploaderService";


export const useModulesLg = (imageFolder?: string, toolbarContainerId = 'toolbar-lg') => {
    const { uploadFileBlob } = useCurrentUserAzureFileUploader();

    const imageUpload = (file: File): Promise<string> => {
        return uploadFileBlob(
            new File(
                [file],
                `${imageFolder}/${file.name}` ?? file.name,
                { type: file.type, lastModified: file.lastModified }
            )
        );
    }

    // Using memo because modules have to be const
    // And there is problem with imageUploader
    // That's kind of fix in order to show the editor pane
    const modulesLg = useMemo(() => {
        return {
            toolbar: {
                container: `#${toolbarContainerId}`
            },
            history: {
                delay: 500,
                maxStack: 100,
                userOnly: true
            },
            imageResize: {
                modules: ['Resize', 'DisplaySize']
            },
            imageUploader: {
                upload: imageUpload
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return modulesLg;
}

// Formats objects for setting up the Quill editor
export const formatsLg = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "code-block"
];

// Quill Toolbar component
export const QuillToolbarLg = ({ id = 'toolbar-lg' }) => (
    <div id={id}>
        <span className="ql-formats">
            <select className="ql-font" defaultValue={'roboto'}>
                <option value="roboto">Roboto</option>
                <option value="poor-story">Poor Story</option>
                <option value="griffy">Griffy</option>
                <option value="raleway">Arial</option>
                <option value="montserrat">Arial</option>
                <option value="lato">Helvetica</option>
                <option value="rubik">Lucida</option>
            </select>
            {/* <select className="ql-size" defaultValue="medium">
        <option value="small">Small</option>
        <option value="medium">Medium</option>
        <option value="large">Large</option>
      </select> */}
            <select className="ql-header" defaultValue={''}>
                <option value="1">Heading 1</option>
                <option value="2">Heading 2</option>
                <option value="3">Heading 3</option>
                <option>Normal</option>
            </select>
        </span>
        <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-strike" />
            <button className="ql-code-block" />
        </span>
        <span className="ql-formats">
            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
            <button className="ql-indent" value="-1" />
            <button className="ql-indent" value="+1" />
        </span>
        <span className="ql-formats">
            <button className="ql-script" value="super" />
            <button className="ql-script" value="sub" />
            <button className="ql-blockquote" />
            {/* <button className="ql-direction" /> */}
        </span>
        <span className="ql-formats">
            <select className="ql-align" />
            <select className="ql-color" />
            <select className="ql-background" />
        </span>
        <span className="ql-formats">
            <button className="ql-link" />
            <button className="ql-image" />
            <button className="ql-video" />
        </span>
        {/* <span className="ql-formats">
      <button className="ql-formula" />
      <button className="ql-code-block" />
      <button className="ql-clean" />
    </span> */}
    </div>
);

export default QuillToolbarLg;
