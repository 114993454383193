import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { PostOrdering, PostSearchParams, toUrlSearchParams } from '../../services/blogPostService';
import { postsCountSearchParamSelector, postsSearchParamSelector, usePostDetailsStore } from '../../state/postDetailsStore';
import { useSocialMediaFollowersStore } from '../../state/socialMediaFollowersStore';
import { useLangStore, useLoading } from '../../state/stateManagement';
import { postFilterPage } from '../../translations/posts/postFilterPage';
import { toLocalisedPost } from '../../utils/mappers/postMappers';
import InstagramPanel from '../Instagram/InstagramPanel';
import Pagination from '../Pagination/Pagination';
import SEO, { getLocalisation } from '../Shared/SEO';
import Sidebar from '../Sidebar/Sidebar';
import ListFilteredPostPanel from './PostFilteredPage/ListFilteredPostPanel';

export default function PostFilteredPage() {

    const [params] = useSearchParams();
    const keyword = params.get('keyword') ?? undefined;
    const tag = params.get('tag') ?? undefined;

    const currentPage = Number(params.get('page') ?? '1');
    const pageSize = 8;
    const searchParams: PostSearchParams = { page: currentPage, pageSize: pageSize, orderBy: PostOrdering.CreatedOnDesc, tag: tag, keyword: keyword };

    const selectedLanguage = useLangStore(store => store.selectedLanguage);

    const { setLoading } = useLoading()
    const { filteredPosts, fetchFilteredPosts, postsCount, fetchPostsCount } = usePostDetailsStore(store => ({
        filteredPosts: postsSearchParamSelector(store, searchParams)?.map(p => toLocalisedPost(p, selectedLanguage)),
        postsCount: postsCountSearchParamSelector(store, searchParams),
        fetchPostsCount: store.fetchPostsCount,
        fetchFilteredPosts: store.fetchFilteredPosts
    }));
    const fetchSocialMediaFollowers = useSocialMediaFollowersStore(store => store.fetchSocialMediaFollowers);
    // Get posts from Store

    useEffect(() => {

        setLoading(true);
        Promise.all([
            fetchSocialMediaFollowers(),
            fetchFilteredPosts(searchParams, selectedLanguage),
            fetchPostsCount(searchParams)]
        )
            .then(() => { setLoading(false) });

    }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {<SEO title={postFilterPage.pageTitle.get(selectedLanguage)!} description={postFilterPage.metaDescription.get(selectedLanguage)!} localisation={getLocalisation(selectedLanguage)} ogImage={''} />}
            {postsCount && <div className="section-heading " >
                <div className="container">
                    <div className="section-heading-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-heading-2-title text-left">
                                    <h2>{postFilterPage.searchResultsFor.get(selectedLanguage)}: {keyword}</h2>
                                    <p className="desc">
                                        {postsCount} {postsCount === 1 ? postFilterPage.articleFound.get(selectedLanguage) : postFilterPage.articlesFound.get(selectedLanguage)}
                                        <strong> {keyword} </strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}


            {filteredPosts && <ListFilteredPostPanel
                posts={filteredPosts}
                pagerElement={
                    <Pagination
                        currentPage={currentPage}
                        totalCount={postsCount ?? filteredPosts.length}
                        pageSize={pageSize}
                        onPageChange={async page => {
                            if (page * pageSize < (postsCount ?? filteredPosts.length) + pageSize) {
                                setLoading(true);
                                await fetchFilteredPosts({ ...searchParams, ...{ page: page } }, selectedLanguage);
                                setLoading(false);
                            }
                        }}
                        className="list-inline"
                        queryParams={toUrlSearchParams(searchParams)}
                    />
                }
                sidebar={
                    <Sidebar />
                } />
            }

            <InstagramPanel />
        </>
    )
}
