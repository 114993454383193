import React, { useEffect, useRef } from 'react'
import { genericTranslations } from '../../../translations/generic/inputTooltip'
import { useLangStore, useLoading } from '../../../state/stateManagement'
import { useForm } from 'react-hook-form'
import SmallEditor from '../../ContentEditorWrapper/SmallEditor';
import { authorEditTranslations } from '../../../translations/author/authorSinglePage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareFacebook, faSquareInstagram, faSquareTwitter, faSquareYoutube } from '@fortawesome/free-brands-svg-icons'
import { faBan, faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import ImageEditable from '../../Shared/ImageEditable'
import { patchAuthor } from '../../../services/authorService'
import { useNavigate } from 'react-router-dom'
import { useFetchWithTokenWithResult } from '../../../utils/useADAuth'
import { scopes } from '../../../config/authConfig'
import { isValidEmail } from '../../../utils/field-validations';
import { Author, LocalisedAuthor, PatchAuthorRequest } from '../../../models/Author';
import { useCurrentUserAzureFileUploader } from '../../../services/fileUploaderService';
import { useAuthorsStore } from '../../../state/authorsStore';
import { isNotOnlyWhiteSpaces } from '../../../utils/predicates';

export const getDirtyValues = (
    dirtyFields: {
        name?: boolean,
        email?: boolean,
        bio?: boolean,
        facebookProfileUrl?: boolean,
        twitterProfileUrl?: boolean,
        instagramProfileUrl?: boolean,
        pinterestProfileUrl?: boolean,
        linkedInProfileUrl?: boolean,
        youtubeProfileUrl?: boolean,
        tiktokProfileUrl?: boolean,
    },
    allValues: PatchAuthorRequest
): PatchAuthorRequest => {
    return {
        name: dirtyFields.name ? allValues.name : undefined,
        email: dirtyFields.email ? allValues.email : undefined,
        bio: dirtyFields.bio ? allValues.bio : undefined,
        facebookProfileUrl: dirtyFields.facebookProfileUrl ? allValues.facebookProfileUrl : undefined,
        instagramProfileUrl: dirtyFields.instagramProfileUrl ? allValues.instagramProfileUrl : undefined,
        twitterProfileUrl: dirtyFields.twitterProfileUrl ? allValues.twitterProfileUrl : undefined
    }
};

export default function AuthorInfoEdit({ author, onClose, onSave }: { author: LocalisedAuthor, onClose: () => void, onSave: () => void }) {

    const setLoading = useLoading(store => store.setLoading);
    const selectedLanguage = useLangStore(store => store.selectedLanguage);
    const updateAuthor = useAuthorsStore(store => store.updateAuthor);

    //Upload file
    const { uploadProfileImage } = useCurrentUserAzureFileUploader()

    //Redirect when post is created successfully
    const navigate = useNavigate();

    //fetch with access token
    const fetchWithToken = useFetchWithTokenWithResult();

    const { register, getValues, setValue, reset, handleSubmit, formState: { dirtyFields, errors } } = useForm<PatchAuthorRequest>({
        defaultValues: {
            name: author.name,
            email: author.email,
            bio: author.bio,
            image: author.image,
            facebookProfileUrl: author.socialMediaAccounts?.find(s => s.mediaName.toLowerCase() === 'facebook')?.profileUrl,
            instagramProfileUrl: author.socialMediaAccounts?.find(s => s.mediaName.toLowerCase() === 'instagram')?.profileUrl,
            linkedInProfileUrl: author.socialMediaAccounts?.find(s => s.mediaName.toLowerCase() === 'linkedin')?.profileUrl,
            twitterProfileUrl: author.socialMediaAccounts?.find(s => s.mediaName.toLowerCase() === 'twitter')?.profileUrl,
            youtubeProfileUrl: author.socialMediaAccounts?.find(s => s.mediaName.toLowerCase() === 'youtube')?.profileUrl,
            pinterestProfileUrl: author.socialMediaAccounts?.find(s => s.mediaName.toLowerCase() === 'pinterest')?.profileUrl,
            tiktokProfileUrl: author.socialMediaAccounts?.find(s => s.mediaName.toLowerCase() === 'tiktok')?.profileUrl
        }, mode: "onChange"
    });

    useEffect(() => {
        reset({ name: author.name, bio: author.bio, image: author.image })
    }, [selectedLanguage]) // eslint-disable-line react-hooks/exhaustive-deps

    const submitBtnRef = useRef<HTMLButtonElement>(null);

    return (<>
        <div className="authors-info row">
            <div className="image col-lg-3 col-md-5">
                <span className="image image-container">
                    <ImageEditable imageData={getValues('image')} onSave={(imageBase64 => { setValue('image', imageBase64) })} />
                </span>
            </div>
            <div className="content col-lg-9 col-md-7">
                <form
                    className='form author-details-edit-form'
                    onSubmit={handleSubmit(async patchRequest => {

                        onSave();
                        setLoading(true);

                        const request: PatchAuthorRequest = getDirtyValues(dirtyFields, patchRequest);

                        const editedImageData = getValues('image');
                        if (editedImageData && author.image?.urlOrBase64 !== editedImageData.urlOrBase64) {
                            const fileUrl = await uploadProfileImage(editedImageData.urlOrBase64, editedImageData.name);
                            request.image = { ...editedImageData, ...{ urlOrBase64: fileUrl } };
                        } else if (editedImageData && author.image?.urlOrBase64 === editedImageData.urlOrBase64) {
                            // Same image
                            request.image = { ...editedImageData };
                        } else if (!editedImageData && author.image) {
                            // Remove image
                            request.image = undefined;
                        }

                        const patchedAuthor = await fetchWithToken([scopes.openId, scopes.posts.write],
                            (accessToken) => patchAuthor(accessToken, request, selectedLanguage)) as Author;

                        // TODO: Update Author in store
                        updateAuthor(patchedAuthor);

                        // Redirect to post
                        navigate(`/authors/${patchedAuthor.id}`);
                        setLoading(false);
                    })}
                >
                    <div className="form-group form-row">
                        <div className='col'>
                            <input
                                {...register('name', { required: true, maxLength: 60, validate: isNotOnlyWhiteSpaces })}
                                placeholder={authorEditTranslations.namePlaceholder.get(selectedLanguage)}
                                title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                type="text"
                                className={`form-control ${!dirtyFields.name ? "" : errors.name ? "border-danger" : "border-success"}`}
                            />
                        </div>
                        <div className='col'>
                            <input
                                {...register('email', { required: true, maxLength: 60, validate: isValidEmail })}
                                placeholder={authorEditTranslations.emailPlaceholder.get(selectedLanguage)}
                                title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                type="text"
                                className={`form-control ${!dirtyFields.email ? "" : errors.email ? "border-danger" : "border-success"}`}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <SmallEditor
                            initValue={getValues('bio')}
                            onChange={(content) => { setValue('bio', content, { shouldValidate: true, shouldDirty: true }) }}
                            placeholder={authorEditTranslations.bioPlaceholder.get(selectedLanguage)}
                            maxContentLenght={200}
                        />
                    </div>
                    <div className="social-media-inputs">
                        <div className='social-media-input-item'>
                            <label htmlFor='facebookProfileUrl' className='fa-facebook'>
                                <FontAwesomeIcon icon={faSquareFacebook} />
                            </label>
                            <input
                                {...register('facebookProfileUrl', { required: false, maxLength: 200, validate: (val) => !val || isNotOnlyWhiteSpaces(val) })}
                                placeholder={authorEditTranslations.socialMediaLinkPlaceholder.get(selectedLanguage)}
                                title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                type="text" className="form-control"
                            />
                        </div>
                        <div className='social-media-input-item ml-1'>
                            <label htmlFor='instagramProfileUrl' className='fa-instagram'>
                                <FontAwesomeIcon icon={faSquareInstagram} />
                            </label>
                            <input
                                {...register('instagramProfileUrl', { required: false, maxLength: 200, validate: (val) => !val || isNotOnlyWhiteSpaces(val) })}
                                placeholder={authorEditTranslations.socialMediaLinkPlaceholder.get(selectedLanguage)}
                                title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                type="text" className="form-control"
                            />
                        </div>
                        <div className='social-media-input-item ml-1'>
                            <label htmlFor='twitterProfileUrl' className='fa-twitter'>
                                <FontAwesomeIcon icon={faSquareTwitter} />
                            </label>
                            <input
                                {...register('twitterProfileUrl', { required: false, maxLength: 200, validate: (val) => !val || isNotOnlyWhiteSpaces(val) })}
                                placeholder={authorEditTranslations.socialMediaLinkPlaceholder.get(selectedLanguage)}
                                title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                type="text" className="form-control"
                            />
                        </div>
                        <div className='social-media-input-item ml-1'>
                            <label htmlFor='youtubeProfileUrl' className='fa-youtube'>
                                <FontAwesomeIcon icon={faSquareYoutube} />
                            </label>
                            <input
                                {...register('youtubeProfileUrl', { required: false, maxLength: 200, validate: (val) => !val || isNotOnlyWhiteSpaces(val) })}
                                placeholder={authorEditTranslations.socialMediaLinkPlaceholder.get(selectedLanguage)}
                                title={genericTranslations.textRequiredTooltip.get(selectedLanguage)}
                                type="text" className="form-control"
                            />
                        </div>

                        <div className='form-group d-none'>
                            <button
                                ref={submitBtnRef}
                                type="submit"
                                name="submit">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
            <span className='close-icon'>
                <FontAwesomeIcon icon={faBan} onClick={onClose} />
            </span>
            <span className='save-icon'>
                <FontAwesomeIcon
                    icon={faFloppyDisk}
                    onClick={e => { submitBtnRef.current!.click(); }}
                />
            </span>
        </div>
    </>
    )
}
