import React from 'react'
import { ProductSpecification } from '../../../../models/Shop/Product'

export default function Specifications({ specifications }: { specifications: ProductSpecification[] }) {
    return (
        <ul className="meta-info">
            {specifications.map(spec => <li key={spec.name}><span>{spec.name}</span>{spec.value}</li>)}
        </ul>
    )
}
